
import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaVeiculo:[],
	listaMotorista: [],
	listaUsuario: [],
	listaSetor: [],
	listaOcorrencia: [],
	listaOcorrenciasSelecionadas: [],
	listaOcorrenciaChecklist: [],
	listaOcorrenciaChecklistsSelecionadas: [],
	listaChecklist: [],
	listaChecklistPerguntas: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	},
	listaStatus: [
		{ id: 1, valor: 'Aguardando' }, 
		{ id: 2, valor: 'Sim' }, 
		{ id: 3, valor: 'Não' }
	],
	listaTipo: [
		{ id: 1, valor: 'Não Realizada' },
		{ id: 2, valor: 'Corretiva/Desgaste' }, 
		{ id: 3, valor: 'Corretiva/Mau Uso' },
		{ id: 4, valor: 'Preventiva' }
	],
	listaObservacao: [
		{ id: 1, valor: 'Não Autorizada' },
		{ id: 2, valor: 'Manutenção Estrada' },
		{ id: 3, valor: 'Peça Indisponível' },
		{ id: 4, valor: 'Não deu Tempo' },
		{ id: 5, valor: 'Não Necessário' }
	]
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'ORDEM_SERVICO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'ORDEM_SERVICO_FILTRO':
			return { ...state, filtro: action.payload };

		case 'ORDEM_SERVICO_LISTADO':
			return { ...state, lista: action.payload.data };

		case 'ORDEM_SERVICO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'ORDEM_SERVICO_VEICULO_SELECT_LISTADO':
			return { ...state, listaVeiculo: action.payload.data };

		case 'ORDEM_SERVICO_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

		case 'ORDEM_SERVICO_USUARIO_SELECT_LISTADO':
			return { ...state, listaUsuario: action.payload.data };

		case 'ORDEM_SERVICO_SETOR_SELECT_LISTADO':
			return { ...state, listaSetor: action.payload.data };

		case 'ORDEM_SERVICO_CHECKLIST_LISTADO':
			//Chegada Viagem
			return { ...state, listaChecklist: action.payload.data.filter(item => item.id_checklist_tipo == 4)[0] ? action.payload.data.filter(item => item.id_checklist_tipo == 4)[0].perguntas || [] : [] };

		case 'ORDEM_SERVICO_OCORRENCIA_LISTADO':
			return { ...state, listaOcorrencia: action.payload.data };

		case 'ORDEM_SERVICO_OCORRENCIA_SELECIONADA_LISTADO':
			return { ...state, listaOcorrenciasSelecionadas: action.payload };

		case 'ORDEM_SERVICO_OCORRENCIA_CHECKLIST_LISTADO':
			return { ...state, listaOcorrenciaChecklist: action.payload.data };

		case 'ORDEM_SERVICO_OCORRENCIA_CHECKLIST_SELECIONADA_LISTADO':
			return { ...state, listaOcorrenciaChecklistsSelecionadas: action.payload };

		case 'ORDEM_SERVICO_CHECKLIST_PERGUNTA_LISTADO':
			//Chegada Carga
			let checklist = action.payload && action.payload.data ? action.payload.data.filter(item => item.id == 10)[0] : null;
			let perguntas = checklist ? (checklist.perguntas || []).map(item => ({ id: item.id, valor: item.descricao })) : [];
			
			return { ...state, listaChecklistPerguntas: perguntas };

		default:
			return state;
	}
}
