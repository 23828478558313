import React from 'react';
import Row from '../layout/row';
import Grid from '../layout/grid';
import Select from 'react-select';

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
			<label htmlFor={props.name}>{props.label}</label>
			{props.modoCadastro ? (
				props.componenteCadastro
			) : (
				<div className='row'>
					<div className='col-xs-10 col-sm-10 col-md-10 col-lg-10'>
						{props.onChange ? (
							<Select
								readOnly={props.readOnly}
								defaultValue={props.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.value)[0] || null : null}
								isClearable={true}
								isSearchable={true}
								isDisabled={props.readOnly ? true : false}
								onChange={(item) => props.onChange(item ? item.value : null)}
								options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
						) : (
							<Select
								readOnly={props.readOnly}
								isClearable={true}
								isSearchable={true}
								isDisabled={props.readOnly ? true : false}
								defaultValue={props.input.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.input.value)[0] || null : null}
								onChange={(item) => props.input.onChange(item ? item.value : null)}
								onBlur={(value) => props.input.onBlur()}
								options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
						)}
					</div>
					<div className='col-xs-2 col-sm-2 col-md-2 col-lg-2'>
						<span class='input-group-btn' style={{ width: '100%' }}>
							<button type='button' class='btn btn-success btn-flat'  style={{ width: '96%' }}
								onClick={props.acaoBotao}
								disabled={props.readOnly == 'readOnly'}>
								{props.acaoBotaoLabel}
							</button>
						</span>
					</div>
				</div>
			)}
       </div>
   </Grid>
)
