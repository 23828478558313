import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './multaForm';
import Select from '../common/form/select';
import ReactSelect from 'react-select';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import { 
	setModoTela, initForm, salvar, setFiltro,
	excluir, getLista, getListaMotorista, getListaVeiculo, getListaCidade
} from './multaActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class Multa extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		status: 1
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaCidade();
		this.props.getListaMotorista();
		this.props.getListaVeiculo();
    }

    render() {
        return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_despesa != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ 
										datahora_infracao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
										data_vencimento: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />


						<Select
							label='Status'
							cols='12 6 4 3'
							options={[{ id: '1', valor: 'Aguardando' }, { id: '2', valor: 'Quitado' }, { id: '3', valor: 'Ambos' }]}
							value={this.state.status}
							onChange={item => {
								this.setState({ ...this.state, status: item })
							}} />
							
						<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<Tr>
							<Th alignCenter>Data Infração</Th>
							<Th alignCenter>Motorista</Th>
							<Th alignCenter>Veículo</Th>
							<Th alignCenter>Cidade / UF</Th>
							<Th alignCenter>Auto Infração</Th>
							<Th alignCenter>Causador</Th>
							<Th alignCenter>Forma Pagamento</Th>
							<Th alignCenter>Parcelas</Th>
							<Th alignRight>Valor Total</Th>
							<Th alignRight>Valor Quitado</Th>
							<Th alignRight>Valor Saldo</Th>
							<Th alignCenter>Status</Th>
							<Th></Th>
						</Tr>

						<TBody>
							{this.props.lista
								.filter(item => {

									let quitado = parseFloat(item.valor || 0) == parseFloat(item.valor_quitado || 0);
									if (this.state.status == 1 && quitado) {
										return false;
									} else if (this.state.status == 2 && !quitado) {
										return false;
									}
									
									if (this.state.pesquisar
										&& !(`
											${item.nome_motorista} ${item.placa_veiculo} ${item.nome_cidade} ${item.sigla_estado} 
											${item.auto_infracao} ${item.nome_causador} ${item.nome_forma_pagamento}
										`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
										return false;
									}
									return true;
								}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_infracao)}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.nome_cidade}{item.nome_cidade ? ' - ' : ''}{item.sigla_estado}</Td>
									<Td alignCenter>{item.auto_infracao}</Td>
									<Td alignCenter>{item.nome_causador}</Td>
									<Td alignCenter>{item.nome_forma_pagamento}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.parcelas, 0)}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTela(item.valor_quitado, 2)}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTela(parseFloat(item.valor || 0) - parseFloat(item.valor_quitado || 0), 2)}</Td>
									<Td alignCenter>{parseFloat(item.valor || 0) == parseFloat(item.valor_quitado || 0) ? 'Quitado' : 'Aguardando'}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_infracao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_infracao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_infracao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_infracao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_infracao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_infracao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_infracao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_infracao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.multa.modoTela,
	lista: state.multa.lista,
	aguardando: state.multa.aguardando,
	filtro: state.multa.filtro,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, setFiltro,
	excluir, getLista, getListaMotorista, getListaVeiculo, getListaCidade
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Multa);
