import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from '../common/widget/valueBox'
import Row from '../common/layout/row'
import InfoBox from '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import imagemUser from '../assets/images/user.jpg';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	getListaEficiencia, getListaPorTipo, getListaOcorrenciaResolvida, getListaOcorrenciaNaoResolvida,
	getListaDias, getListaAnalise, getListaNaoConformidadeExercicio, setFiltro } from './dashboardManutencaoActions';

class DashboardMotorista extends Component {

	state = {
		linhaSelecionada: null,
		terceiro: window.location.href.includes('terceiro'),
		pesquisar: ''
	}

	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.getListaEficiencia();
		this.props.getListaPorTipo();
		this.props.getListaOcorrenciaResolvida();
		this.props.getListaOcorrenciaNaoResolvida();
		this.props.getListaDias();
		this.props.getListaAnalise();
		this.props.getListaNaoConformidadeExercicio();
	}

	componentWillUpdate() {
		
	}

	render() {

		let osTotalFinalizadas = 0;
		let osTotalAbertas = 0;
		this.props.listaEficiencia.forEach(item => {
			osTotalFinalizadas += parseInt(item.finalizadas);
			osTotalAbertas += parseInt(item.abertas);
		});
		let osTotal = osTotalFinalizadas + osTotalAbertas;
		let osPercentualTotalFinalizadas = parseInt(osTotal ? (osTotalFinalizadas * 100) / osTotal : 0);
		let osPercentualTotalAbertas = parseInt(osTotal ? (osTotalAbertas * 100) / osTotal : 0);
		if (osPercentualTotalFinalizadas > osPercentualTotalAbertas) {
			osPercentualTotalFinalizadas = 100 - osPercentualTotalAbertas;
		} else {
			osPercentualTotalAbertas = 100 - osPercentualTotalFinalizadas;
		}

		let naoRealizadoTotal = 0;
		this.props.listaOcorrenciaNaoResolvida.forEach(item => {
			naoRealizadoTotal += parseInt(item.quantidade || 0);
		});

		let osTipos = [
			{ id: 2, valor: 'Corretiva/Desgaste', cor: 'rgb(255, 215, 0)' }, 
			{ id: 3, valor: 'Corretiva/Mau Uso', cor: 'rgb(255, 0, 0)' },
			{ id: 4, valor: 'Preventiva', cor: 'rgb(50, 205, 50)' }
		];

		let totalResolvido = 0;
		let totalNaoResolvido = 0;
		this.props.listaOcorrenciaResolvida.forEach(item => {
			totalResolvido += parseInt(item.resolvido || 0);
			totalNaoResolvido += parseInt(item.nao_resolvido || 0);
		});

		let diasMaior = 0;
		this.props.listaDias.forEach(item => {
			if (item.dias > diasMaior) {
				diasMaior = parseInt(item.dias);
			}
		});

		let analiseTotal = 0;
		this.props.listaAnalise.forEach(item => {
			analiseTotal += parseInt(item.quantidade);
		});

		let analiseTotalAbertas = 0;
		this.props.listaAnalise.filter(item => item.tipo != 'Encerradas').forEach(item => {
			analiseTotalAbertas += parseInt(item.quantidade);
		});

		return (
			<Content>
				<Row>
					<LabelAndInputMask
						label='Data Inicial' placeholder='Informe o período inicial'
						cols='12 6 3 2'
						mask='99/99/9999'
						value={this.props.filtro.data_inicial}
						onChange={(data) => {
							this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
						}} />
				
					<LabelAndInputMask
						label='Data Final' placeholder='Informe o período final'
						cols='12 6 3 2'
						mask='99/99/9999'
						value={this.props.filtro.data_final}
						onChange={(data) => {
							this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
						}} />
						
					<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
						<Button
							text='Atualizar'
							type={'primary'}
							icon={'fa fa-plus'}
							event={() => {
								this.props.getListaEficiencia();
								this.props.getListaPorTipo();
								this.props.getListaOcorrenciaResolvida();
								this.props.getListaOcorrenciaNaoResolvida();
								this.props.getListaDias();
								this.props.getListaAnalise();
								this.props.getListaNaoConformidadeExercicio();
							}} />
					</Grid>
				</Row>
				<Row>
					<Grid cols='12 12 8 8'>
						<Row>
							<Grid cols='12 12 12 12'>
								<ContentCard style={{ height: 314 }}>
									<ContentCardBody>
										<h5>Ordens de Serviço - Eficiência</h5>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											{this.props.listaEficiencia.map(item => (
												<div key={item.nome_setor} style={{ textAlign: 'center' }}>
													<img src={`
														https://quickchart.io/chart?height=110&width=110&c={
															type: 'gauge',
															data: {
																datasets: [{
																	value: ${
																		parseInt((parseInt(item.abertas) + parseInt(item.finalizadas)) 
																			? (parseInt(item.finalizadas) * 100) / (parseInt(item.abertas) + parseInt(item.finalizadas)) 
																			: 0)
																	},
																	data: [33, 67, 100],
																	backgroundColor: ['rgb(255, 0, 0)', 'rgb(255, 215, 0)', 'rgb(50, 205, 50)'],
																	borderWidth: 2
																}]
															},
															options: {
																valueLabel: {
																	fontSize: 8,
																	backgroundColor: 'transparent',
																	color: 'rgb(0, 0, 0)',
																	formatter: function(value, context){
																		return ${"`${value}% finalizado`"};
																	},
																	bottomMarginPercentage: -10
																}
															}
														}`} />
													<p style={{ fontSize: 20 }}>{item.nome_setor}</p>
												</div>
											))}
										</div>
									</ContentCardBody>							
								</ContentCard>
							</Grid>
							<Grid cols='12 12 8 8'>
								<ContentCard style={{ height: 331 }}>
									<ContentCardBody>
										<h5>Comparativo Tipo de Manutenção</h5>
										<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
											{osTipos.map(item => (
												<div key={item.id} style={{ display: 'flex', marginLeft: 0, fontSize: 14 }}>
													<div style={{ backgroundColor: item.cor, height: 15, width: 15, marginRight: 2 }}></div> {item.valor}
												</div>
											))}
										</div>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											{this.props.listaPorTipo.map(item => {
												
												let tipos = (item.itens || []).filter(tipo => tipo);
												let totalSetor = 0;
												tipos.forEach(tipo => {
													totalSetor += parseInt(tipo.quantidade || 0);
												});

												let listaTipos = osTipos.map(tipo => ({
													id: tipo.id,
													nome: tipo.valor,
													quantidade: tipos.filter(tipoItem => tipoItem.id_tipo == tipo.id)[0] ? parseInt(tipos.filter(tipoItem => tipoItem.id_tipo == tipo.id)[0].quantidade) : 0
												}));

												return (
													<div key={item.nome_setor} style={{ textAlign: 'center' }}>
														<img src={`
															https://quickchart.io/chart?height=102&width=80&c={
																type: 'pie',
																data: {
																	datasets: [{
																		data: ${JSON.stringify(listaTipos.map(tipo => (totalSetor ? (parseInt(tipo.quantidade) * 100) / totalSetor : 0).toFixed(1)))},
																		backgroundColor: ${JSON.stringify(osTipos.map(tipo => tipo.cor))},
																		borderWidth: 2
																	}]
																},
																options: {
																	plugins: {
																		datalabels: {
																			color: 'rgb(0, 0, 0)',
																			font: {
																				size: 7,
																				weight: 'bold'
																			},
																			formatter: (val, ctx) => {
																				return ${"`${val.replace('.', ',')}%`"}
																			}
																		}													
																	}
																}
															}`} />
														<p style={{ fontSize: 20 }}>{item.nome_setor}</p>
													</div>
												);
											})}
										</div>
									</ContentCardBody>							
								</ContentCard>
							</Grid>
							<Grid cols='12 12 4 4'>
								<ContentCard style={{ height: 331 }}>
									<ContentCardBody>
										<h5>Ordens de Serviço - Eficiência Geral</h5>
										<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
											<div style={{ display: 'flex' }}>
												<div style={{ backgroundColor: 'rgb(230, 193, 30)', height: 18, width: 30, marginRight: 5 }}></div> Abertas - {osTotalAbertas}
											</div>
											<div style={{ display: 'flex' }}>
												<div style={{ backgroundColor: 'rgb(18, 116, 201)', height: 18, width: 30, marginRight: 5 }}></div> Encerradas - {osTotalFinalizadas}
											</div>
										</div>
										<div style={{ textAlign: 'center' }}>
											<img src={`
												https://quickchart.io/chart?height=123&width=100&c={
													type: 'doughnut',
													data: {
														datasets: [{
															data: [${osPercentualTotalFinalizadas}, ${osPercentualTotalAbertas}],
															backgroundColor: ['rgb(18, 116, 201)', 'rgb(230, 193, 30)'],
															borderWidth: 2
														}]
													},
													options: {
														plugins: {
															datalabels: {
																backgroundColor: 'rgb(0, 128, 128)',
																color: 'rgb(255, 255, 255)',
																font: {
																	size: 6
																},
																formatter: (val, ctx) => {
																	return ${"`${val}%`"}
																}
															}										
														}
													}
												}`} />
											</div>											
									</ContentCardBody>							
								</ContentCard>
							</Grid>
						</Row>
					</Grid>
					<Grid cols='12 12 4 4'>
						<Row>
							<Grid cols='12 12 12 12'>
								<ContentCard style={{ height: 314 }}>
									<ContentCardBody>
										<h5>Não Conformidades</h5>
										<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 0 }}>
											<div style={{ width: '30%', display: 'flex', flexDirection: 'column', marginLeft: 0, textAlign: 'center' }}>
												<div style={{ fontSize: 18, fontWeight: 'bold' }}>
													{totalResolvido + totalNaoResolvido}
												</div>
												<div style={{ fontSize: 14 }}>
													Total
												</div>
											</div>
											<div style={{ width: '30%', display: 'flex', flexDirection: 'column', marginLeft: 0, textAlign: 'center' }}>
												<div style={{ fontSize: 18, fontWeight: 'bold' }}>
													{totalResolvido}
												</div>
												<div style={{ fontSize: 14 }}>
													Resolvidas
												</div>
											</div>
											<div style={{ width: '30%', display: 'flex', flexDirection: 'column', marginLeft: 0, textAlign: 'center' }}>
												<div style={{ fontSize: 18, fontWeight: 'bold' }}>
													{totalNaoResolvido}
												</div>
												<div style={{ fontSize: 14 }}>
													Não Resolvidas
												</div>
											</div>
										</div>
										<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
											<div style={{ display: 'flex', marginLeft: 0, fontSize: 14 }}>
												<div style={{ backgroundColor: 'rgb(122, 126, 204)', height: 15, width: 15, marginRight: 2 }}></div> Resolvidas
											</div>
											<div style={{ display: 'flex', marginLeft: 0, fontSize: 14 }}>
												<div style={{ backgroundColor: 'rgb(222, 75, 78)', height: 15, width: 15, marginRight: 2 }}></div> Não Resolvidas
											</div>
											<div style={{ display: 'flex', marginLeft: 0, fontSize: 14 }}>
												<div style={{ backgroundColor: 'rgb(209, 153, 13)', height: 15, width: 15, marginRight: 2 }}></div> Média Móvel
											</div>
										</div>

										<div style={{ textAlign: 'center' }}>
											<img src={`
												https://quickchart.io/chart?height=86&width=250&c={
													type: 'bar',
													data: {
														labels: ${JSON.stringify(['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
															.filter((item, i) => this.props.listaOcorrenciaResolvida.filter(os => os.mes == (i + 1)).length))},
														datasets: [{
															label: 'Resolvidas',
															data: ${JSON.stringify(this.props.listaOcorrenciaResolvida.map(item => item.resolvido))},
															backgroundColor: 'rgb(122, 126, 204)'
														}, { 
															label: 'Não Resolvidas', 
															data: ${JSON.stringify(this.props.listaOcorrenciaResolvida.map(item => item.nao_resolvido * -1))},
															backgroundColor: 'rgb(222, 75, 78)'
														}, { 
															type: 'line', 
															label: 'Média Móvel', 
															borderColor: 'rgb(209, 153, 13)', 
															borderWidth: 1,
															fill: false,
															data: ${JSON.stringify(this.props.listaOcorrenciaResolvida.map(item => item.resolvido - item.nao_resolvido))}
														}]
													},
													options: {
														scales: {
															xAxes: [{
																stacked: true,
																ticks: {
																	fontSize: 6
																}
															}],
															yAxes: [{
																stacked: true,
																ticks: {
																	fontSize: 6
																}
															}]
														},
														legend: {
															labels: {
																fontSize: 5
															},
															display: false
														}
													}
												}`} />
										</div>										
									</ContentCardBody>							
								</ContentCard>
							</Grid>
							<Grid cols='12 12 12 12'>
								<ContentCard style={{ height: 331 }}>
									<ContentCardBody>
										<h5>Motivos de não ter resolvido a Não Conformidade</h5>
										<Table responsive>
											<THead>
												<Tr style={{ height: 46 }}>
													<Th>Motivo</Th>
													<Th alignCenter>Quantidade</Th>
													<Th>Gráfico</Th>
												</Tr>
											</THead>
											<TBody>
												{this.props.listaOcorrenciaNaoResolvida.map(item => (
													<Tr key={item.id} style={{ height: 36 }}>
														<Td width={200}>{item.nome}</Td>
														<Td alignCenter width={100}>{parseInt(item.quantidade || 0)}</Td>
														<Td alignRight width={500}>
															<div class='progress progress-sm'>
																<div class='progress-bar bg-primary' style={{ width: naoRealizadoTotal ? `${(item.quantidade * 100) / naoRealizadoTotal}%` : '0%' }}></div>
															</div>
														</Td>
													</Tr>
												))}
											</TBody>
										</Table>
									</ContentCardBody>							
								</ContentCard>
							</Grid>
						</Row>
					</Grid>
				</Row>
				<Row>
					<Grid cols='12 12 5 5'>
						<ContentCard style={{ height: 350 }}>
							<ContentCardBody>
							<ContentCard>
									<ContentCardBody>
										<h5>Dias para Finalizar Ordem de Serviço</h5>
										<Table responsive>
											<THead>
												<Tr style={{ height: 46 }}>
													<Th>Setor</Th>
													<Th alignCenter>Dias</Th>
													<Th>Gráfico</Th>
												</Tr>
											</THead>
											<TBody>
												{this.props.listaDias.map(item => (
													<Tr key={item.id} style={{ height: 36 }}>
														<Td width={100}>{item.nome_setor}</Td>
														<Td alignCenter width={100}>{parseInt(item.dias || 0)}</Td>
														<Td alignRight width={500}>
															<div class='progress progress-sm'>
																<div class='progress-bar bg-primary' style={{ width: diasMaior ? `${parseInt((item.dias * 100) / diasMaior)}%` : '0%' }}></div>
															</div>
														</Td>
													</Tr>
												))}
											</TBody>
										</Table>
									</ContentCardBody>							
								</ContentCard>
							</ContentCardBody>							
						</ContentCard>
					</Grid>
					<Grid cols='12 12 5 5'>
						<ContentCard style={{ height: 350 }}>
							<ContentCardBody>
							<ContentCard>
									<ContentCardBody>
										<h5>Análise de Ordens de Serviço</h5>
										<Table responsive>
											<THead>
												<Tr style={{ height: 46 }}>
													<Th>Motivo</Th>
													<Th alignCenter>Quantidade</Th>
													<Th>Gráfico</Th>
												</Tr>
											</THead>
											<TBody>
												<Tr style={{ height: 36 }}>
													<Td width={100} bold>Todas</Td>
													<Td alignCenter width={100} bold>{parseInt(analiseTotal || 0)} - {analiseTotal ? `100%` : '0%'}</Td>
													<Td alignRight width={500}>
														<div class='progress progress-sm'>
															<div class='progress-bar bg-success' style={{ width: analiseTotal ? `100%` : '0%' }}></div>
														</div>
													</Td>
												</Tr>
												{this.props.listaAnalise.filter(item => item.tipo == 'Encerradas').map(item => (
													<Tr key={item.id} style={{ height: 36 }}>
														<Td width={100} bold>{item.tipo}</Td>
														<Td alignCenter width={100} bold>{parseInt(item.quantidade || 0)} - {analiseTotal ? `${FormatUtils.formatarValorTela((item.quantidade * 100) / analiseTotal, 2)}%` : '0%'}</Td>
														<Td alignRight width={500}>
															<div class='progress progress-sm'>
																<div class='progress-bar bg-success' style={{ width: analiseTotal ? `${parseInt((item.quantidade * 100) / analiseTotal)}%` : '0%' }}></div>
															</div>
														</Td>
													</Tr>
												))}
											</TBody>
											<TBody>
												{this.props.listaAnalise.filter(item => item.tipo != 'Encerradas').map(item => (
													<Tr key={item.id} style={{ height: 36 }}>
														<Td width={100}>{item.tipo}</Td>
														<Td alignCenter width={100}>{parseInt(item.quantidade || 0)} - {analiseTotalAbertas ? `${FormatUtils.formatarValorTela((item.quantidade * 100) / analiseTotalAbertas, 2)}%` : '0%'}</Td>
														<Td alignRight width={500}>
															<div class='progress progress-sm'>
																<div class='progress-bar bg-primary' style={{ width: analiseTotalAbertas ? `${parseInt((item.quantidade * 100) / analiseTotalAbertas)}%` : '0%' }}></div>
															</div>
														</Td>
													</Tr>
												))}
											</TBody>
										</Table>
									</ContentCardBody>							
								</ContentCard>
							</ContentCardBody>							
						</ContentCard>
					</Grid>
					<Grid cols='12 12 2 2'>
						<ContentCard style={{ height: 350 }}>
							<ContentCardBody>
								<h5>Não Conformidades por Competência</h5>
								{this.props.listaNaoConformidadeExercicio.length == 2 &&
								<div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
									<div style={{ display: 'flex', marginLeft: 0, marginRight: 8, fontSize: 14 }}>
										<div style={{ backgroundColor: 'rgb(191, 162, 46)', height: 15, width: 15, marginRight: 2 }}></div> {['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][this.props.listaNaoConformidadeExercicio[0].mes - 1]}
									</div>
									<div style={{ display: 'flex', marginLeft: 8, marginRight: 0, fontSize: 14 }}>
										<div style={{ backgroundColor: 'rgb(122, 126, 204)', height: 15, width: 15, marginRight: 2 }}></div> {['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][this.props.listaNaoConformidadeExercicio[1].mes - 1]}
									</div>
								</div>}
								{this.props.listaNaoConformidadeExercicio.length == 2 &&										
									<div style={{ textAlign: 'center' }}>
										{console.log(`datasets: [{
														label: '${['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][this.props.listaNaoConformidadeExercicio[0].mes - 1]}',
														data: ${JSON.stringify([this.props.listaNaoConformidadeExercicio[0].quantidade])},
														backgroundColor: 'rgb(191, 162, 46)'
													}, {
														label: '${['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][this.props.listaNaoConformidadeExercicio[1].mes - 1]}',
														data: ${JSON.stringify([this.props.listaNaoConformidadeExercicio[1].quantidade])},
														backgroundColor: 'rgb(122, 126, 204)'
													}]`)}
										
										<img src={`
											https://quickchart.io/chart?height=75&width=80&c={
												type: 'bar',
												data: {
													datasets: [{
														label: '${['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][this.props.listaNaoConformidadeExercicio[0].mes - 1]}',
														data: ${JSON.stringify([this.props.listaNaoConformidadeExercicio[0].quantidade])},
														backgroundColor: 'rgb(191, 162, 46)'
													}, {
														label: '${['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][this.props.listaNaoConformidadeExercicio[1].mes - 1]}',
														data: ${JSON.stringify([this.props.listaNaoConformidadeExercicio[1].quantidade])},
														backgroundColor: 'rgb(122, 126, 204)'
													}]
												},
												options: {
													scales: {
														xAxes: [{
															stacked: false,
															ticks: {
																fontSize: 6
															}
														}],
														yAxes: [{
															stacked: false,
															ticks: {
																fontSize: 6,
																beginAtZero: true
															},
															display: false
														}]
													},
													legend: {
														labels: {
															fontSize: 5
														},
														display: false
													}
												}
											}`} />
											
											{this.props.listaNaoConformidadeExercicio[0].quantidade + this.props.listaNaoConformidadeExercicio[1].quantidade > 0 &&
											<div>
												{this.props.listaNaoConformidadeExercicio[0].quantidade > this.props.listaNaoConformidadeExercicio[1].quantidade ? (
													<div>
														<h4>
															{100 -  parseInt((this.props.listaNaoConformidadeExercicio[1].quantidade * 100) / this.props.listaNaoConformidadeExercicio[0].quantidade)}% 
														</h4>
														menor do que a competência anterior
													</div>
												) : (this.props.listaNaoConformidadeExercicio[0].quantidade == this.props.listaNaoConformidadeExercicio[1].quantidade ? (
													<div>
														Igual a competência anterior
													</div>
												) : (
													<div>
														<h4>
															{100 - parseInt((this.props.listaNaoConformidadeExercicio[0].quantidade * 100) / this.props.listaNaoConformidadeExercicio[1].quantidade)}% 
														</h4>
														maior do que a competência anterior
													</div>
												))}
											</div>}
									</div>}
							</ContentCardBody>							
						</ContentCard>
					</Grid>
				</Row>
			</Content>
		)
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	permissoes: state.usuario.permissoes,
	listaEficiencia: state.dashboardManutencao.listaEficiencia,
	filtro: state.dashboardManutencao.filtro,
	listaPorTipo: state.dashboardManutencao.listaPorTipo,
	listaOcorrenciaResolvida: state.dashboardManutencao.listaOcorrenciaResolvida,
	listaOcorrenciaNaoResolvida: state.dashboardManutencao.listaOcorrenciaNaoResolvida,
	listaDias: state.dashboardManutencao.listaDias,
	listaAnalise: state.dashboardManutencao.listaAnalise,
	listaNaoConformidadeExercicio: state.dashboardManutencao.listaNaoConformidadeExercicio
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaEficiencia, getListaPorTipo, getListaOcorrenciaResolvida, getListaOcorrenciaNaoResolvida, 
	getListaDias, getListaAnalise, getListaNaoConformidadeExercicio, setFiltro
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardMotorista)
