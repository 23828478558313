import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from '../common/widget/valueBox'
import Row from '../common/layout/row'
import InfoBox from '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import imagemUser from '../assets/images/user.jpg';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { getListaNegociacaoUtilizada, getListaNegociacaoStatus, getListaNegociacaoForma, setFiltro } from './dashboardFinanceiroActions';

class DashboardFinanceiro extends Component {

	state = {
		linhaSelecionada: null,
		terceiro: window.location.href.includes('terceiro'),
		pesquisar: ''
	}

	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.getListaNegociacaoUtilizada();
		this.props.getListaNegociacaoStatus();
		this.props.getListaNegociacaoForma();
	}

	componentWillUpdate() {
		
	}

	render() {

		let cores = ['rgb(25, 118, 210)', 'rgb(223, 227, 11)', 'rgb(214, 94, 51)', 'rgb(0, 200, 83)', 'rgb(149, 117, 205)', 'rgb(255, 152, 0)', 'rgb(128, 203, 196)', 'rgb(3, 155, 229)', 'rgb(165, 214, 167)', 'rgb(239, 154, 154)'];

		let totalNegociacaoStatus = 0;
		this.props.listaNegociacaoStatus.filter(status => status.quantidade > 0).filter(status => status.quantidade > 0).forEach(status => {
			totalNegociacaoStatus += parseInt(status.quantidade);
		});
		
		let percentualNegociacaoStatusTotal = 100;

		let totalNegociacaoForma = 0;
		this.props.listaNegociacaoForma.forEach(forma => {
			totalNegociacaoForma += parseInt(forma.quantidade);
		});
		
		return (
			<Content>
				<Row>
					<LabelAndInputMask
						label='Data Inicial' placeholder='Informe o período inicial'
						cols='12 6 3 2'
						mask='99/99/9999'
						value={this.props.filtro.data_inicial}
						onChange={(data) => {
							this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
						}} />
				
					<LabelAndInputMask
						label='Data Final' placeholder='Informe o período final'
						cols='12 6 3 2'
						mask='99/99/9999'
						value={this.props.filtro.data_final}
						onChange={(data) => {
							this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
						}} />
						
					<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
						<Button
							text='Atualizar'
							type={'primary'}
							icon={'fa fa-plus'}
							event={() => {
								this.props.getListaNegociacaoUtilizada();
								this.props.getListaNegociacaoStatus();
								this.props.getListaNegociacaoForma();
							}} />
					</Grid>
				</Row>
				<Row>
					<Grid cols='12 12 12 12'>
						<Row>
							<Grid cols='12 12 8 8'>
								<ContentCard>
									<ContentCardBody>
										<h5>Abastecimentos Frota</h5>
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												{this.props.listaNegociacaoUtilizada.filter(forma => 
													forma.forma_pagamento != 'APP PROFROTAS'
													&& forma.forma_pagamento != 'APP REDE FROTA'
													&& forma.forma_pagamento != 'PIX EMPRESA'
												).map(forma => {	

													let total = 0;
													forma.itens.filter(status => status.quantidade > 0).forEach(status => {
														total += parseInt(status.quantidade);
													});

													let percentualTotal = 100;

													return (
														<img key={forma.nome} src={`
															https://quickchart.io/chart?height=200&width=90&c={
																type: 'bar',
																data: {
																	labels: ['${forma.forma_pagamento}'],
																	datasets: ${JSON.stringify(forma.itens.filter(status => status.quantidade > 0).map((status, i) => {

																		let percentual = parseFloat(((parseInt(status.quantidade) * 100) / total).toFixed(1));

																		percentualTotal -= percentual;

																		if (i == forma.itens.filter(status => status.quantidade > 0).length -1) {
																			percentual += percentualTotal;
																		}

																		return ({
																			label: status.nome_status,
																			backgroundColor: ['rgb(255, 255, 0)', 'rgb(0, 200, 83)', 'rgb(41, 182, 246)', 'rgb(255, 82, 82)'][status.id_status],
																			data: [percentual.toFixed(1)]
																		});
																	}))}
																},
																options: {
																	title: {
																		display: false,
																		text: '',
																	},
																	plugins: {
																		datalabels: {
																			color: 'rgb(0, 0, 0)',
																			font: {
																				size: 7,
																				weight: 'bold'
																			},
																			formatter: (val, ctx) => {
																				return ${"`${val.replace('.', ',')}%`"}
																			}
																		}
																	},
																	legend: {
																		display: false
																	},
																	scales: {
																		xAxes: [{
																			stacked: true,
																			display: true,
																			ticks: {
																				fontSize: 6,
																				fontStyle: 'bold'
																			}
																		}],
																		yAxes: [{
																			stacked: true,
																			display: false,
																			ticks: {
																				fontSize: 5
																			}
																		}]
																	}
																},
															}`} />
													);
												})}
												<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 10 }}>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(255, 255, 0)', height: 20, width: 20, marginRight: 2 }}></div> Não Negociado
													</div>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(0, 200, 83)', height: 20, width: 20, marginRight: 2 }}></div> Abaixo do Negociado
													</div>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(41, 182, 246)', height: 20, width: 20, marginRight: 2 }}></div> Preço OK
													</div>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(255, 82, 82)', height: 20, width: 20, marginRight: 2 }}></div> Acima do Negociado
													</div>
												</div>
										</div>
									</ContentCardBody>							
								</ContentCard>
							</Grid>											
						
							<Grid cols='12 12 4 4'>
								<ContentCard>
									<ContentCardBody>
										<h5>Vigência das Negociações de Preço com Postos de Combustíveis</h5>
										<div style={{ display: 'flex', justifyContent: 'center' }}>												
											<img src={`
												https://quickchart.io/chart?height=188&width=90&c={
													type: 'bar',
													data: {
														labels: ['Negociações'],
														datasets: ${JSON.stringify(this.props.listaNegociacaoStatus.filter(status => status.quantidade > 0).map((status, i) => {

															let percentual = parseFloat(((parseInt(status.quantidade) * 100) / totalNegociacaoStatus).toFixed(1));
															
															percentualNegociacaoStatusTotal -= percentual;
															
															if (i == this.props.listaNegociacaoStatus.filter(status => status.quantidade > 0).filter(status => status.quantidade > 0).length -1) {
																percentual += percentualNegociacaoStatusTotal;
															}

															return {
																label: status.nome_status,
																backgroundColor: ['rgb(255, 255, 0)', 'rgb(41, 182, 246)', 'rgb(0, 200, 83)', 'rgb(255, 82, 82)'][status.id_status],
																data: [percentual.toFixed(1)]
															}
														}))}
													},
													options: {
														title: {
															display: false,
															text: '',
														},
														plugins: {
															datalabels: {
																color: 'rgb(0, 0, 0)',
																font: {
																	size: 7,
																	weight: 'bold'
																},
																formatter: (val, ctx) => {
																	return ${"`${val.replace('.', ',')}%`"}
																}
															}
														},
														legend: {
															display: false
														},
														scales: {
															xAxes: [{
																stacked: true,
																display: false,
																ticks: {
																	fontSize: 7
																}
															}],
															yAxes: [{
																stacked: true,
																display: false,
																ticks: {
																	fontSize: 5
																}
															}]
														}
													},
												}`} />

												<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 10 }}>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(255, 255, 0)', height: 20, width: 20, marginRight: 2 }}></div> 7 Dias
													</div>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(41, 182, 246)', height: 20, width: 20, marginRight: 2 }}></div> 15 Dias
													</div>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(0, 200, 83)', height: 20, width: 20, marginRight: 2 }}></div> 30 Dias
													</div>
													<div style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
														<div style={{ backgroundColor: 'rgb(255, 82, 82)', height: 20, width: 20, marginRight: 2 }}></div> Não Negociado
													</div>
												</div>
										</div>
									</ContentCardBody>							
								</ContentCard>
							</Grid>											
						
							<Grid cols='12 12 12 12'>
								<ContentCard>
									<ContentCardBody>
										<h5>Porcentagem dos Abastecimentos</h5>
										{/*<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
											<div style={{ display: 'flex' }}>
												<div style={{ backgroundColor: 'rgb(230, 193, 30)', height: 18, width: 30, marginRight: 5 }}></div> Abertas - {osTotalAbertas}
											</div>
											<div style={{ display: 'flex' }}>
												<div style={{ backgroundColor: 'rgb(18, 116, 201)', height: 18, width: 30, marginRight: 5 }}></div> Encerradas - {osTotalFinalizadas}
											</div>
											</div>*/}
										<div style={{ textAlign: 'center' }}>
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												<img src={`
													https://quickchart.io/chart?height=200&width=200&c={
														type: 'doughnut',
														data: {
															datasets: [{
																data: ${JSON.stringify(this.props.listaNegociacaoForma.map(forma => (
																	totalNegociacaoForma > 0 ?
																	parseFloat((parseInt(forma.quantidade) * 100) / totalNegociacaoForma).toFixed(1)
																	: 0
																)))},
																backgroundColor: ${JSON.stringify(this.props.listaNegociacaoForma.map((forma, i) => (
																	cores[i]
																)))},
																borderWidth: 2
															}]
														},
														options: {
															plugins: {
																datalabels: {
																	backgroundColor: 'rgb(0, 128, 128)',
																	color: 'rgb(255, 255, 255)',
																	font: {
																		size: 6
																	},
																	formatter: (val, ctx) => {
																		return ${"`${val.replace('.', ',')}%`"}
																	}
																}									
															}
														}
													}`} />
												<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 10 }}>
													{this.props.listaNegociacaoForma.map((forma, i) => (
														<div key={forma.forma_pagamento} style={{ display: 'flex', marginLeft: 0, fontSize: 16, marginBottom: 8 }}>
															<div style={{ backgroundColor: cores[i], height: 20, width: 20, marginRight: 2 }}></div> {forma.forma_pagamento}
														</div>
													))}
												</div>
											</div>
										</div>
									</ContentCardBody>							
								</ContentCard>
							</Grid>
						</Row>
					</Grid>
				</Row>
			</Content>
		)
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	permissoes: state.usuario.permissoes,
	filtro: state.dashboardFinanceiro.filtro,
	listaNegociacaoUtilizada: state.dashboardFinanceiro.listaNegociacaoUtilizada,
	listaNegociacaoStatus: state.dashboardFinanceiro.listaNegociacaoStatus,
	listaNegociacaoForma: state.dashboardFinanceiro.listaNegociacaoForma
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaNegociacaoUtilizada, getListaNegociacaoStatus, getListaNegociacaoForma, setFiltro
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardFinanceiro)
