import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './rotaForm';
import Select from '../common/form/select';

import { setModoTela, initForm, carregar, salvar, excluir, setFiltro, getLista, getListaCidade, getListaEstado, getListaPosto, getListaVeiculoTipo } from './rotaActions';

class Rota extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
        this.props.getListaEstado();
		this.props.getListaCidade();
		this.props.getListaPosto();
		this.props.getListaVeiculoTipo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_cadastro != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<Select
							options={this.props.listaCidade}
							label='Cidade'
							cols='12 12 6 6'
							placeholder='Selecione a cidade'
							value={this.props.filtro.id_cidade}
							onChange={(data) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_cidade: data
								});
								this.props.getLista();
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<thead>
							<Tr>
								<Th width={300}>Nome</Th>
								<Th width={200} alignCenter>Origem</Th>
								<Th width={200} alignCenter>Destino</Th>
								<Th width={300} alignCenter>Veículos</Th>
								<Th width={100} alignCenter>Qtd Coletas</Th>
								<Th width={100} alignCenter>Qtd Entregas</Th>
								<Th width={100} alignCenter>Código GR</Th>
								<Th width={100} alignCenter>Rota</Th>
								<Th width={100} alignCenter></Th>
							</Tr>
						</thead>
						<TBody>
							{this.props.lista
								.filter(item => {
									if (this.state.pesquisar
										&& !(`
											${item.nome}
											${item.nome_cidade_origem}
											${item.nome_estado_origem}
											${item.nome_cidade_destino}
											${item.nome_estado_destino}
											${item.nome_veiculo_tipos}
										`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
										return false;
									}
									return true;
								}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{item.nome_cidade_origem} - {item.nome_estado_origem}</Td>
									<Td alignCenter>{item.nome_cidade_destino} - {item.nome_estado_destino}</Td>
									<Td alignCenter>{item.nome_veiculo_tipos}</Td>
									<Td alignCenter>{(item.itens || []).filter(ponto => ponto.id_tipo == 1).length + 1}</Td>
									<Td alignCenter>{(item.itens || []).filter(ponto => ponto.id_tipo == 2).length + 1}</Td>
									<Td alignCenter>{item.codigo}</Td>
									<Td alignCenter>{item.nome_tipo}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_cadastro != 2}
											event={() => {
												this.props.carregar(item.id);
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_cadastro != 2}
											event={() => {
												this.props.carregar(item.id, true);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.rota,
	modoTela: state.rota.modoTela,
	filtro: state.rota.filtro,
	lista: state.rota.lista,
	permissoes: state.usuario.permissoes,
	listaCidade: state.rota.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, carregar, setFiltro, salvar, excluir, getLista, getListaCidade, getListaEstado, getListaPosto, getListaVeiculoTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Rota);
