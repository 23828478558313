const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaAbastecida:  [{
		id: 'true',
		valor: 'Sim'
	}, {
		id: 'false',
		valor: 'Não'
	}],


};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'TIPO_DESPESA_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'TIPO_DESPESA_LISTADO':
			return { ...state, lista: action.payload.data };


		case 'TIPO_DESPESA_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};
		
			case 'TIPO_DESPESA_VIAGEM_ABASTECIDA_SELECT_LISTADO':
			return { ...state, listaAbastecida: action.payload.data };


		default:
			return state;
	}
}
