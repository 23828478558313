import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'VALE_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('valeForm', {
			...registro
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'VALE_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {

	return (dispatch, getState) => {
		dispatch(setAguardando(true));		
		axios.get(`${consts.API_URL}/vale
			${getState().auth.usuario && getState().auth.usuario.id_motorista ?
				`?id_motorista=${getState().auth.usuario.id_motorista}`
			: ''}
		`).then(resp => {
			dispatch({
				type: 'VALE_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};
}


export function salvar(registro) {

    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/vale`, {
				...registro,
                datahora_vale: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_vale),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/vale`, {
				...registro,
				datahora_vale: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_vale),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/vale?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvarConferido(registro) {

    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/vale/conferido`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'VALE_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}
