import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './viagemLancamentoForm';
import Select from '../common/form/select';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, initForm, salvar, excluir, getLista, setFiltro,
	getListaMotorista, getListaVeiculo, getListaVeiculoTipo 
} from './viagemLancamentoActions';
import { salvar as salvarViagem } from '../viagem/viagemActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ViagemForm from '../viagem/viagemForm';

class ViagemLancamento extends Component {

	state = {
		linhaSelecionada: null,
		id_status: 1,
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaMotorista();
		this.props.getListaVeiculo();
		this.props.getListaVeiculoTipo();
    }

    render() {
        return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'viagem' ? (
						<ViagemForm visualizacaoViagem onSubmit={this.props.salvarViagem} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_viagem != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							name='id_status'
							label='Status' placeholder='Informe o status'
							cols='12 12 4 4'
							options={[{ id: 1, valor: 'Abertas' }, { id: 2, valor: 'Fechadas' }]}
							value={this.state.id_status}
							onChange={(item) => {
								this.setState({
									...this.state,
									id_status: item
								});
							}}/>
					</Row>
					<Row>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
					
						<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>

							<Tr>
								<Th >Número Viagem</Th>
								<Th >Data/Hora Início</Th>
								<Th >Data/Hora Fim</Th>
								<Th alignCenter>Dias</Th>
                                <Th alignCenter>Motorista</Th>
                                <Th alignCenter>Veículo</Th>
								<Th alignCenter>Tipo</Th>
								<Th alignCenter>Tipo Viagem</Th>
								<Th alignRight>Faturamento</Th>
								<Th alignRight>Comissão</Th>
								<Th alignRight>Despesa</Th>
								<Th alignRight>Combustível</Th>
								<Th alignRight>Resultado</Th>
								<Th></Th>
							</Tr>

						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.id_status == 1 && item.datahora_fim) {
									return false;
								} else if (this.state.id_status == 2 && !item.datahora_fim) {
									return false
								} else {
									return true;
								}
							}).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.numero_viagem} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio)} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim)}
										${item.dias_viagem} ${item.nome_motorista} ${item.placa_veiculo} ${item.nome_veiculo_tipo}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.numero_viagem}</Td>
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio)}</Td>
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim)}</Td>
									<Td alignCenter>{item.dias_viagem}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
                                    <Td alignCenter>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.nome_veiculo_tipo}</Td>
									<Td alignCenter>{item.nome_veiculo_terceiro ? 'Terceiro' : 'Frota Própria'}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.total_faturamento, 2)}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.total_comissao_motorista, 2)}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.total_despesas, 2)}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.total_abastecidas, 2)}</Td>
									<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.total_faturamento - item.total_comissao_motorista - item.total_despesas - item.total_abastecidas, 2)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_viagem != 2}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_viagem != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.viagemLancamento,
	modoTela: state.viagemLancamento.modoTela,
	lista: state.viagemLancamento.lista,
	aguardando: state.viagemLancamento.aguardando,
	filtro: state.viagemLancamento.filtro,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getLista, setFiltro, 
	getListaMotorista, getListaVeiculo, getListaVeiculoTipo, salvarViagem 
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViagemLancamento);
