import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'DEVOLUCAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('devolucaoForm', {
			...registro
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'DEVOLUCAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {

	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/devolucao
			${getState().auth.usuario && getState().auth.usuario.id_motorista ?
				`?id_motorista=${getState().auth.usuario.id_motorista}`
			: ''}
		`).then(resp => {
			dispatch({
				type: 'DEVOLUCAO_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};

}

export function buscarImagens(id) {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
	    axios.get(`${consts.API_URL}/devolucao/imagens?id=${id}`).then(retorno => {
			dispatch(initForm({
				...getState().devolucao.registro,
				imagem: retorno.data ? retorno.data.imagem : null,
				imagem_valor: retorno.data ? retorno.data.imagem_valor : null
			}));
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};
}

export function salvar(registro) {

    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/devolucao`, {
				...registro,
                datahora_devolucao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_devolucao),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/devolucao`, {
				...registro,
				datahora_devolucao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_devolucao),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/devolucao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvarConferido(registro) {

    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/devolucao/conferido`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'DEVOLUCAO_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaContaBancaria() {
    const request = axios.get(`${consts.API_URL}/contaBancaria/listarSelect`);
    return {
        type: 'DEVOLUCAO_CONTA_BANCARIA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'DEVOLUCAO_CIDADE_SELECT_LISTADO',
        payload: request
    };
}