import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';

import {
	setModoTela, initForm
} from './multaActions';
import DateFormat from '../common/dateFormat/DateFormat';

class MultaForm extends Component {

	state = {

	}

	componentDidMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';
		let readOnlyAlteracao = this.props.formularioValues.id ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='datahora_infracao'
								label='Data Hora' placeholder='Informe a data e hora'
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='12 6 3 3'
								readOnly={readOnly || readOnlyAlteracao} />

							<Field
								name='id_motorista'
								component={Select}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 6 5 5'
								readOnly={readOnly || readOnlyAlteracao}
								options={this.props.listaMotorista} />

							<Field
								name='id_veiculo'
								component={Select}
								label='Veículo' placeholder='Informe o veículo'
								cols='12 6 4 4'
								readOnly={readOnly}
								options={this.props.listaVeiculo} />

							<Field
								name='auto_infracao'
								component={LabelAndInput}
								label='Auto de Infração' placeholder='Informe o auto de infração'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='id_cidade'
								component={Select}
								label='Cidade' placeholder='Informe a cidade'
								cols='12 6 3 3'
								readOnly={readOnly}
								options={this.props.listaCidade} />
								
							<Field
								name='id_causador'
								component={Select}
								label='Causador' placeholder='Informe o causador'
								cols='12 6 3 3'
								readOnly={readOnly}
								options={this.props.listaCausador} />
								
							<Select
								name='reconhecida'
								label='Reconhecida' placeholder='Informe se foi reconhecida'
								cols='12 6 3 3'
								readOnly={readOnly}
								options={this.props.listaReconhecida}
								value={this.props.formularioValues.reconhecida ? 1 : 2}
								onChange={item => {
									this.props.initForm({
										...this.props.formularioValues,
										reconhecida: item == 1
									})
								}} />

							<Field
								name='id_grau'
								component={Select}
								label='Grau' placeholder='Informe o grau'
								cols='12 6 3 3'
								readOnly={readOnly}
								options={this.props.listaGrau} />
								
							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor' placeholder='Informe o valor'
								cols='12 6 3 3'
								readOnly={readOnly || readOnlyAlteracao}
								casas={2} />
								
							<Field
								name='id_forma_pagamento'
								component={Select}
								label='Forma de Pagamento' placeholder='Informe a forma de pagamento'
								cols='12 6 3 3'
								readOnly={readOnly}
								options={this.props.listaFormaPagamento} />
								
							<Field
								name='parcelas'
								component={Select}
								label='Parcelas' placeholder='Informe as parcelas'
								cols='12 6 3 3'
								readOnly={readOnly || readOnlyAlteracao}
								options={this.props.listaParcelas} />

							<Field
								name='data_vencimento'
								label='Data de Vencimento' placeholder='Informe a data de vencimento'
								component={LabelAndInputMask}
								mask='99/99/9999'
								cols='12 6 3 3'
								readOnly={readOnly || readOnlyAlteracao} />
								
							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição Auto de Infração' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={readOnly} />
								
							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>		
						
						<Table responsive>
							<Tr>
								<Th>Status</Th>
								<Th alignCenter>Data Vencimento</Th>
								<Th alignCenter>Data Hora Pagamento</Th>
								<Th alignCenter>Acerto de Viagem</Th>
								<Th alignRight>Valor</Th>
							</Tr>
							<TBody>
								{(this.props.formularioValues.itens || []).filter(item => item).map(item => (
									<Tr key={item.id} >
										<Td>{item.datahora_pagamento && item.fechado ? 'Quitado' : 'Aguardando'}</Td>
										<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_vencimento)}</Td>
										<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento)}</Td>
										<Td alignCenter>{item.numero_viagem}</Td>
										<Td alignRight>R$ {FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									</Tr>
								))}
							</TBody>
						</Table>						
								
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										disabled={false}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										disabled={false}
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

MultaForm = reduxForm({ form: 'multaForm', destroyOnUnmount: false })(MultaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('multaForm')(state),
	registro: state.multa.registro,
	listaCidade: state.multa.listaCidade,
	listaMotorista: state.multa.listaMotorista,
	listaVeiculo: state.multa.listaVeiculo,
	listaCausador: state.multa.listaCausador,
	listaGrau: state.multa.lisaGrau,
	listaFormaPagamento: state.multa.listaFormaPagamento,
	listaReconhecida: state.multa.listaReconhecida,
	listaParcelas: state.multa.listaParcelas
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MultaForm);
