import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ABASTECIDA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('abastecidaForm', {
			...registro
		})
    ];
}

export function setFiltro(filtro) {
	return {
		type: 'ABASTECIDA_FILTRO',
		payload: filtro,
	};
}

export function setAguardando(aguardando) {
    return {
        type: 'ABASTECIDA_SET_AGUARDANDO',
        payload: aguardando
    };
}


export function getLista() {

	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/abastecida?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().abastecida.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().abastecida.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().abastecida.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().abastecida.filtro.data_final)}
			${getState().auth.usuario && getState().auth.usuario.id_motorista ?
				`&id_motorista=${getState().auth.usuario.id_motorista}`
			: ''}
		`).then(resp => {
			dispatch({
				type: 'ABASTECIDA_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};
}

export function buscarImagens(id) {
	return (dispatch, getState) => {
	    axios.get(`${consts.API_URL}/abastecida/imagens?id=${id}`).then(retorno => {
			dispatch(initForm({
				...getState().abastecida.registro,
				imagem_odometro: retorno.data ? retorno.data.imagem_odometro : null,
				imagem_nota_fiscal: retorno.data ? retorno.data.imagem_nota_fiscal : null
			}));
		}).catch(ex => {
			console.log(ex);
		});
	};
}


export function salvar(registro) {

    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/abastecida`, {
				...registro,
                datahora_abastecida: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_abastecida),
				quantidade: FormatUtils.formatarValorBanco(registro.quantidade, 2),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				preco: registro.quantidade && registro.valor ? (parseFloat(FormatUtils.formatarValorBanco(registro.valor, 2)) / parseFloat(FormatUtils.formatarValorBanco(registro.quantidade, 2))).toFixed(3) : 0,
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/abastecida`, {
				...registro,
				datahora_abastecida: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_abastecida),
				quantidade: FormatUtils.formatarValorBanco(registro.quantidade, 2),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				preco: registro.quantidade && registro.valor ? (parseFloat(FormatUtils.formatarValorBanco(registro.valor, 2)) / parseFloat(FormatUtils.formatarValorBanco(registro.quantidade, 2))).toFixed(3) : 0,
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function salvarConferido(registro) {

    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/abastecida/conferido`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvarAtua(registro) {

    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/abastecida/atua`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/abastecida?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaPosto() {
    const request = axios.get(`${consts.API_URL}/posto/listarSelect`);
    return {
        type: 'ABASTECIDA_POSTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'ABASTECIDA_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'ABASTECIDA_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFormaPagamento() {
    const request = axios.get(`${consts.API_URL}/formaPagamento/listarSelect`);
    return {
        type: 'ABASTECIDA_FORMA_PAGAMENTO_SELECT_LISTADO',
        payload: request
    };
}
