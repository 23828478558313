const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	filtro: {
		id_cidade: null
	},
	lista: [],
	listaEstado: [],
	listaCidade: [],
	listaPosto: [],
	listaTipo: [{ id: 1, valor: 'Especial' }, { id: 2, valor: 'Padrão' }],
	listaVeiculoTipo: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'ROTA_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'ROTA_FILTRO':
			return {
				...state,
				filtro: action.payload.filtro
			};

		case 'ROTA_LISTADO':
			return { ...state, lista: action.payload.data };

		case 'ROTA_ESTADO_SELECT_LISTADO':
			return { ...state, listaEstado: action.payload.data };

		case 'ROTA_CIDADE_SELECT_LISTADO':
			return { ...state, listaCidade: action.payload.data };

		case 'ROTA_POSTO_SELECT_LISTADO':
			return { ...state, listaPosto: action.payload.data };

		case 'ROTA_VEICULO_TIPO_SELECT_LISTADO':
			return { ...state, listaVeiculoTipo: action.payload.data };

		default:
			return state;
	}
}
