import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp';
import Dashboard from '../dashboard/dashboard';
import DashboardManutencao from '../dashboardManutencao/dashboardManutencao';
import DashboardFinanceiro from '../dashboardFinanceiro/dashboardFinanceiro';
// import DashboardLogistica from '../dashboardLogistica/dashboardLogistica';
import Usuario from '../usuario/usuario';
import Cotacao from '../cotacao/cotacao';
import Posto from '../posto/posto';
import Ponto from '../ponto/ponto';
import PontoTipo from '../pontoTipo/pontoTipo';
import TipoDespesa from '../tipoDespesa/tipoDespesa';
import ViagemLancamento from '../viagemLancamento/viagemLancamento';
import DespesaViagem from '../despesaViagem/despesaViagem';
import Checklist from '../checklist/checklist';
import ChecklistPerguntas from '../checklist/checklistPerguntas/checklistPerguntas';
import DespesaProduto from '../despesaProduto/despesaProduto';
import Abastecida from '../abastecida/abastecida';
import Vale from '../vale/vale';
import Devolucao from '../devolucao/devolucao';
import VeiculoTipo from '../veiculoTipo/veiculoTipo';
import Veiculo from '../veiculo/veiculo';
import VeiculoCarreta from '../veiculo/veiculoCarreta';
import Produto from '../produto/produto';
import PlanejamentoViagem from '../planejamentoViagem/planejamentoViagem';
import Rede from '../rede/rede';
import FormaPagamento from '../formaPagamento/formaPagamento';
import Rota from '../rota/rota';
import Motorista from '../motorista/motorista';
import Setor from '../setor/setor';
import Ocorrencia from '../ocorrencia/ocorrencia';
import OrdemServico from '../ordemServico/ordemServico';
import ContaBancaria from '../contaBancaria/contaBancaria';
import Multa from '../multa/multa';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route exact path='/terceiro' component={Dashboard} />
			<Route path='/dashboardManutencao' component={DashboardManutencao} />
			<Route path='/dashboardFinanceiro' component={DashboardFinanceiro} />
			{/* <Route path='/DashboardLogistica' component={DashboardLogistica} /> */}
			<Route path='/usuario' component={Usuario} />
			<Route path='/cotacao' component={Cotacao} />
			<Route path='/posto' component={Posto} />
			<Route path='/pontoTipo' component={PontoTipo} />
			<Route path='/ponto' component={Ponto} />
			<Route path='/rede' component={Rede} />
			<Route path='/formaPagamento' component={FormaPagamento} />
			<Route path='/tipoDespesa' component={TipoDespesa} />
			<Route path='/veiculoTipo' component={VeiculoTipo} />
			<Route path='/veiculo' component={Veiculo} />
			<Route path='/veiculoCarreta' component={VeiculoCarreta} />
			<Route path='/produto' component={Produto} />
			<Route path='/viagemLancamento' component={ViagemLancamento} />
			<Route path='/despesaViagem' component={DespesaViagem} />
			<Route path='/checklist' component={Checklist} />
			<Route path='/checklistPerguntas' component={ChecklistPerguntas} />
			<Route path='/despesaProduto' component={DespesaProduto} />
			<Route path='/abastecida' component={Abastecida} />
			<Route path='/vale' component={Vale} />
			<Route path='/devolucao' component={Devolucao} />
			<Route path='/planejamentoViagem' component={PlanejamentoViagem} />
			<Route path='/rota' component={Rota} />
			<Route path='/motorista' component={Motorista} />
			<Route path='/setor' component={Setor} />
			<Route path='/ocorrencia' component={Ocorrencia} />
			<Route path='/ordemServico' component={OrdemServico} />
			<Route path='/contaBancaria' component={ContaBancaria} />
			<Route path='/multa' component={Multa} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
