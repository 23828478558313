import React from 'react'
import Grid from '../layout/grid'

export default props => {
	return (
		<Grid cols={props.cols}>
	        <div className='form-group'>
	            <label htmlFor={props.name}>{props.label}</label>
				{props.onChange ? (
 				   <input {...props.input} className='form-control' style={{ backgroundColor: props.backgroundColor }}
 						placeholder={props.placeholder}
 						readOnly={props.readOnly} type={props.type}
 						value={props.value}
 						onChange={props.onChange} />
 			   ) : (
 				   <input {...props.input} className='form-control'
 						placeholder={props.placeholder}
 						readOnly={props.readOnly} type={props.type} />
 			   )}
	        </div>
	    </Grid>
	);
}
