import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './postoNegociacaoForm';

import { setModoTela, initForm, salvar, excluir, getLista } from './postoNegociacaoActions';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

class PostoNegociacao extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista(this.props.registroPosto ? this.props.registroPosto.id : 0);
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ id_posto: this.props.registroPosto.id, data_negociacao: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()) });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Produto</Th>
								<Th alignCenter>Negociação</Th>
								<Th alignCenter>Prazo</Th>
								<Th alignCenter>Validade</Th>
								<Th alignCenter>Anterior</Th>
								<Th alignCenter>Atual</Th>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Valor Alterado</Th>
								<Th alignCenter>Variação</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_produto}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_negociacao)}</Td>
									<Td alignCenter>{item.prazo}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_validade)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.preco_anterior, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.preco_atual, 2)}</Td>
									<Td alignCenter style={{ fontWeight: 'bold', color: '#fff', backgroundColor: [null, '#00c853', '#29b6f6', '#ff5252'][item.id_status] }}>{item.nome_status}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.preco_anterior && item.preco_atual ? (item.preco_anterior > item.preco_atual ? item.preco_anterior - item.preco_atual : item.preco_atual - item.preco_anterior) : 0, 2)}</Td>
                                    <Td alignCenter>{FormatUtils.formatarValorTela(item.variacao, 2)} %</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													data_negociacao: DateFormat.formatarDataSqlParaTela(item.data_negociacao),
													preco_atual: FormatUtils.formatarValorTela(item.preco_atual, 2),
													preco_anterior: FormatUtils.formatarValorTela(item.preco_anterior, 2)
												});
												this.props.initForm({
													...item,
													data_negociacao: DateFormat.formatarDataSqlParaTela(item.data_negociacao),
													preco_atual: FormatUtils.formatarValorTela(item.preco_atual, 2),
													preco_anterior: FormatUtils.formatarValorTela(item.preco_anterior, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_negociacao: DateFormat.formatarDataSqlParaTela(item.data_negociacao),
													preco_atual: FormatUtils.formatarValorTela(item.preco_atual, 2),
													preco_anterior: FormatUtils.formatarValorTela(item.preco_anterior, 2)
												});
												this.props.initForm({
													...item,
													data_negociacao: DateFormat.formatarDataSqlParaTela(item.data_negociacao),
													preco_atual: FormatUtils.formatarValorTela(item.preco_atual, 2),
													preco_anterior: FormatUtils.formatarValorTela(item.preco_anterior, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.postoNegociacao,
	modoTela: state.postoNegociacao.modoTela,
	lista: state.postoNegociacao.lista,
	registroPosto: state.posto.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PostoNegociacao);
