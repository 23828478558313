import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Form from '../common/form/form';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir } from './cotacaoActions';

class Cotacao extends Component {

    state = {
        linhaSelecionada: null
    }

    componentDidMount() {
        this.props.initForm({});

    }

    render() {
        if (!this.props.formularioValues) {

            return null;
        }
        //COTAÇÃO 1
        let frete = parseFloat(String(this.props.formularioValues.frete || 0).split(".").join("").replace(",", "."));
        let km = parseFloat(String(this.props.formularioValues.km || 0).split(".").join("").replace(",", "."));
        let valor_carga = parseFloat(String(this.props.formularioValues.valor_carga || 0).split(".").join("").replace(",", "."));
		let percentual_margem = parseFloat(String(this.props.formularioValues.percentual_margem || 0).split(".").join("").replace(",", "."));
        let percentual_comissao = parseFloat(String(this.props.formularioValues.percentual_comissao || 0).split(".").join("").replace(",", "."));
        let percentual_icms = parseFloat(String(this.props.formularioValues.percentual_icms || 0).split(".").join("").replace(",", "."));
        let percentual_seguro = parseFloat(String(this.props.formularioValues.percentual_seguro || 0).split(".").join("").replace(",", "."));
        let gr = parseFloat(String(this.props.formularioValues.gr || 0).split(".").join("").replace(",", "."));

        // COTAÇÃO 2
		let frete2 = parseFloat(String(this.props.formularioValues.frete2 || 0).split(".").join("").replace(",", "."));
        let km2 = parseFloat(String(this.props.formularioValues.km2 || 0).split(".").join("").replace(",", "."));
        let valor_carga2 = parseFloat(String(this.props.formularioValues.valor_carga2 || 0).split(".").join("").replace(",", "."));
		let percentual_margem2 = parseFloat(String(this.props.formularioValues.percentual_margem2 || 0).split(".").join("").replace(",", "."));
        let percentual_comissao2 = parseFloat(String(this.props.formularioValues.percentual_comissao2 || 0).split(".").join("").replace(",", "."));
        let percentual_icms2 = parseFloat(String(this.props.formularioValues.percentual_icms2 || 0).split(".").join("").replace(",", "."));
        let percentual_seguro2 = parseFloat(String(this.props.formularioValues.percentual_seguro2 || 0).split(".").join("").replace(",", "."));
        let gr2 = parseFloat(String(this.props.formularioValues.gr2 || 0).split(".").join("").replace(",", "."));

        //COTAÇÃO 1
		let margem = frete * (percentual_margem / 100);
        let ICMS = frete * (percentual_icms / 100);
        let comissao = frete * (percentual_comissao / 100);
        let seguro = valor_carga * (percentual_seguro / 100);
        let GR = gr;
        let valorKmFrete = frete - ICMS - margem - comissao - seguro - GR;
        let lucroKmBruto = frete / km;
        let lucroLiquido = valorKmFrete / km;

        //COTAÇÃO 2
		let margem2 = frete2 * (percentual_margem2 / 100);
        let ICMS2 = frete2 * (percentual_icms2 / 100);
        let comissao2 = frete2 * (percentual_comissao2 / 100);
        let seguro2 = valor_carga2 * (percentual_seguro2 / 100);
        let GR2 = gr2;
        let valorKmFrete2 = frete2 - ICMS2 - margem2 - comissao2 - seguro2 - GR2;
        let lucroKmBruto2 = frete2 / km2;
        let lucroLiquido2 = valorKmFrete2 / km2;


        return (
            <ContentCard  style= {{margin:10}}>
                <ContentCardHeader>
                </ContentCardHeader>
                <ContentCardBody >

                    <Row>
                        <Grid cols="6 6 6 6">

                            <Form event={this.props.handleSubmit}>

                                <label> COTAÇÃO 1ª</label>
                                <Row>
                                    <Field

                                        name='frete'
                                        component={LabelAndInputNumber}
                                        label='Frete' placeholder='Informe  o frete'
                                        cols='6 6 6 6 '
                                        casas={2} />

                                    <Field
                                        name='valor_carga'
                                        component={LabelAndInputNumber}
                                        label='Valor Carga' placeholder='Informe  o valor carga'
                                        cols='6 6 6 6 '
                                        casas={2} />

                                    <Field
                                        name='km'
                                        component={LabelAndInputNumber}
                                        label='Km' placeholder='Informe  o Km'
                                        cols='6 6 6 6 '
                                        casas={2} />

                                    <Field
                                        name='gr'
                                        component={LabelAndInputNumber}
                                        label='Gerenciamento de Risco' placeholder='Informe  o Gerenciamento de Risco'
                                        cols='6 6 6 6 '
                                        casas={2} />

                                    <Field
                                        name='percentual_margem'
                                        component={LabelAndInputNumber}
                                        label='Margem Empresa' placeholder='Informe a margem'
                                        cols='6 6 6 6 '
                                        casas={2} />

                                    <Field
                                        name='percentual_comissao'
                                        component={LabelAndInputNumber}
                                        label='Comissão Comercial' placeholder='Informe  a comissão'
                                        cols='6 6 6 6 '
                                        casas={2} />

                                    <Field
                                        name='percentual_icms'
                                        component={LabelAndInputNumber}
                                        label='ICMS' placeholder='Informe  o icms'
                                        cols='6 6 6 6 '
                                        casas={2} />

                                    <Field
                                        name='percentual_seguro'
                                        component={LabelAndInputNumber}
                                        label='Seguro da Carga' placeholder='Informe  o seguro'
                                        cols='6 6 6 6 '
                                        casas={2} />
                                </Row>

                            </Form>
                        </Grid>

                        <Grid cols="6 6 6 6">

                            <Table responsive >
                                <THead>
                                    <p style={{ fontSize: 22, color: '#1C8403' }}> Extrato de Cotação </p>
                                </THead>
                                <TBody>
									<Tr>
										<Td style={{ fontSize: 20 }}> Margem Empresa</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
										<Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(margem, 2)} </Td>
									</Tr>
                                    <Tr>
                                        <Td style={{ fontSize: 20 }}> Comissão Comercial</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
                                        <Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(comissao, 2)} </Td>
                                    </Tr>
                                    <Tr>
                                        <Td style={{ fontSize: 20 }}> ICMS</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
                                        <Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(ICMS, 2)} </Td>
                                    </Tr>
                                    <Tr>
                                        <Td style={{ fontSize: 20 }}> Seguro da Carga</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
                                        <Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(seguro, 2)} </Td>
                                    </Tr>
                                    <Tr>
                                        <Td style={{ fontSize: 20 }}> Gerenciamento de Risco</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
                                        <Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(GR, 2)} </Td>
                                    </Tr>
                                    <Tr backgroundColor='#1C8403' color='#fff'>
                                        <Td style={{ fontSize: 22 }}> Valor Frete</Td>
										<Td alignRight style={{ fontSize: 22 }}>R$</Td>
                                        <Td alignRight style={{ fontSize: 22 }}>{FormatUtils.formatarValorTelaDecimal(valorKmFrete, 2)} </Td>
                                    </Tr>
                                    <Tr backgroundColor='#1C8403' color='#fff'>
                                        <Td style={{ fontSize: 22 }}> Valor do KM Bruto</Td>
										<Td alignRight style={{ fontSize: 22 }}>R$</Td>
                                        <Td alignRight style={{ fontSize: 22 }}>{FormatUtils.formatarValorTelaDecimal(lucroKmBruto, 2)} </Td>
                                    </Tr>
                                    <Tr backgroundColor='#1C8403' color='#fff'>
                                        <Td style={{ fontSize: 22 }}> Valor do KM Líquido</Td>
										<Td alignRight style={{ fontSize: 22 }}>R$</Td>
                                        <Td alignRight style={{ fontSize: 22 }}>{FormatUtils.formatarValorTelaDecimal(lucroLiquido, 2)} </Td>
                                    </Tr>
                                </TBody>
                            </Table>

                        </Grid>
                    </Row>

                    <br></br>

					<Row>
						<Grid cols="6 6 6 6">

							<Form event={this.props.handleSubmit}>

								<label> COTAÇÃO 2ª</label>
								<Row>
									<Field

										name='frete2'
										component={LabelAndInputNumber}
										label='Frete' placeholder='Informe  o frete'
										cols='6 6 6 6 '
										casas={2} />

									<Field
										name='valor_carga2'
										component={LabelAndInputNumber}
										label='Valor Carga' placeholder='Informe  o valor carga'
										cols='6 6 6 6 '
										casas={2} />

									<Field
										name='km2'
										component={LabelAndInputNumber}
										label='Km' placeholder='Informe  o Km'
										cols='6 6 6 6 '
										casas={2} />

									<Field
										name='gr2'
										component={LabelAndInputNumber}
										label='Gerenciamento de Risco' placeholder='Informe  o Gerenciamento de Risco'
										cols='6 6 6 6 '
										casas={2} />

									<Field
										name='percentual_margem2'
										component={LabelAndInputNumber}
										label='Margem Empresa' placeholder='Informe a margem'
										cols='6 6 6 6 '
										casas={2} />

									<Field
										name='percentual_comissao2'
										component={LabelAndInputNumber}
										label='Comissão Comercial' placeholder='Informe  a comissão'
										cols='6 6 6 6 '
										casas={2} />

									<Field
										name='percentual_icms2'
										component={LabelAndInputNumber}
										label='ICMS' placeholder='Informe  o icms'
										cols='6 6 6 6 '
										casas={2} />

									<Field
										name='percentual_seguro2'
										component={LabelAndInputNumber}
										label='Seguro da Carga' placeholder='Informe  o seguro'
										cols='6 6 6 6 '
										casas={2} />
								</Row>

							</Form>
						</Grid>

						<Grid cols="6 6 6 6">

							<Table responsive >
								<THead>
									<p style={{ fontSize: 22, color: '#1C8403' }}> Extrato de Cotação </p>
								</THead>
								<TBody>
									<Tr>
										<Td style={{ fontSize: 20 }}> Margem Empresa</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
										<Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(margem2, 2)} </Td>
									</Tr>
									<Tr>
										<Td style={{ fontSize: 20 }}> Comissão Comercial</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
										<Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(comissao2, 2)} </Td>
									</Tr>
									<Tr>
										<Td style={{ fontSize: 20 }}> ICMS</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
										<Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(ICMS2, 2)} </Td>
									</Tr>
									<Tr>
										<Td style={{ fontSize: 20 }}> Seguro da Carga</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
										<Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(seguro2, 2)} </Td>
									</Tr>
									<Tr>
										<Td style={{ fontSize: 20 }}> Gerenciamento de Risco</Td>
										<Td alignRight style={{ fontSize: 20 }}>R$</Td>
										<Td alignRight style={{ fontSize: 20 }}>{FormatUtils.formatarValorTelaDecimal(GR2, 2)} </Td>
									</Tr>
									<Tr backgroundColor='#1C8403' color='#fff'>
										<Td style={{ fontSize: 22 }}> Valor Frete</Td>
										<Td alignRight style={{ fontSize: 22 }}>R$</Td>
										<Td alignRight style={{ fontSize: 22 }}>{FormatUtils.formatarValorTelaDecimal(valorKmFrete2, 2)} </Td>
									</Tr>
									<Tr backgroundColor='#1C8403' color='#fff'>
										<Td style={{ fontSize: 22 }}> Valor do KM Bruto</Td>
										<Td alignRight style={{ fontSize: 22 }}>R$</Td>
										<Td alignRight style={{ fontSize: 22 }}>{FormatUtils.formatarValorTelaDecimal(lucroKmBruto2, 2)} </Td>
									</Tr>
									<Tr backgroundColor='#1C8403' color='#fff'>
										<Td style={{ fontSize: 22 }}> Valor do KM Líquido</Td>
										<Td alignRight style={{ fontSize: 22 }}>R$</Td>
										<Td alignRight style={{ fontSize: 22 }}>{FormatUtils.formatarValorTelaDecimal(lucroLiquido2, 2)} </Td>
									</Tr>
								</TBody>
							</Table>

						</Grid>
					</Row>
                </ContentCardBody>
            </ContentCard>


        );
    }


}


Cotacao = reduxForm({ form: 'cotacao', destroyOnUnmount: false })(Cotacao);
const mapStateToProps = state => ({
    sessao: state.auth.usuario,
    formularioValues: getFormValues('cotacao')(state),
    registro: state.cotacao.registro,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Cotacao);
