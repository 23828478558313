import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import DateFormat from '../common/dateFormat/DateFormat';
import ButtonTable from '../common/table/buttonTable';

import {
	setModoTela, initForm, initFormExecucao, iniciar, finalizar, alterarVeiculo, adicionarOcorrencia, salvarExecucao, getLista, gerarPdfNovo
} from './ordemServicoActions';


class OrdemServicoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		if (this.props.formularioValues.id 
			&& !(this.props.formularioValues.controle_pneus || []).length 
			&& this.props.formularioValues.id_setor == 1 /*BORRACHARIA*/) {

			let cavaloEixos = this.props.formularioValues.eixos_veiculo || [];
			let carreta1Eixos = this.props.formularioValues.eixos_carreta1 || [];
			let carreta2Eixos = this.props.formularioValues.eixos_carreta2 || [];
			
			let conjunto = [];
			if (cavaloEixos.length) {
				let cavalo = {
					id: 1,
					tipo: 'Cavalo',
					cor: '#cfd1d0',
					placa: this.props.formularioValues.placa_veiculo,
					eixos: []
				};
				(cavaloEixos || []).forEach((eixo, i) => {
					cavalo.eixos.push({
						id: cavalo.eixos.length + 1,
						pneus: Array.from(Array(eixo).keys()).map((pneu, j) => ({
							id: j+1,
							numero: ''
						}))
					});
				});
				conjunto.push(cavalo);
			}

			if (carreta1Eixos.length) {
				let carreta1 = {
					id: 2,
					tipo: 'Carreta 1',
					cor: '#e4ede8',
					placa: this.props.formularioValues.placa_carreta1,
					eixos: []
				};
				(carreta1Eixos || []).forEach((eixo, i) => {
					carreta1.eixos.push({
						id: carreta1.eixos.length + 1,
						pneus: Array.from(Array(eixo).keys()).map((pneu, j) => ({
							id: j+1,
							numero: ''
						}))
					});
				});
				conjunto.push(carreta1);
			}

			if (carreta1Eixos.length) {
				let carreta2 = {
					id: 3,
					tipo: 'Carreta 2',
					cor: '#cfd1d0',
					placa: this.props.formularioValues.placa_carreta2,
					eixos: []
				};
				(carreta2Eixos || []).forEach((eixo, i) => {
					carreta2.eixos.push({
						id: carreta2.eixos.length + 1,
						pneus: Array.from(Array(eixo).keys()).map((pneu, j) => ({
							id: j+1,
							numero: ''
						}))
					});
				});
				conjunto.push(carreta2);
			}

			if (conjunto.length) {
				this.props.initForm({
					...this.props.formularioValues,
					controle_pneus: conjunto,
					estepes: Array.from(Array(4).keys()).map((pneu, i) => ({
						id: i+1,
						numero: ''
					})),
					estourados: Array.from(Array(4).keys()).map((pneu, i) => ({
						id: i+1,
						numero: ''
					}))
				});
			}
		}

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let itensAbertos = (this.props.formularioValues.ocorrencias || []).filter(item => item.realizado == null).length 
		|| (this.props.formularioValues.ocorrencia_checklists || []).filter(item => item.realizado == null).length;




		return (
			<ContentCard>
				
				{this.state.osIniciar || this.state.osFinalizar ? this.modalData() : null}

				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Row>
							<Field
								name='id_setor'
								component={Select}
								label='Setor' placeholder='Informe o setor'
								cols='12 12 12 12'
								readOnly={'readOnly'}
								options={this.props.listaSetor} />

							<Field
								name='id_motorista'
								component={Select}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 12 12 12'
								readOnly={'readOnly'}
								options={this.props.listaMotorista} />
								
							<Field
								name='id_usuario_responsavel'
								component={Select}
								label='Responsável' placeholder='Informe o responsável'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaUsuario} />
								
							<Field
								name='datahora_inicio_manutencao'
								label='Data Hora Início Manutenção' placeholder='Informe a data '
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='12 6 4 3'
								readOnly={readOnly}
							/>

							<Field
								name='datahora_fim_manutencao'
								label='Data Hora Fim Manutenção' placeholder='Informe a data '
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='12 6 4 3'
								readOnly={itensAbertos ? 'readOnly' : readOnly}
							/>

							<Field
								name='id_veiculo'
								component={Select}
								label='Veículo' placeholder='Informe o veículo'
								cols='12 12 12 12'
								readOnly={this.props.sessao.id == 7 || this.props.sessao.id == 1 ? readOnly : 'readonly'}
								options={this.props.listaVeiculo} />
								
							<Field
								name='km_chegada'
								component={LabelAndInputNumber}
								label='Km Chegada' placeholder='Informe o km'
								cols='12 6 3 2'
								readOnly={'readOnly'}
								casas={0} />
						
						</Row>

						{((this.props.formularioValues.controle_pneus || []).length > 0) &&
						<Row>
							<Grid cols='12 12 8 9'>
								<Table>
									<TBody>
										{/*Pneu externo da direita*/}
										<Tr>
											{(this.props.formularioValues.controle_pneus || []).map(item => (
												item.eixos.map(eixo => {
													const pneu = eixo.pneus.length == 2 ? null : eixo.pneus[0];

													return (
														<td key={pneu ? pneu.id : null} style={{ backgroundColor: item.cor }} >
															{pneu &&
															<input
																className='form-control'
																style={{ textAlign: 'center' }}
																readOnly={readOnly}
																value={pneu.numero}
																onChange={(data) => {
																	this.props.initForm({
																		...this.props.formularioValues,
																		controle_pneus: this.props.formularioValues.controle_pneus.map(itemAux => ({
																			...itemAux,
																			eixos: itemAux.eixos.map(eixoAux => ({
																				...eixoAux,
																				pneus: eixoAux.pneus.map(pneuAux => {
																					if (item.id == itemAux.id
																						&& eixo.id == eixoAux.id
																						&& pneu 
																						&& pneu.id == pneuAux.id) {
																						return {
																							...pneuAux,
																							numero: data.target.value 
																						}
																					}
																					return pneuAux;
																				})
																			}))
																		}))
																	});
																}} />}
														</td>
													);
												})
											))}
										</Tr>
										{/*Pneu interno da direita*/}
										<Tr>
											{(this.props.formularioValues.controle_pneus || []).map(item => (
												item.eixos.map(eixo => {
													const pneu = eixo.pneus.length == 2 ? eixo.pneus[0] : eixo.pneus[1];

													return (
														<td key={pneu ? pneu.id : null} style={{ backgroundColor: item.cor }}>
															{pneu &&
															<input
																className='form-control'
																style={{ textAlign: 'center' }}
																readOnly={readOnly}
																value={pneu.numero}
																onChange={(data) => {
																	this.props.initForm({
																		...this.props.formularioValues,
																		controle_pneus: this.props.formularioValues.controle_pneus.map(itemAux => ({
																			...itemAux,
																			eixos: itemAux.eixos.map(eixoAux => ({
																				...eixoAux,
																				pneus: eixoAux.pneus.map(pneuAux => {
																					if (item.id == itemAux.id
																						&& eixo.id == eixoAux.id
																						&& pneu 
																						&& pneu.id == pneuAux.id) {
																						return {
																							...pneuAux,
																							numero: data.target.value 
																						}
																					}
																					return pneuAux;
																				})
																			}))
																		}))
																	});
																}} />}
														</td>
													);
												})
											))}
										</Tr>
										{/*Placas*/}
										<Tr>
											{(this.props.formularioValues.controle_pneus || []).map(item => (
												<td key={item.id} colSpan={item.eixos.length} style={{ textAlign: 'center', backgroundColor: item.cor, fontWeight: 'bold' }}>{item.placa}</td>
											))}
										</Tr>
										{/*Pneu interno da esquerda*/}
										<Tr>
											{(this.props.formularioValues.controle_pneus || []).map(item => (
												item.eixos.map(eixo => {
													const pneu = eixo.pneus.length == 2 ? eixo.pneus[1] : eixo.pneus[2];

													return (
														<td key={pneu ? pneu.id : null} style={{ backgroundColor: item.cor }}>
															{pneu &&
															<input
																className='form-control'
																style={{ textAlign: 'center' }}
																readOnly={readOnly}
																value={pneu.numero}
																onChange={(data) => {
																	this.props.initForm({
																		...this.props.formularioValues,
																		controle_pneus: this.props.formularioValues.controle_pneus.map(itemAux => ({
																			...itemAux,
																			eixos: itemAux.eixos.map(eixoAux => ({
																				...eixoAux,
																				pneus: eixoAux.pneus.map(pneuAux => {
																					if (item.id == itemAux.id
																						&& eixo.id == eixoAux.id
																						&& pneu 
																						&& pneu.id == pneuAux.id) {
																						return {
																							...pneuAux,
																							numero: data.target.value 
																						}
																					}
																					return pneuAux;
																				})
																			}))
																		}))
																	});
																}} />}
														</td>
													);
												})
											))}
										</Tr>
										{/*Pneu externo da esquerda*/}
										<Tr>
											{(this.props.formularioValues.controle_pneus || []).map(item => (
												item.eixos.map(eixo => {
													const pneu = eixo.pneus.length == 2 ? null : eixo.pneus[3];

													return (
														<td key={pneu ? pneu.id : null} style={{ backgroundColor: item.cor }}>
															{pneu &&
															<input
																className='form-control'
																style={{ textAlign: 'center' }}
																readOnly={readOnly}
																value={pneu.numero}
																onChange={(data) => {
																	this.props.initForm({
																		...this.props.formularioValues,
																		controle_pneus: this.props.formularioValues.controle_pneus.map(itemAux => ({
																			...itemAux,
																			eixos: itemAux.eixos.map(eixoAux => ({
																				...eixoAux,
																				pneus: eixoAux.pneus.map(pneuAux => {
																					if (item.id == itemAux.id
																						&& eixo.id == eixoAux.id
																						&& pneu 
																						&& pneu.id == pneuAux.id) {
																						return {
																							...pneuAux,
																							numero: data.target.value 
																						}
																					}
																					return pneuAux;
																				})
																			}))
																		}))
																	});
																}} />}
														</td>
													);
												})
											))}
										</Tr>

									</TBody>
								</Table>
							</Grid>										

							<Grid cols='12 6 4 3'>
								<Table>
									<THead>
										{/*Ação*/}
										<Tr>
											<th style={{ textAlign: 'center' }}>ESTEPE</th>
											<th style={{ textAlign: 'center' }}>ESTOURADO</th>
										</Tr>
									</THead>
									<TBody>
										{(this.props.formularioValues.estepes || []).map((linha, i) => {
											
											const estepe = (this.props.formularioValues.estepes || []).filter(estepe => estepe.id == linha.id)[0];
											const estourado = (this.props.formularioValues.estourados || []).filter(estourado => estourado.id == linha.id)[0];

											return (
												<Tr key={i}>
													<td key={estepe ? estepe.id : null}>
														{estepe &&
														<input
															className='form-control'
															style={{ textAlign: 'center' }}
															readOnly={readOnly}
															value={estepe.numero}
															onChange={(data) => {
																this.props.initForm({
																	...this.props.formularioValues,
																	estepes: this.props.formularioValues.estepes.map(itemAux => {
																		
																		if (estepe.id == itemAux.id) {
																			return {
																				...itemAux,
																				numero: data.target.value 
																			}
																		}
																		return itemAux;
																	})
																});
															}} />}
													</td>
													<td key={estourado ? estourado.id : null}>
														{estourado &&
														<input
															className='form-control'
															style={{ textAlign: 'center' }}
															readOnly={readOnly}
															value={estourado.numero}
															onChange={(data) => {
																this.props.initForm({
																	...this.props.formularioValues,
																	estourados: this.props.formularioValues.estourados.map(itemAux => {
																		
																		if (estourado.id == itemAux.id) {
																			return {
																				...itemAux,
																				numero: data.target.value 
																			}
																		}
																		return itemAux;
																	})
																});
															}} />}
													</td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>}

						<Row>
							<Field
								name='observacao_gerente'
								component={LabelAndTextarea}
								label='Observações do gerente de manutenção para o responsável pela manutenção' placeholder='Informe a observacao'
								cols='12 12 8 6'
								readOnly={readOnly} />
								
							<Field
								name='sobre_condutor'
								component={LabelAndTextarea}
								label='Observações do gerente de manutenção sobre e ao condutor' placeholder='Informe a observacao'
								cols='12 12 8 6'
								readOnly={readOnly} />
						</Row>

						<Row>
								<Grid cols='6 6 4 3'>
									<Button
										text={'Salvar'}
										type={'success'}
										icon={'fa fa-chevron-left'}
										event={() => {
											this.props.alterarVeiculo(this.props.formularioValues);
										}} />
								</Grid>
							
							<Grid cols='6 6 4 3'>
								<Button
									text={'Imprimir'}
									type={'primary'}
									icon={'fa fa-chevron-left'}
									disabled={!this.props.formularioValues.datahora_inicio_manutencao || !this.props.formularioValues.id_usuario_responsavel}
									event={() => {
										this.props.gerarPdfNovo({
											...this.props.formularioValues,
											data_ordem_servico: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.data_ordem_servico),
											datahora_abertura: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_abertura),
											datahora_inicio: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_inicio),
											datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_finalizacao),
											datahora_fim_viagem: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_fim_viagem),
											datahora_checklist: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_checklist)
										});
									}} />
							</Grid>
							
						</Row>

						<label style={{ marginTop: 28 }}>Ocorrências</label>
						<Row>
							<Select
								name='id_pergunta'
								label='Pergunta' placeholder='Informe a pergunta'
								cols='12 12 3 4'
								readOnly={readOnly}
								options={this.props.listaChecklistPerguntas}
								value={this.state.id_pergunta}
								onChange={data => {
									this.setState({
										...this.state,
										adicionar_id_pergunta: data
									})
								}} />

							<LabelAndInput
								name='descricao'
								label='Descrição' placeholder='Informe a descrição'
								cols='12 12 5 6'
								readOnly={readOnly}
								value={this.state.adicionar_observacao}
								onChange={data => {
									this.setState({
										...this.state,
										adicionar_observacao: data.target.value
									})
								}} />

							<Grid cols='12 12 4 2' style={{ paddingTop: 28 }}>
								<Button
									text='Adicionar'
									type={'success'}
									icon={'fa fa-plus'}
									disabled={readOnly ? true : false}
									event={() => {
										let pergunta = this.props.listaChecklistPerguntas.filter(item => item.id == this.state.adicionar_id_pergunta)[0];
										this.props.adicionarOcorrencia({
											id_ordem_servico: this.props.formularioValues.id,
											id_motorista: this.props.formularioValues.id_motorista,
											id_veiculo: this.props.formularioValues.id_veiculo,
											datahora_ocorrencia: DateFormat.getDataHoraAtual(),
											id_carreta: null,
											descricao: (pergunta ? pergunta.valor : '') + (pergunta && this.state.adicionar_observacao ? ' - ' : '') + this.state.adicionar_observacao,
											imagem: null,
											descricao_gerente: null,
											resolvido: false
										}, this.props.formularioValues);
									}} />
							</Grid>
						</Row>
						<Table responsive>
								<tr>
									<Th width={100}>Data Hora</Th>
									<Th width={100}>Motorista</Th>
									<Th width={100}>Placa</Th>
									<Th width={400}>Descrição</Th>
									<Th width={80} alignCenter>Realizado</Th>
									<Th width={80} alignCenter>Tipo de Manutenção</Th>
									<Th width={80} alignCenter>Observação</Th>
									<Th width={200} alignCenter>Detalhes</Th>
									<Th width={25} alignCenter></Th>
								</tr>
							<TBody>
								{this.props.formularioValues.ocorrencias.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td width={100}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia)}</Td>
										<Td width={150}>{item.nome_motorista}</Td>
										<Td width={100}>{this.props.listaVeiculo.filter(veiculo => veiculo.id == this.props.formularioValues.id_veiculo).map(veiculo => veiculo.valor).join('')}</Td>
										<Td width={400}>{item.descricao}{item.descricao_execucao ? ' - ' : ''}{item.descricao_execucao}</Td>
										<Td>
											<select
												className='form-control'
												value={item.realizado ? 1 : (item.realizado == false ? 2 : 3)}
												onChange={data => {
													this.props.salvarExecucao({
														...item,
														tipo: 'OCORRENCIA',
														realizado: data.target.value == 1 ? true : (data.target.value == 2 ? false : null)
													});
												}} 
												style={{ fontSize: 14 }}>
												{(!!item.id_tipo && item.id_tipo != 1) &&
												<option value='1'>Sim</option>}
												{(item.id_observacao && (item.id_observacao != 5 || item.observacao_execucao)) && 
												<option value='2'>Não</option>}
												<option value='3'>Aguardando</option>
											</select>
										</Td>
										<Td>
											<select
												className='form-control'
												value={item.id_tipo || 0}
												onChange={data => {
													this.props.salvarExecucao({
														...item,
														tipo: 'OCORRENCIA',
														id_tipo: data.target.value == '0' ? null : data.target.value
													});
												}}
												style={{ fontSize: 14 }} >
												<option value={0}></option>
												{this.props.listaTipo.filter(tipo => item.realizado ? tipo.id != 1 : true).map(tipo => (
													<option value={tipo.id}>{tipo.valor}</option>
												))}
											</select>
										</Td>
										<Td>
											<select
												className='form-control'
												value={item.id_observacao || 0}
												onChange={data => {
													this.props.salvarExecucao({
														...item,
														tipo: 'OCORRENCIA',
														id_observacao: data.target.value == '0' ? null : data.target.value
													});
												}}
												style={{ fontSize: 14 }} >
												<option value={0}></option>
												{this.props.listaObservacao.map(observacao => (
													<option value={observacao.id}>{observacao.valor}</option>
												))}
											</select>
										</Td>
										<Td width={500}>{item.observacao_execucao}</Td>
										<Td>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												event={() => {
													this.props.setModoTela('execucao', {
														...this.props.registro
													});
													this.props.initFormExecucao({
														...item,
														tipo: 'OCORRENCIA'
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>

						<label>Checklists</label>
						<Table responsive>
								<tr>
									<Th width={100}>Data Hora</Th>
									<Th width={100}>Motorista</Th>
									<Th width={100}>Placa</Th>
									<Th width={400}>Descrição</Th>
									<Th width={80} alignCenter>Realizado</Th>
									<Th width={80} alignCenter>Tipo de Manutenção</Th>
									<Th width={80} alignCenter>Observação</Th>
									<Th width={200} alignCenter>Detalhes</Th>
									<Th width={25} alignCenter></Th>
								</tr>
							<TBody>
								{this.props.formularioValues.ocorrencia_checklists.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td width={100}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_checklist)}</Td>
										<Td width={150}>{item.nome_motorista}</Td>
										<Td width={100}>{this.props.listaVeiculo.filter(veiculo => veiculo.id == this.props.formularioValues.id_veiculo).map(veiculo => veiculo.valor).join('')}</Td>
										<Td width={400}>{item.pergunta} {item.observacao || 'VERIFICAR'}{item.descricao_execucao ? ' - ' : ''}{item.descricao_execucao}</Td>
										<Td>
											<select
												className='form-control'
												value={item.realizado ? 1 : (item.realizado == false ? 2 : 3)}
												onChange={data => {
													this.props.salvarExecucao({
														...item,
														tipo: 'OCORRENCIA_CHECKLIST',
														realizado: data.target.value == 1 ? true : (data.target.value == 2 ? false : null)
													});
												}}
												style={{ fontSize: 14 }} >
												{(!!item.id_tipo && item.id_tipo != 1) &&
												<option value='1'>Sim</option>}
												{(item.id_observacao && (item.id_observacao != 5 || item.observacao_execucao)) && 
												<option value='2'>Não</option>}
												<option value='3'>Aguardando</option>
											</select>
										</Td>
										<Td>
											<select
												className='form-control'
												value={item.id_tipo || 0}
												onChange={data => {
													this.props.salvarExecucao({
														...item,
														tipo: 'OCORRENCIA_CHECKLIST',
														id_tipo: data.target.value == '0' ? null : data.target.value
													});
												}}
												style={{ fontSize: 14 }} >
												<option value={0}></option>
												{this.props.listaTipo.filter(tipo => item.realizado ? tipo.id != 1 : true).map(tipo => (
													<option value={tipo.id}>{tipo.valor}</option>
												))}
											</select>
										</Td>
										<Td>
											<select
												className='form-control'
												value={item.id_observacao || 0}
												onChange={data => {
													this.props.salvarExecucao({
														...item,
														tipo: 'OCORRENCIA_CHECKLIST',
														id_observacao: data.target.value == '0' ? null : data.target.value
													});
												}}
												style={{ fontSize: 14 }} >
												<option value={0}></option>
												{this.props.listaObservacao.map(observacao => (
													<option value={observacao.id}>{observacao.valor}</option>
												))}
											</select>
										</Td>
										<Td width={500}>{item.observacao_execucao}</Td>
										<Td>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												event={() => {
													this.props.setModoTela('execucao', {
														...this.props.registro
													});
													this.props.initFormExecucao({
														...item,
														tipo: 'OCORRENCIA_CHECKLIST'
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text={!this.props.formularioValues.datahora_inicio ? 'Finalizar' : (!this.props.formularioValues.datahora_finalizacao ? 'Finalizar' : 'Reabrir')}
										type={'success'}
										icon={'fa fa-chevron-left'}
										disabled={
											DateFormat.isNull(this.props.formularioValues.datahora_inicio)
											|| (DateFormat.isNull(this.props.formularioValues.datahora_finalizacao)
											&& (
												itensAbertos
												|| DateFormat.isNull(DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio_manutencao)) 
												|| DateFormat.isNull(DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim_manutencao))
											))}
										title={
											(
												this.props.formularioValues.datahora_inicio
												&& !this.props.formularioValues.datahora_finalizacao 
												&& itensAbertos ? 'Existem ocorrências não tratadas' : (
													this.props.formularioValues.datahora_inicio
													&& !this.props.formularioValues.datahora_finalizacao 
													&& (
														!this.props.formularioValues.datahora_inicio_manutencao 
														|| !this.props.formularioValues.datahora_fim_manutencao
													) ? 'Infome as datas da manutenção' : null
												)
											)}									

										event={() => {
											if (!this.props.formularioValues.datahora_inicio) {
												// this.props.iniciar({
												// 	...this.props.formularioValues,
												// 	datahora_inicio: DateFormat.getDataHoraAtual(),
												// 	id_usuario_realizacao: this.props.sessao.id
												// });
												// this.setState({
												// 	...this.state,
												// 	osIniciar: {
												// 		...this.props.formularioValues,
												// 		datahora_inicio: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
												// 		id_usuario_realizacao: this.props.sessao.id
												// 	}
												// });
											} else if (!this.props.formularioValues.datahora_finalizacao) {
												this.props.finalizar({
													...this.props.formularioValues,
													datahora_finalizacao: DateFormat.getDataHoraAtual()
												});
												// this.setState({
												// 	...this.state,
												// 	osFinalizar: {
												// 		...this.props.formularioValues,
												// 		datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
												// 	}
												// });
											} else {
												this.props.finalizar({
													...this.props.formularioValues,
													datahora_finalizacao: null
												});
											}
										}} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => {
										this.props.setModoTela('lista');
										this.props.getLista();
									}} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}

	modalData() {

        return (
			<div class='modal fade show' id='modal-md' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h6 class='modal-title'>{this.state.osIniciar ? 'Iníciar' : 'Finalizar'}</h6>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, osIniciar: null, osFinalizar: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>

							<LabelAndInputMask
								label='Data/Hora' placeholder='Informe a data e hora'
								cols='12 12 12 12'
								mask='99/99/9999 99:99'
								value={this.state.osIniciar ? this.state.osIniciar.datahora_inicio :  this.state.osFinalizar.datahora_finalizacao}
								onChange={data => {
									if (this.state.osIniciar) {
										this.setState({
											...this.state,
											osIniciar: {
												...this.state.documentoViagem,
												datahora_inicio: data.target.value
											}
										});
									} else {
										this.setState({
											...this.state,
											osFinalizar: {
												...this.state.documentoViagem,
												datahora_finalizacao: data.target.value
											}
										});
									}
								}} />
							
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {

									if (this.state.osIniciar) {
										this.props.iniciar({
											...this.props.formularioValues,
											datahora_inicio: DateFormat.formatarDataHoraTelaParaSql(this.state.osIniciar.datahora_inicio),
											id_usuario_realizacao: this.props.sessao.id
										});
									} else {
										this.props.finalizar({
											...this.props.formularioValues,
											datahora_finalizacao: DateFormat.formatarDataHoraTelaParaSql(this.state.osFinalizar.datahora_finalizacao)
										});
									}

									this.setState({ ...this.state, osIniciar: null, osFinalizar: null });
								}}>{this.state.osIniciar ? 'Iniciar' : 'Finalizar'}</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

OrdemServicoForm = reduxForm({ form: 'ordemServicoForm', destroyOnUnmount: false })(OrdemServicoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('ordemServicoForm')(state),
	registro: state.ordemServico.registro,
	listaSetor: state.ordemServico.listaSetor,
	listaMotorista: state.ordemServico.listaMotorista,
	listaVeiculo: state.ordemServico.listaVeiculo,
	listaChecklistPerguntas: state.ordemServico.listaChecklistPerguntas,
	listaTipo: state.ordemServico.listaTipo,
	listaObservacao: state.ordemServico.listaObservacao,
	listaUsuario: state.ordemServico.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, initFormExecucao, iniciar, finalizar, alterarVeiculo, adicionarOcorrencia, salvarExecucao, getLista, gerarPdfNovo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoForm);
