import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './valeActions';

class ValeForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='datahora_vale'
								label='Data Hora' placeholder='Informe a data '
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='6 6 6 6'
								readOnly={readOnly}
							/>

							<Field
								name='id_motorista'
								component={Select}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 12 12 12'
								readOnly={this.props.sessao && this.props.sessao.id_motorista ? 'readOnly' : readOnly}
								options={this.props.listaMotorista} />

							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor' placeholder='Informe o valor'
								cols='4 4 4 4'
								readOnly={readOnly}
								casas={2} />

							<Field
								name='id_forma'
					 			component={Select}
								label='Forma de Pagamento'
								cols='8 8 8 8'
								readOnly={readOnly}
								options={this.props.listaForma} />

							<Select
								name='pessoal'
								label='Tipo'
								cols='4 4 4 4'
								readOnly={readOnly}
								options={[{ id: 1, valor: 'Vale Pessoal' }, { id: 2, valor: 'Caminhão' }]}
								value={this.props.formularioValues.pessoal ? 1 : 2}
								onChange={(value => {
									this.props.initForm({
										...this.props.formularioValues,
										pessoal: value == 1
									});
								})} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe observações'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

ValeForm = reduxForm({ form: 'valeForm', destroyOnUnmount: false })(ValeForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('valeForm')(state),
	registro: state.vale.registro,
	listaMotorista: state.vale.listaMotorista,
	listaForma: state.vale.listaForma
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ValeForm);
