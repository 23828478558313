import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ORDEM_SERVICO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('ordemServicoForm', {
			...registro
		})
    ];
}

export function setFiltro(filtro) {
	return {
		type: 'ORDEM_SERVICO_FILTRO',
		payload: filtro,
	};
}

export function setAguardando(aguardando) {
    return {
        type: 'ORDEM_SERVICO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/ordemServico?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().ordemServico.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().ordemServico.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().ordemServico.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().ordemServico.filtro.data_final)}
		`).then(resp => {
			dispatch({
				type: 'ORDEM_SERVICO_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};
}

export function salvar(registro) {

    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/ordemServico`, {
				...registro,
				id_usuario_abertura: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setListaOcorrenciaSelecionada([]));
				dispatch(setListaOcorrenciaChecklistSelecionada([]));
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				if (e.response && e.response.data && e.response.data.includes('Deseja continuar?')) {
					if (window.confirm(e.response.data)) {
						dispatch(salvar({
							...registro,
							confirma_viagem: true
						}));
					}
				} else {
					setErroAPI(e);
				}
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/ordemServico`, {
				...registro,
				datahora_despesa: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_despesa),
				quantidade: FormatUtils.formatarValorBanco(registro.quantidade, 2),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
		dispatch(setAguardando(true));
        axios.delete(`${consts.API_URL}/ordemServico?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function alterarVeiculo(registro) {

    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/ordemServico/alterarVeiculo`, {
			...registro,
			datahora_inicio_manutencao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio_manutencao),
			datahora_fim_manutencao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim_manutencao)
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			if (registro.datahora_inicio_manutencao) {
				dispatch(iniciar({
					...registro,
					datahora_inicio: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio_manutencao),
					id_usuario_realizacao: getState().auth.usuario.id
				}));
			}

			dispatch(getLista());
			dispatch(setModoTela('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}


export function iniciar(registro) {

    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/ordemServico/iniciar`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function finalizar(registro) {

    return async (dispatch, getState) => {
		dispatch(setAguardando(true));

		await axios.post(`${consts.API_URL}/ordemServico/alterarVeiculo`, {
			...registro,
			datahora_inicio_manutencao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio_manutencao),
			datahora_fim_manutencao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim_manutencao)
		});

		await axios.post(`${consts.API_URL}/ordemServico/finalizar`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function gerarPdf(registro) {

    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/ordemServico/gerarPdf`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			window.open(`${consts.URL}/${resp.data}`, '_blank');
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function gerarPdfNovo(registro) {

    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/ordemServico/gerarPdfNovo`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			window.open(`${consts.URL}/${resp.data}`, '_blank');
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}


export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'ORDEM_SERVICO_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaSetor() {
    const request = axios.get(`${consts.API_URL}/setor/listarSelect`);
    return {
        type: 'ORDEM_SERVICO_SETOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaChecklist() {
    const request = axios.get(`${consts.API_URL}/checklist`);
    return {
        type: 'ORDEM_SERVICO_CHECKLIST_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'ORDEM_SERVICO_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect?geral=true`);
    return {
        type: 'ORDEM_SERVICO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function adicionarOcorrencia(registro, ordemServico) {
		return (dispatch, getState) => {
			axios.post(`${consts.API_URL}/ocorrencia`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				let ocorrencias = (ordemServico.ocorrencias || []).map(item => item);
				ocorrencias.push({
					...registro,
					id: resp.data.id,
					nome_motorista: getState().ordemServico.listaMotorista.filter(motorista => motorista.id == registro.id_motorista).map(motorista => motorista.valor).join('')
				});
				
				dispatch(initForm({
					...ordemServico,
					ocorrencias: ocorrencias
				}));
			}).catch(e => {
				setErroAPI(e);
			});
		};
	}

export function setListaOcorrenciaSelecionada(lista) {
    return {
        type: 'ORDEM_SERVICO_OCORRENCIA_SELECIONADA_LISTADO',
        payload: lista
    };
}

export function getListaOcorrencia() {
    const request = axios.get(`${consts.API_URL}/ocorrencia?sem_os=true`);
    return {
        type: 'ORDEM_SERVICO_OCORRENCIA_LISTADO',
        payload: request
    };
}

export function getListaOcorrenciaChecklist() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaChecklist?sem_os=true`);
    return {
        type: 'ORDEM_SERVICO_OCORRENCIA_CHECKLIST_LISTADO',
        payload: request
    };
}

export function setListaOcorrenciaChecklistSelecionada(lista) {
    return {
        type: 'ORDEM_SERVICO_OCORRENCIA_CHECKLIST_SELECIONADA_LISTADO',
        payload: lista
    };
}

export function getChecklistPergunta() {
	const request = axios.get(`${consts.API_URL}/checklist`);
    return {
        type: 'ORDEM_SERVICO_CHECKLIST_PERGUNTA_LISTADO',
        payload: request
    };
}

//EXECUÇÃO
export function initFormExecucao(registro = {}) {
    return [
        initialize('ordemServicoExecucaoForm', {
			...registro
		})
    ];
}

export function salvarExecucao(registro) {

	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		if (registro.tipo == 'OCORRENCIA') {
	        axios.post(`${consts.API_URL}/ocorrencia/alterarRealizacao`, {
				...registro
			}).then(resp => {
				
				setSuccess('Operação Realizada com sucesso.');

				let ordemServico = {
					...getState().ordemServico.registro,
					ocorrencias: getState().ordemServico.registro.ocorrencias.map(item => {
						if (item.id == registro.id) {
							return {
								...item,
								realizado: registro.id_tipo ? registro.realizado : null,
								id_tipo: registro.id_tipo || null,
								id_observacao: registro.id_observacao || null,
								descricao_execucao: registro.descricao_execucao,
								observacao_execucao: registro.observacao_execucao
							}
						}
						return item;
					})
				};

				// dispatch(getLista());
				dispatch(initForm(ordemServico));
				dispatch(setModoTela('cadastro', ordemServico));
				dispatch(setAguardando(false));
			}).catch(e => {								
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.post(`${consts.API_URL}/ocorrenciaChecklist/alterarRealizacao`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');

				let ordemServico = {
					...getState().ordemServico.registro,
					ocorrencia_checklists: getState().ordemServico.registro.ocorrencia_checklists.map(item => {
						if (item.id == registro.id) {
							return {
								...item,
								realizado: registro.id_tipo ? registro.realizado : null,
								id_tipo: registro.id_tipo || null,
								id_observacao: registro.id_observacao || null,
								descricao_execucao: registro.descricao_execucao,
								observacao_execucao: registro.observacao_execucao
							}
						}
						return item;
					})
				};

				// dispatch(getLista());
				dispatch(initForm(ordemServico));
				dispatch(setModoTela('cadastro', ordemServico));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}