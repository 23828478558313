import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './veiculoForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaVeiculoTipo } from './veiculoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class Veiculo extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaVeiculoTipo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_cadastro != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Placa</Th>
								<Th alignCenter>CM</Th>
								<Th alignCenter>Frota/Terceiro</Th>
								<Th alignCenter>Tipo</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista
								.filter(item => {
									if (item.carreta) {
										return false;
									}
									if (this.state.pesquisar
										&& !(`${item.placa} ${item.cm} ${item.nome_veiculo_tipo}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
										return false;
									}
									return true;
								}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.placa}</Td>
									<Td alignCenter>{item.cm}</Td>
									<Td alignCenter>{item.terceiro ? 'Terceiro' : 'Frota'}</Td>
									<Td alignCenter>{item.nome_veiculo_tipo}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_cadastro != 2}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_cadastro != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.veiculo,
	modoTela: state.veiculo.modoTela,
	lista: state.veiculo.lista,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({setModoTela, initForm, salvar, excluir, getLista, getListaVeiculoTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Veiculo);
