import React from 'react'
import imagemSoLogo from '../../assets/images/sologo.png';

export default props => {
	if (props.aguardando) {

		return (
			<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
				<div class='modal-dialog'>
					<div class='overlay'>
						<div style={{ marginLeft: '50%', marginRight: '50%' }}>
							<i class="fas fa-4x fa-spinner fa-spin"></i>
							<img src={imagemSoLogo} style={{ height: 30, width: 30, marginLeft: 13, marginTop: -73, borderRadius: '50%' }} />
						</div>
					</div>
				</div>
			</div>
		);

	}

	return null;
}
