import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './ocorrenciaActions';

class OcorrenciaForm extends Component {

	state = {

	}
	
	componentDidMount() {

		const fileSelectorImagem = document.createElement('input');
		fileSelectorImagem.setAttribute('type', 'file');
		fileSelectorImagem.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('png')) {
					
					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem: reader.result
						});

					  };
				}				

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorImagem = fileSelectorImagem;
	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='datahora_ocorrencia'
								label='Data Hora' placeholder='Informe a data '
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='6 6 6 6'
								readOnly={'readOnly'}	/>

							<Field
								name='id_motorista'
								component={Select}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 12 12 12'
								readOnly={'readOnly'}
								options={this.props.listaMotorista} />

							<Field
								name='id_veiculo'
								component={Select}
								label='Veículo' placeholder='Informe o veículo'
								cols='12 12 12 12'
								readOnly={'readOnly'}
								options={this.props.listaVeiculo.filter(item => !item.carreta)} />

							<Field
								name='id_carreta'
								component={Select}
								label='Placa' placeholder='Informe a placa'
								cols='12 12 12 12'
								readOnly={'readOnly'}
								options={this.props.listaVeiculo} />

							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={'readOnly'} />

							<Field
								name='descricao_gerente'
								component={LabelAndInput}
								label='Descrição do Gerente' placeholder='Descrição do gerente'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='resolvido'
								component={LabelAndCheckbox}
								label='Resolvido' placeholder='Marque se já foi resolvido'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>

						<Row>
							<Grid cols='6 6 6 6'>
								<label>Foto</label>
							</Grid>
						</Row>
						<Row>
							<Grid cols='6 6 6 6'>
								{this.props.formularioValues.imagem ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={this.props.formularioValues.imagem} style={{ height: 400 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '100%' }}
												onClick={() => {
													const linkSource = this.props.formularioValues.imagem;
													const downloadLink = document.createElement("a");
													const fileName = `ocorrencia_${this.props.formularioValues.id}.jpg`;
													downloadLink.href = linkSource;
													downloadLink.download = fileName;
													downloadLink.click();
												}}>
												<i class='fas fa-download'></i>
											</button>
										</div>
									</div>
								) : (
									<Grid cols='6 6 3 2'>
										<Button
											text='Carregar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => {
												this.fileSelectorImagem.click()
											}} />
									</Grid>
								)}
							</Grid>
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

OcorrenciaForm = reduxForm({ form: 'ocorrenciaForm', destroyOnUnmount: false })(OcorrenciaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('ocorrenciaForm')(state),
	registro: state.ocorrencia.registro,
	listaMotorista: state.ocorrencia.listaMotorista,
	listaVeiculo: state.ocorrencia.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OcorrenciaForm);
