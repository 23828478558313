import DateFormat from "../common/dateFormat/DateFormat";

const INITIAL_STATE = { 
    listaNegociacaoUtilizada: [],
    listaNegociacaoStatus: [],
    listaNegociacaoForma: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

        case 'DASHBOARD_FINANCEIRO_FILTRO':
			return { ...state, filtro: action.payload };

		case 'DASHBOARD_FINANCEIRO_NEGOCIACAO_UTILIZADA_LISTADA':
            return { 
                ...state, 
                listaNegociacaoUtilizada: action.payload.data 
            };

        case 'DASHBOARD_FINANCEIRO_NEGOCIACAO_STATUS_LISTADA':
            return { 
                ...state, 
                listaNegociacaoStatus: action.payload.data 
            };

        case 'DASHBOARD_FINANCEIRO_NEGOCIACAO_FORMA_LISTADA':
            return { 
                ...state, 
                listaNegociacaoForma: action.payload.data 
            };

        default:
            return state;
    }
}
