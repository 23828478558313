import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './ordemServicoForm';
import Select from '../common/form/select';

import { salvar, setModoTela, getListaOcorrencia, setListaOcorrenciaSelecionada, getListaOcorrenciaChecklist, setListaOcorrenciaChecklistSelecionada } from './ordemServicoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class OrdemServicoSelecionar extends Component {

	state = {
		linhaSelecionada: null,
		id_motorista: null,
		id_veiculo: null,
		id_setor: null,
		id_usuario_responsavel: null
	}

    componentWillMount() {
		this.props.getListaOcorrencia();
		this.props.getListaOcorrenciaChecklist();
	}

    render() {

		return (
			<>
				<ContentCard>
					<ContentCardHeader>
						<Row>

							<Select
								label='Motorista' placeholder='Informe o motorista'
								cols='6 6 3 3'
								options={this.props.listaMotorista}
								value={this.state.id_motorista}
								onChange={value => {
									this.setState({
										...this.state,
										id_motorista: value
									});
									this.props.setListaOcorrenciaSelecionada([]);
									this.props.setListaOcorrenciaChecklistSelecionada([]);
								}} />

							<Select
								label='Veículo' placeholder='Informe o veículo'
								cols='6 6 3 3'
								options={this.props.listaVeiculo}
								value={this.state.id_veiculo}
								onChange={value => {
									this.setState({
										...this.state,
										id_veiculo: value
									});
								}} />

							<Select
								label='Setor' placeholder='Informe o setor'
								cols='6 6 3 3'
								options={this.props.listaSetor}
								value={this.state.id_setor}
								onChange={value => {
									this.setState({
										...this.state,
										id_setor: value
									});
									this.props.setListaOcorrenciaSelecionada([]);
									this.props.setListaOcorrenciaChecklistSelecionada([]);
								}} />
							
							<Select
								label='Responsável' placeholder='Informe o responsável'
								cols='6 6 3 3'
								options={this.props.listaUsuario}
								value={this.state.id_usuario_responsavel}
								onChange={value => {
									this.setState({
										...this.state,
										id_usuario_responsavel: value
									});
								}} />

						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<label>Ocorrências</label>
						<Table responsive>
								<tr>
									<Th width={100}>Data Hora</Th>
									<Th width={200}>Motorista</Th>
									<Th width={100}>Placa</Th>
									<Th width={400}>Descrição</Th>
									<Th width={100}></Th>
								</tr>
							<TBody>
								{this.props.listaOcorrencia
									.filter(item => {
										if ((this.state.id_motorista && item.id_motorista != this.state.id_motorista)
											|| this.props.listaOcorrenciasSelecionadas.filter(ocorrencia => ocorrencia.id == item.id).length) {
											return false;
										}
										return true;
									}).map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td width={100}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia)}</Td>
										<Td width={200}>{item.nome_motorista}</Td>
										<Td width={100}>{item.placa_carreta}</Td>
										<Td width={400}>{item.descricao}</Td>
										<Td width={100}>
											<button type='button'
												class={`btn btn-success btn-sm ${!this.state.id_motorista || !this.state.id_veiculo ? 'disabled' : ''}`}
												onClick={() => {
													let lista = this.props.listaOcorrenciasSelecionadas.map(ocorrencia => ocorrencia);
													if (!lista.filter(ocorrencia => ocorrencia.id == item.id).length) {
														lista.push(item);
													} else {
														lista = lista.filter(ocorrencia => ocorrencia.id != item.id);
													}

													this.props.setListaOcorrenciaSelecionada(lista);
												}}><i class={`fas fa-plus`}></i>
											</button>
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>

						<label>Checklists</label>
						<Table responsive>
								<tr>
									<Th width={100}>Data Hora</Th>
									<Th width={100}>Tipo</Th>
									<Th width={200}>Motorista</Th>
									<Th width={100}>Placa</Th>
									<Th width={400}>Descrição</Th>
									<Th width={100}></Th>
								</tr>
							<TBody>
								{this.props.listaOcorrenciaChecklist
									.filter(item => {
										if ((this.state.id_motorista && item.id_motorista != this.state.id_motorista)
											|| this.props.listaOcorrenciaChecklistsSelecionadas.filter(ocorrencia => ocorrencia.id == item.id).length) {
											return false;
										}
										return true;
									}).map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td width={100}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_checklist)}</Td>
										<Td width={200}>{item.nome_checklist}</Td>
										<Td width={200}>{item.nome_motorista}</Td>
										<Td width={100}>{item.placa_veiculo}</Td>
										<Td width={400}>{item.pergunta} {item.observacao || 'VERIFICAR'}</Td>
										<Td width={100}>
											<button type='button'
												class={`btn btn-success btn-sm ${!this.state.id_motorista || !this.state.id_veiculo ? 'disabled' : ''}`}
												onClick={() => {
													let lista = this.props.listaOcorrenciaChecklistsSelecionadas.map(ocorrencia => ocorrencia);
													if (!lista.filter(ocorrencia => ocorrencia.id == item.id).length) {
														lista.push(item);
													} else {
														lista = lista.filter(ocorrencia => ocorrencia.id != item.id);
													}

													this.props.setListaOcorrenciaChecklistSelecionada(lista);
												}}><i class={`fas fa-plus`}></i>
											</button>
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>

					</ContentCardBody>
				</ContentCard>


				<ContentCard>
					<ContentCardHeader>
						<Row>
							<label>Selecionados</label>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<label>Ocorrências</label>
						<Table responsive>
								<tr>
									<Th width={100}>Data Hora</Th>
									<Th width={200}>Motorista</Th>
									<Th width={100}>Placa</Th>
									<Th width={400}>Descrição</Th>
									<Th width={100}></Th>
								</tr>
							<TBody>
								{this.props.listaOcorrenciasSelecionadas.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td width={100}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia)}</Td>
										<Td width={200}>{item.nome_motorista}</Td>
										<Td width={100}>{item.placa_carreta}</Td>
										<Td width={400}>{item.descricao}</Td>
										<Td width={100}>
											<button type='button'
												class={`btn btn-'danger'} btn-sm ${!this.state.id_motorista || !this.state.id_veiculo ? 'disabled' : ''}`}
												onClick={() => {
													let lista = this.props.listaOcorrenciasSelecionadas.map(ocorrencia => ocorrencia);
													if (!lista.filter(ocorrencia => ocorrencia.id == item.id).length) {
														lista.push(item);
													} else {
														lista = lista.filter(ocorrencia => ocorrencia.id != item.id);
													}

													this.props.setListaOcorrenciaSelecionada(lista);
												}}><i class={`fas fa-times`}></i>
											</button>
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>

						<label>Checklists</label>
						<Table responsive>
								<tr>
									<Th width={100}>Data Hora</Th>
									<Th width={200}>Motorista</Th>
									<Th width={100}>Placa</Th>
									<Th width={400}>Descrição</Th>
									<Th width={100}></Th>
								</tr>
							<TBody>
								{this.props.listaOcorrenciaChecklistsSelecionadas.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td width={100}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_checklist)}</Td>
										<Td width={200}>{item.nome_motorista}</Td>
										<Td width={100}>{item.placa_veiculo}</Td>
										<Td width={400}>{item.pergunta} {item.observacao || 'VERIFICAR'}</Td>
										<Td width={100}>
											<button type='button'
												class={`btn btn-'danger'} btn-sm ${!this.state.id_motorista || !this.state.id_veiculo ? 'disabled' : ''}`}
												onClick={() => {
													let lista = this.props.listaOcorrenciaChecklistsSelecionadas.map(ocorrencia => ocorrencia);
													if (!lista.filter(ocorrencia => ocorrencia.id == item.id).length) {
														lista.push(item);
													} else {
														lista = lista.filter(ocorrencia => ocorrencia.id != item.id);
													}

													this.props.setListaOcorrenciaChecklistSelecionada(lista);
												}}><i class={`fas fa-times`}></i>
											</button>
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								<Button
									text='Gerar OS'
									type={'success'}
									icon={'fa fa-chevron-left'}
									disabled={!this.state.id_setor || !this.state.id_motorista || !this.state.id_veiculo 
										|| !this.state.id_usuario_responsavel || !this.props.listaOcorrenciasSelecionadas}
									event={() => {
										this.props.salvar({
											datahora_abertura: DateFormat.getDataHoraAtual(),
											id_setor: this.state.id_setor,
											id_motorista: this.state.id_motorista,
											id_veiculo: this.state.id_veiculo,
											id_usuario_responsavel: this.state.id_usuario_responsavel,
											listaOcorrencias: this.props.listaOcorrenciasSelecionadas,
											listaOcorrenciaChecklists: this.props.listaOcorrenciaChecklistsSelecionadas
										});
									}} />
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => {
										this.props.setModoTela('lista');
										this.props.setListaOcorrenciaSelecionada([]);
									}} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</ContentCard>
			</>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.ordemServico,
	modoTela: state.ordemServico.modoTela,
	lista: state.ordemServico.lista,
	listaOcorrencia: state.ordemServico.listaOcorrencia,
	listaOcorrenciaChecklist: state.ordemServico.listaOcorrenciaChecklist,
	listaSetor: state.ordemServico.listaSetor,
	listaMotorista: state.ordemServico.listaMotorista,
	listaVeiculo: state.ordemServico.listaVeiculo,
	listaOcorrenciasSelecionadas: state.ordemServico.listaOcorrenciasSelecionadas,
	listaOcorrenciaChecklistsSelecionadas: state.ordemServico.listaOcorrenciaChecklistsSelecionadas,
	permissoes: state.usuario.permissoes,
	listaUsuario: state.ordemServico.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ salvar, setModoTela, getListaOcorrencia, setListaOcorrenciaSelecionada, getListaOcorrenciaChecklist, setListaOcorrenciaChecklistSelecionada }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoSelecionar);
