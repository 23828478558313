import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ChecklistPerguntas from './checklistPerguntas/checklistPerguntas';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';


import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './checklistActions';

class ChecklistForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (
	<>
		<ContentCard>
			<Form event={this.props.handleSubmit}>
				<ContentCardBody>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome' placeholder='Informe o nome'
							cols='12 12 12 12'
							readOnly={readOnly} />

						<Field
							name='id_checklist_tipo'
							component={Select}
							label='Tipo'
							cols='12 12 12 12'
							readOnly={readOnly}
							options={this.props.listaChecklistTipo} />

						<Select
							options={this.props.listaProduto}
							label='Produtos'
							cols='12 12 12 12'
							placeholder='Selecione o produto'
							readOnly={readOnly}
							multiple={true}
							value={this.props.listaProduto.map(item => ({ value: item.id, label: item.valor })).filter(item => (this.props.formularioValues.produtos || []).includes(item.value))}
							onChange={value => {
								this.props.initForm({
									...this.props.formularioValues,
									produtos: value.map(item => item.value)
								});
							}} />

						<Field
							name='dias'
							component={LabelAndInputNumber}
							label='Dias' placeholder='Informe os dias de intervalo'
							cols='4 4 4 4'
							readOnly={readOnly}
							casas={0} />
					</Row>
				</ContentCardBody>
				<ContentCardFooter>
					<Row alignCenter>
						<Grid cols='6 6 4 3'>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
							)}
						</Grid>
						<Grid cols='6 6 4 3'>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</Grid>
					</Row>
				</ContentCardFooter>
			</Form>
		</ContentCard>

			{this.props.perguntas ?(
				<>
					<ContentCard>
						<ChecklistPerguntas />
					</ContentCard>
				</>
			)
			: null}







	</>

		)
	}
}

ChecklistForm = reduxForm({ form: 'checklistForm', destroyOnUnmount: false })(ChecklistForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('checklistForm')(state),
	registro: state.checklist.registro,
	listaChecklistTipo: state.checklist.listaChecklistTipo,
	listaProduto: state.checklist.listaProduto
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistForm);
