import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaCidade: [],
	listaMotorista: [],
	listaVeiculo: [],
	listaCausador: [{ id: 1, valor: 'Empresa' }, { id: 2, valor: 'Motorista' }],
	lisaGrau: [{ id: 1, valor: 'Leve' }, { id: 2, valor: 'Média' }, { id: 3, valor: 'Grave' }, { id: 4, valor: 'Gravíssima' }],
	listaFormaPagamento: [
		{ id: 1, valor: 'Dinheiro' }, { id: 2, valor: 'Cartão de Crédito' }, { id: 3, valor: 'Cartão de Débito' }, 
		{ id: 4, valor: 'PIX' }, { id: 5, valor: 'Cheque' }, { id: 6, valor: 'Boleto' }, { id: 7, valor: 'Acerto de Viagem' }
	],
	listaReconhecida: [{ id: '1', valor: 'Sim' }, { id: '2',	valor: 'Não' }],
	listaParcelas: [
		{ id: 1, valor: 'À Vista' }, { id: 2, valor: '2'}, { id: 3, valor: '3'}, { id: 4, valor: '4'}, { id: 5, valor: '5'}, { id: 6, valor: '6'},
		{ id: 7, valor: '7'}, { id: 8, valor: '8'}, { id: 9, valor: '9'}, { id: 10, valor: '10'}, { id: 11, valor: '11'}, { id: 12, valor: '12'}
	],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'MULTA_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'MULTA_FILTRO':
			return { ...state, filtro: action.payload };

		case 'MULTA_LISTADO':
			return { ...state, lista: action.payload.data };


		case 'MULTA_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'MULTA_CIDADE_SELECT_LISTADO':
			return { ...state, listaCidade: action.payload.data };

		case 'MULTA_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

		case 'MULTA_VEICULO_SELECT_LISTADO':
			return { ...state, listaVeiculo: action.payload.data };

		default:
			return state;
	}
}
