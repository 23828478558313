import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import LabelAndInputDate from '../common/form/labelAndInputDate';
import LabelInputSearchLocation from '../common/form/labelInputSearchLocation';

import PostoNegociacao from './postoNegociacao/postoNegociacao';

import {
	setModoTela, initForm
} from './postoActions';

class PostoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir || this.props.negociacao ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						{!this.props.negociacao ? (
							<>
								<Row>
									<Field
										name='codigo'
										component={LabelAndInput}
										label='Código Atua' placeholder='Informe o código'
										cols='12 12 4 4'
										readOnly={readOnly} />

									<Field
										name='id_rede'
										component={Select}
										label='Rede' placeholder='Informe a rede'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaRede} />

									<Field
										name='nome'
										component={LabelAndInput}
										label='Nome' placeholder='Informe o nome'
										cols='12 12 12 12'
										readOnly={readOnly} />

									<Field
										name='cnpj'
										component={LabelAndInputMask}
										mask= '99.999.999/9999-99'
										label='CNPJ (opcional)' placeholder='Informe o CNPJ'
										cols='12 12 4 4'
										readOnly={readOnly} />

									{this.props.formularioValues.endereco ? (
										<Field
											name='endereco'
											component={LabelAndInput}
											label='Endereço' placeholder='Informe o endereço'
											cols='12 12 12 12'
											readOnly={readOnly} />
									) : (
										<LabelInputSearchLocation
											name='endereco'
											label='Endereço' placeholder='Informe o endereço'
											cols='12 12 12 12'
											readOnly={readOnly}
											onChange={retorno => {
												this.props.initForm({
													...this.props.formularioValues,
													endereco: `${retorno.endereco}${retorno.numero ? `, ${retorno.numero}` : ''}${retorno.bairro ? `, ${retorno.bairro}` : ''}${retorno.cep ? ` - ${retorno.cep}` : ''}`,
													id_cidade: this.props.listaCidade.filter(item => item.valor == retorno.cidade && item.uf == retorno.estado)[0] ? this.props.listaCidade.filter(item => item.valor == retorno.cidade && item.uf == retorno.estado)[0].id : null,
													cidade: this.props.listaCidade.filter(item => item.valor == retorno.cidade)[0] ? this.props.listaCidade.filter(item => item.valor == retorno.cidade)[0].valor : null,
													latitude: retorno.latitude,
													longitude: retorno.longitude
												});
											}} />
									)}

									{this.props.formularioValues.cidade ? (
										<Field
											name='cidade'
											component={LabelAndInput}
											label='Cidade' placeholder='Informe a cidade'
											cols='12 12 12 12'
											readOnly={readOnly} />
									) : (
										<Field
											name='id_cidade'
											component={Select}
											label='Cidade' placeholder='Informe a cidade'
											cols='12 12 12 12'
											readOnly={readOnly}
											options={this.props.listaCidade} />
									)}
								</Row>
								<Row>
									<Field
										name='latitude'
										component={LabelAndInput}
										label='Latitude' placeholder='Informe o latitude'
										cols='12 12 4 4'
										readOnly={readOnly} />

									<Field
										name='longitude'
										component={LabelAndInput}
										label='Longitude' placeholder='Informe o longitude'
										cols='12 12 4 4'
										readOnly={readOnly} />
								</Row>

								<Row>
									<Field
										name='contato'
										component={LabelAndInput}
										label='Contato' placeholder='Informe o contato'
										cols='12 12 4 4 '
										readOnly={readOnly} />

									<Field
										name='telefone'
										component={LabelAndInputMask}
										mask= '(99) 9 9999-9999'
										label='Telefone' placeholder='Informe o telefone'
										cols='12 12 4 4'
										readOnly={readOnly} />
										<Grid cols='4 3 2 2'>

									<Grid cols='12 12 4 4'>
										<span class='input-group-append' style={{ marginTop: 31 }}>
											{this.props.formularioValues.contato ? (
												<button class='btn btn-primary' type={'button'}
													onClick={() => {
														let telefone = String(this.props.formularioValues.telefone).replace('55', '').replace(/[^0-9]/g, '');
														if (telefone.length <= 8) {
															telefone = `55${telefone}`;
														}
														window.open(`https://api.whatsapp.com/send?phone=55${telefone}`);
													}}>WhatsApp</button>
											) : (
												<button class='btn btn-primary' disabled>WhatsApp</button>
											)}
										</span>
									</Grid>
								</Grid>

								<Field
									name='observacao'
									component={LabelAndInput}
									label='Observação' placeholder='Informe o observação'
									cols='12 12 12 12'
									readOnly={readOnly} />
									
								<Field
									name='cancelado'
									component={LabelAndCheckbox}
									label='Cancelado para Negociação' placeholder='Informe se está cancelado para negociação'
									cols='12 12 4 4'
									readOnly={readOnly} />
								
								<Field
									name='desativado'
									component={LabelAndCheckbox}
									label='Desativado' placeholder='Informe se está desativado'
									cols='12 12 12 12'
									readOnly={readOnly} />

								</Row>
							</>
						) : (
							<Row>
								<Field
									name='codigo'
									component={LabelAndInput}
									label='Código Atua' placeholder='Informe o código'
									cols='12 12 3 3'
									readOnly={readOnly} />

								<Field
									name='id_rede'
									component={Select}
									label='Rede' placeholder='Informe a rede'
									cols='12 12 4 4'
									readOnly={readOnly}
									options={this.props.listaRede} />

								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 4 4'
									readOnly={readOnly} />

								<Field
									name='cnpj'
									component={LabelAndInputMask}
									mask= '99.999.999/9999-99'
									label='CNPJ (opcional)' placeholder='Informe o CNPJ'
									cols='12 12 3 3'
									readOnly={readOnly} />

								<Field
									name='endereco'
									component={LabelAndInput}
									label='Endereço' placeholder='Informe o endereço'
									cols='12 12 3 3'
									readOnly={readOnly} />

								<Field
									name='contato'
									component={LabelAndInput}
									label='Contato' placeholder='Informe o contato'
									cols='12 12 3 3 '
									readOnly={readOnly} />

								<Field
									name='telefone'
									component={LabelAndInputMask}
									mask= '(99) 9 9999-9999'
									label='Telefone' placeholder='Informe o telefone'
									cols='12 12 3 3'
									readOnly={readOnly} />
									
								<Field
									name='cancelado'
									component={LabelAndCheckbox}
									label='Cancelado para Negociação' placeholder='Informe se está cancelado para negociação'
									cols='12 12 4 4'
									readOnly={readOnly} />
									
								<Field
									name='desativado'
									component={LabelAndCheckbox}
									label='Desativado' placeholder='Informe se está desativado'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>
						)}
						<Row>{console.log(this.props.formularioValues)}
							<Field
								name='id_forma_pagamento'
								component={Select}
								label='Forma Pagamento' placeholder='Informe a forma de pagamento'
								cols='12 6 4 3'
								readOnly={readOnly}
								options={this.props.listaFormaPagamento} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										disabled={this.props.negociacao}
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>


				{this.props.formularioValues.id ? (
					<PostoNegociacao />
				) : null}
			</ContentCard>
		)
	}


}

PostoForm = reduxForm({ form: 'postoForm', destroyOnUnmount: false })(PostoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('postoForm')(state),
	registro: state.posto.registro,
	listaCidade: state.posto.listaCidade,
	listaRede: state.posto.listaRede,
	listaFormaPagamento: state.posto.listaFormaPagamento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PostoForm);
