import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    lista: [],
    listaMotorista: [],
    listaVeiculo: [],
	listaVeiculoTipo: [],
	listaChecklistResposta: [],
	aguardando: false,
    checklistResposta: null,
    listaDespesaViagem: [],
    listaAbastecida: [],
    listaMulta: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'VIAGEM_LANCAMENTO_MODO_TELA':
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro
            };

        case 'VIAGEM_LANCAMENTO_FILTRO':
			return { ...state, filtro: action.payload };

        case 'VIAGEM_LANCAMENTO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'VIAGEM_LANCAMENTO_DESPESA_VIAGEM_LISTADO':
            return { ...state, listaDespesaViagem: action.payload.data };
            
        case 'VIAGEM_LANCAMENTO_ABASTECIDA_LISTADO':
            return { ...state, listaAbastecida: action.payload.data };

        case 'VIAGEM_LANCAMENTO_MULTA_LISTADO':
            return { ...state, listaMulta: action.payload.data };

		case 'VIAGEM_LANCAMENTO_ATUALIZACAO_LISTADO':
            return {
				...state,
				lista: action.payload.lista,
				registro: state.registro ? {
					...state.registro,
					fretes: action.payload.registro ? action.payload.registro.fretes : state.registro.fretes
				} : null
			};

        case 'VIAGEM_LANCAMENTO_MOTORISTA_LISTADO':
            return { ...state, listaMotorista: action.payload.data };

        case 'VIAGEM_LANCAMENTO_VEICULO_LISTADO':
            return { ...state, listaVeiculo: action.payload.data };

        case 'VIAGEM_LANCAMENTO_VEICULO_TIPO_LISTADO':
            return { ...state, listaVeiculoTipo: action.payload.data };

		case 'VIAGEM_LANCAMENTO_CHECKLIST_RESPOSTA_LISTADO':
            return { ...state, listaChecklistResposta: action.payload.data };

        case 'VIAGEM_LANCAMENTO_SET_AGUARDANDO':
            return {
                ...state,
                aguardando: action.payload
            };

		case 'VIAGEM_LANCAMENTO_SET_CHECKLIST':
            return {
                ...state,
                checklistResposta: action.payload
            };

        default:
            return state;
    }
}
