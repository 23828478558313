import DateFormat from "../common/dateFormat/DateFormat";

const INITIAL_STATE = { 
    listaEficiencia: [],
    listaPorTipo: [],
    listaOcorrenciaResolvida: [],
    listaOcorrenciaNaoResolvida: [],
    listaDias: [],
    listaAnalise: [],
    listaNaoConformidadeExercicio: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

        case 'DASHBOARD_FINANCEIRO_FILTRO':
			return { ...state, filtro: action.payload };

		case 'DASHBOARD_MANUTENCAO_EFICIENCIA_LISTADA':
            return { 
                ...state, 
                listaEficiencia: action.payload.data 
            };

        case 'DASHBOARD_MANUTENCAO_POR_TIPO_LISTADA':
            return { 
                ...state, 
                listaPorTipo: action.payload.data 
            };

        case 'DASHBOARD_MANUTENCAO_OCORRENCIA_RESOLVIDA_LISTADA':
            return { 
                ...state, 
                listaOcorrenciaResolvida: action.payload.data 
            };

        case 'DASHBOARD_MANUTENCAO_OCORRENCIA_NAO_RESOLVIDA_LISTADA':
            return { 
                ...state, 
                listaOcorrenciaNaoResolvida: action.payload.data 
            };

        case 'DASHBOARD_MANUTENCAO_DIAS_LISTADA':
            return { 
                ...state, 
                listaDias: action.payload.data 
            };

        case 'DASHBOARD_MANUTENCAO_ANALISE_LISTADA':
            return { 
                ...state, 
                listaAnalise: action.payload.data 
            };

        case 'DASHBOARD_MANUTENCAO_NAO_CONFORMIDADE_EXERCICIO_LISTADA':
            return { 
                ...state, 
                listaNaoConformidadeExercicio: action.payload.data 
            };

        default:
            return state;
    }
}
