import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './devolucaoActions';

class DevolucaoForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorImagem = document.createElement('input');
		fileSelectorImagem.setAttribute('type', 'file');
		fileSelectorImagem.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('png')) {
					
					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem: reader.result
						});

					  };
				}				

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorImagem = fileSelectorImagem;

		const fileSelectorValor = document.createElement('input');
		fileSelectorValor.setAttribute('type', 'file');
		fileSelectorValor.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('png')) {
					
					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_valor: reader.result
						});

					  };
				}				

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorValor = fileSelectorValor;

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='datahora_devolucao'
								label='Data Hora' placeholder='Informe a data '
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='6 6 6 6'
								readOnly={readOnly}
							/>

							<Field
								name='id_motorista'
								component={Select}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 12 12 12'
								readOnly={this.props.sessao && this.props.sessao.id_motorista ? 'readOnly' : readOnly}
								options={this.props.listaMotorista} />

							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor' placeholder='Informe o valor'
								cols='4 4 4 4'
								readOnly={readOnly}
								casas={2} />

							<Field
								name='id_forma'
					 			component={Select}
								label='Forma de Pagamento'
								cols='8 8 8 8'
								readOnly={readOnly}
								options={this.props.listaForma} />

							<Field
								name='id_cidade'
					 			component={Select}
								label='Cidade'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaCidade} />

							<Field
								name='id_conta_destino'
					 			component={Select}
								label='Conta de Destino'
								cols='8 8 8 8'
								readOnly={readOnly}
								options={this.props.listaContaBancaria} />

							<Field
								name='local_deposito'
								component={LabelAndInput}
								label='Local do Depósito' placeholder='Informe o local'
								cols='4 4 4 4'
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe observações'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Grid cols='6 6 6 6'>
								<label>Foto</label>
							</Grid>
							<Grid cols='6 6 6 6'>
								<label>Foto Dinheiro/Cheque</label>
							</Grid>
						</Row>
						<Row>
							<Grid cols='6 6 6 6'>
								{this.props.formularioValues.imagem ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={this.props.formularioValues.imagem} style={{ height: 400 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '100%' }}
												onClick={() => {
													const linkSource = this.props.formularioValues.imagem;
													const downloadLink = document.createElement("a");
													const fileName = `nf.jpg`;
													downloadLink.href = linkSource;
													downloadLink.download = fileName;
													downloadLink.click();
												}}>
												<i class='fas fa-download'></i>
											</button>
										</div>
									</div>
								) : (
									<Grid cols='6 6 3 2'>
										<Button
											text='Carregar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => {
												this.fileSelectorImagem.click()
											}} />
									</Grid>
								)}
							</Grid>
						
							<Grid cols='6 6 6 6'>
								{this.props.formularioValues.imagem_valor ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={this.props.formularioValues.imagem_valor} style={{ height: 400 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '100%' }}
												onClick={() => {
													const linkSource = this.props.formularioValues.imagem_valor;
													const downloadLink = document.createElement("a");
													const fileName = `nf.jpg`;
													downloadLink.href = linkSource;
													downloadLink.download = fileName;
													downloadLink.click();
												}}>
												<i class='fas fa-download'></i>
											</button>
										</div>
									</div>
								) : (
									<Grid cols='6 6 3 2'>
										<Button
											text='Carregar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => {
												this.fileSelectorValor.click()
											}} />
									</Grid>
								)}
							</Grid>
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

DevolucaoForm = reduxForm({ form: 'devolucaoForm', destroyOnUnmount: false })(DevolucaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('devolucaoForm')(state),
	registro: state.devolucao.registro,
	listaMotorista: state.devolucao.listaMotorista,
	listaForma: state.devolucao.listaForma,
	listaCidade: state.devolucao.listaCidade,
	listaContaBancaria: state.devolucao.listaContaBancaria
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DevolucaoForm);
