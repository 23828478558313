import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'POSTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('postoForm', {
			...registro


		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'POSTO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/posto?todos=true`);
    return {
        type: 'POSTO_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'POSTO_CIDADE_LISTADO',
        payload: request
    };
}

export function getListaRede() {
    const request = axios.get(`${consts.API_URL}/rede/listarSelect`);
    return {
        type: 'POSTO_REDE_LISTADO',
        payload: request
    };
}


export function salvar(registro) {

    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/posto`, {
				...registro,
                cnpj: FormatUtils.removerMascara(registro.cnpj),
				telefone: FormatUtils.removerMascara(registro.telefone)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/posto`, {
				...registro,
				cnpj: FormatUtils.removerMascara(registro.cnpj),
				telefone: FormatUtils.removerMascara(registro.telefone)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/posto?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaFormaPagamento() {
    const request = axios.get(`${consts.API_URL}/formaPagamento/listarSelect`);
    return {
        type: 'POSTO_FORMA_PAGAMENTO_SELECT_LISTADO',
        payload: request
    };
}
