const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaLocal: [],
	listaEstado: [],
	listaCidade: [],
	listaManutencaoStatus: [],
	listaVeiculo: [],
	listaMotorista: [],
	listaTransportadora: [],
	listaProduto: [],
	listaUnidadeMedida: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'MANUTENCAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'MANUTENCAO_LISTADO':
            return { ...state, lista: action.payload.data };

		case 'MANUTENCAO_LOCAL_SELECT_LISTADO':
            return { ...state, listaLocal: action.payload.data };

		case 'MANUTENCAO_ESTADO_SELECT_LISTADO':
            return { ...state, listaEstado: action.payload.data };

		case 'MANUTENCAO_CIDADE_SELECT_LISTADO':
            return { ...state, listaCidade: action.payload.data };

		case 'MANUTENCAO_MANUTENCAO_STATUS_SELECT_LISTADO':
            return { ...state, listaManutencaoStatus: action.payload.data };

		case 'MANUTENCAO_VEICULO_SELECT_LISTADO':
            return { ...state, listaVeiculo: action.payload.data };

		case 'MANUTENCAO_MOTORISTA_SELECT_LISTADO':
            return { ...state, listaMotorista: action.payload.data };

		case 'MANUTENCAO_TRANSPORTADORA_SELECT_LISTADO':
            return { ...state, listaTransportadora: action.payload.data };

		case 'MANUTENCAO_PRODUTO_SELECT_LISTADO':
            return { ...state, listaProduto: action.payload.data };

		case 'MANUTENCAO_UNIDADE_MEDIDA_SELECT_LISTADO':
            return { ...state, listaUnidadeMedida: action.payload.data };

        default:
            return state;
    }
}
