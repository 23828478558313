const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaMotorista: [],
	listaForma: [{
		id: 1,
		valor: 'Depósito'
	}, {
		id: 2,
		valor: 'Dinheiro'
	}, {
		id: 3,
		valor: 'Cheque'
	}, {
		id: 4,
		valor: 'Carta Frete'
	}, {
		id: 5,
		valor: 'Adiantamento'
	}]
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'VALE_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'VALE_LISTADO':
			return { ...state, lista: action.payload.data };


		case 'VALE_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'VALE_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

		default:
			return state;
	}
}
