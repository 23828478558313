import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../auth/authActions';
import { getLista as getListaNotificacao } from '../../notificacao/notificacaoActions';
import Row from '../layout/row';
import Grid from '../layout/grid';
import DateFormat from '../dateFormat/DateFormat';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			open: false, 
			relogio: `${DateFormat.getDiaSemana(new Date())}, ${DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())}`,
			vibrar: true
		};

		setInterval(() => {

			this.setState({
				...this.state,
				relogio: `${DateFormat.getDiaSemana(new Date())}, ${DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())}`,
				vibrar: false
			})
		}, 5000);
	}

	componentWillMount() {
		this.props.getListaNotificacao();
		setInterval(() => {
			this.props.getListaNotificacao();
			this.setState({ 
				...this.state,
				vibrar: !this.state.vibrar
			});
		}, 30000);
	}

	changeOpen() {
		this.setState({ open: !this.state.open });
	}

	render() {
		const { nome, login } = this.props.usuario;

		let listaNotificacao = (this.props.lista_notificacao || [])
			.filter(item => (this.props.notificacao_perfil || []).filter(perfil => perfil == item.id_perfil).length)
			.filter(item => !item.id_usuario || item.id_usuario == this.props.usuario.id);

		return (
			<ul className='navbar-nav ml-auto'>

				<li className='nav-item dropdown'>
					{/*<a className='nav-link' data-toggle='dropdown' href='#'>
						<i className='far fa-bell'></i>
						<span className='badge badge-warning navbar-badge'>15</span>
					</a>*/}
					<div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
						<span className='dropdown-item dropdown-header'>15 Notifications</span>
						<div className='dropdown-divider'></div>
						<a href='#' className='dropdown-item'>
							<i className='fas fa-envelope mr-2'></i> 4 new messages
							<span className='float-right text-muted text-sm'>3 mins</span>
						</a>
					<div className='dropdown-divider'></div>
					<a href='#' className='dropdown-item'>
						<i className='fas fa-users mr-2'></i> 8 friend requests
						<span className='float-right text-muted text-sm'>12 hours</span>
					</a>
					<div className='dropdown-divider'></div>
					<a href='#' className='dropdown-item'>
						<i className='fas fa-file mr-2'></i> 3 new reports
						<span className='float-right text-muted text-sm'>2 days</span>
					</a>
					<div className='dropdown-divider'></div>
						<a href='#' className='dropdown-item dropdown-footer'>See All Notifications</a>
					</div>
				</li>
				<Row alignRight style={{ marginRight: 2, marginTop: 6 }}>
					<Grid style={{ marginRight: 3 }}>
						<i class='far fa-clock'> </i>
					</Grid>
					<label>
						{this.state.relogio}
					</label>
				</Row>

				<li className='nav-item dropdown' style={{ color: '#BA0000' }}>
					<a className='nav-link' data-toggle='dropdown' href='#'>
						<i className='fas fa-bell' 
							style={{ 
								color: '#BA0000',
								fontSize: 25,
								paddingTop: -5,
								animation: this.state.vibrar ? 'shake 1s' : null,
								animationIterationCount: this.state.vibrar ? 'infinite' : null
							}}></i>
						<span className='badge badge-warning navbar-badge' style={{ fontSize: 12, fontWeight: 'bold' }}>
							{listaNotificacao.length || ''}
						</span>
					</a>
					<div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'
						style={{ backgroundColor: '#BA0000' }} >
						<div id='toastsContainerTopRight' 
							className='toasts-top-right fixed'
							style={{ width: 330, marginTop: 33, marginRight: 10 }} >								
							<div className='toast fade show'
								role='alert'
								aria-live='assertive'
								aria-atomic='true' >										
								<div className='toast-header'>
									<strong className='mr-auto' style={{ paddingLeft: 110 }}>
										Notificações
									</strong>
									<button data-dismiss='toast'
										type='button'
										class='ml-2 mb-1 close'
										aria-label='Close' >
										<span aria-hidden='true'
											onClick={() =>
												this.setState({ ...this.state, notificacaoOpen: false })
											} >
											×
										</span>
									</button>
								</div>
								<div className='toast-body'>
									<div className={``}	
										style={{
											maxHeight: (window.innerHeight || 0) - 200,
											overflow: 'auto'
										}} >
										<div className='dropdown-divider'></div>
										{listaNotificacao.map((item) => {
											return (
												<div key={item.id}>
													<div className=''
														style={{ cursor: 'pointer', paddingTop: 10, paddingBottom: 10 }}
														onClick={() => {
															window.location.href = `#${item.link}`;
															// this.props.setNotificacaoVista(item);
															// this.props.setFiltroFatura({
																// 	...this.props.filtroFatura,
																// 	id_cliente: item.id_cliente,
																// 	id_unidade_consumidora:
																// 	item.id_unidade_consumidora,
															// });
															// this.props.getListaFatura();
														}} >
														<div 
															style={{
																fontSize: 14,
																fontWeight: 'bold'
															}} >
															{item.descricao}
														</div>
														<div
															style={{
																fontSize: 13,
																fontWeight: null
															}} >
															{item.nome_tipo}
														</div>
														<a
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'space-between'
															}} >
															<div
																style={{
																	fontSize: 12,
																	fontWeight: 'bold',
																	color: '#424242'
																}} >
																{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao).split(' ')[1]}
															</div>
														</a>
													</div>
													<div className='dropdown-divider'></div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>

				<li className='nav-item dropdown'>
					<a className='nav-link' data-toggle='dropdown' href='#'>
						<i className='far fa-user'></i>
					</a>
					<div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
						<span className='dropdown-item dropdown-header'>Perfil</span>
						<div className='dropdown-divider'></div>
						<a href='#' className='dropdown-item'>
							<i className='fas fa-user mr-2'></i> Nome
							<span className='float-right text-muted text-sm'>{nome}</span>
						</a>
					<div className='dropdown-divider'></div>
					<a href='#' className='dropdown-item'>
						<i className='fas fa-envelope mr-2'></i> Usuário
						<span className='float-right text-muted text-sm'>{login}</span>
					</a>
					<div className='dropdown-divider'></div>
						<a href='#' onClick={this.props.logout}
							className='btn btn-block btn-primary btn-sm'>Sair</a>
					</div>
				</li>
			</ul>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	notificacao_perfil: state.usuario.notificacao_perfil,
	lista_notificacao: state.notificacao.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	logout, getListaNotificacao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className='navbar-custom-menu'>
	<ul className='nav navbar-nav'>
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href='javascript:;' onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className='dropdown-toggle'
				data-toggle='dropdown'>
				<i className='fa fa-user'/>
				<span className='hidden-xs'>{nome}</span>
			</a>
			<ul className='dropdown-menu'>
				<li className='user-header'>
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className='user-footer'>
					<div className='pull-right'>
						<a href='#' onClick={this.props.logout}
							className='btn btn-default btn-flat'>Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
