const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaLocal: [],
	listaEstado: [],
	listaCidade: [],
	listaRota: [],
	listaViagemStatus: [],
	listaVeiculo: [],
	listaMotorista: [],
	listaTransportadora: [],
	listaProduto: [],
	listaUnidadeMedida: [],
	listaProximaCarga: [],
	listaViagemLancamento: [],
	listaTarifaTipo: [{
		id: 1,
		valor: 'Valor Total do CTRC'
	}, {
		id: 2,
		valor: 'Multiplica por Tonelada'
	}, {
		id: 3,
		valor: 'Multiplica por Km Rodado'
	}, {
		id: 4,
		valor: 'Multiplica por m³'
	}, {
		id: 5,
		valor: 'Multiplica por Unidade'
	}],
	listaTomadorTipo: [{
		id: 1,
		valor: 'Transportadora'
	}, {
		id: 2,
		valor: 'Remetente'
	}, {
		id: 3,
		valor: 'Destinatário'
	}, {
		id: 4,
		valor: 'Terceiro'
	}],
	listaAdiantamentoTipo: [{
		id: 1,
		valor: 'Valor recebido pelo Motorista'
	}, {
		id: 2,
		valor: 'Valor recebido pela Empresa'
	}],
	listaSaldoTipo: [{
		id: 1,
		valor: 'Valor recebido pelo Motorista'
	}, {
		id: 2,
		valor: 'Valor recebido pela Empresa'
	}],
	listaPedagioTipo: [{
		id: 1,
		valor: 'Trajeto Sem Pedágio'
	}, {
		id: 2,
		valor: '+ Pedágio (pago no saldo)'
	}, {
		id: 3,
		valor: '+ Pedágio (pago no adiantamento)'
	}, {
		id: 4,
		valor: 'Pedágio pago no Cartão/Cupom'
	}, {
		id: 5,
		valor: 'Incluso'
	}],
	listaDescargaTipo: [{
		id: 1,
		valor: 'Livre'
	}, {
		id: 2,
		valor: 'A pagar - valor por unidade'
	}],
	listaPosto: [],
	listaDiariaTipo: [{
		id: 1,
		valor: 'Valor recebido pelo Motorista'
	}, {
		id: 2,
		valor: 'Valor recebido pela Empresa'
	}],
	listaSimNao: [{
		id: 1,
		valor: 'Sim'
	}, {
		id: 2,
		valor: 'Não'
	}]
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'VIAGEM_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'VIAGEM_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'VIAGEM_LISTADO':
			return { ...state, lista: action.payload.data };

		case 'VIAGEM_LANCAMENTO_SELECT_LISTADO':
			return { ...state, listaViagemLancamento: action.payload.data };

		case 'VIAGEM_LOCAL_SELECT_LISTADO':
			return { ...state, listaLocal: action.payload.data };

		case 'VIAGEM_ESTADO_SELECT_LISTADO':
			return { ...state, listaEstado: action.payload.data };

		case 'VIAGEM_CIDADE_SELECT_LISTADO':
			return { ...state, listaCidade: action.payload.data };

		case 'VIAGEM_VIAGEM_STATUS_SELECT_LISTADO':
			return { ...state, listaViagemStatus: action.payload.data };

		case 'VIAGEM_VEICULO_SELECT_LISTADO':
			return { ...state, listaVeiculo: action.payload.data };

		case 'VIAGEM_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

		case 'VIAGEM_TRANSPORTADORA_SELECT_LISTADO':
			return { ...state, listaTransportadora: action.payload.data };

		case 'VIAGEM_PRODUTO_SELECT_LISTADO':
			return { ...state, listaProduto: action.payload.data };

		case 'VIAGEM_UNIDADE_MEDIDA_SELECT_LISTADO':
			return { ...state, listaUnidadeMedida: action.payload.data };

		case 'VIAGEM_PROXIMA_CARGA_SELECT_LISTADO':
			return { ...state, listaProximaCarga: action.payload.data };

		case 'VIAGEM_POSTO_SELECT_LISTADO':
			return { ...state, listaPosto: action.payload.data };

		case 'VIAGEM_ROTA_SELECT_LISTADO':
			return { ...state, listaRota: action.payload.data };

		default:
			return state;
	}
}
