import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setFiltro(filtro) {
	return {
		type: 'DASHBOARD_FINANCEIRO_FILTRO',
		payload: filtro,
	};
}

export function getListaEficiencia() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarEficiencia?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_MANUTENCAO_EFICIENCIA_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}

export function getListaPorTipo() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarPorTipo?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_MANUTENCAO_POR_TIPO_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}

export function getListaOcorrenciaResolvida() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarDashboardResolvido?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_MANUTENCAO_OCORRENCIA_RESOLVIDA_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}

export function getListaOcorrenciaNaoResolvida() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarDashboardNaoResolvido?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_MANUTENCAO_OCORRENCIA_NAO_RESOLVIDA_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}

export function getListaDias() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarDashboardDias?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_MANUTENCAO_DIAS_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}

export function getListaAnalise() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarDashboardAnalise?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_MANUTENCAO_ANALISE_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}

export function getListaNaoConformidadeExercicio() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardManutencao.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarDashboardNaoConformidadeExercicio?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_MANUTENCAO_NAO_CONFORMIDADE_EXERCICIO_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}



