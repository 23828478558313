const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    lista: [],
    listaChecklist: [],
	listaSetor: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CHECKLIST_PERGUNTAS_MODO_TELA':
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro
            };

        case 'CHECKLIST_PERGUNTAS_LISTADO':
            return { ...state, lista: action.payload.data };


        case 'CHECKLIST_PERGUNTAS_SET_AGUARDANDO':
            return {
                ...state,
                aguardando: action.payload
            };

        case 'CHECKLIST_PERGUNTAS_CHECKLIST_LISTADO':
            return {
                ...state,
                listaChecklist: action.payload.data
            };

        case 'CHECKLIST_PERGUNTAS_SETOR_SELECT_LISTADO':
            return {
                ...state,
                listaSetor: action.payload.data
            };

        default:
            return state;
    }
}
