import React from 'react'
import { getPreciseDistance, getCenter } from "geolib";
import {
	GoogleMap,
	Marker,
	LoadScript,
	StandaloneSearchBox,
	DirectionsRenderer,
	useJsApiLoader,
	withScriptjs, 
	withGoogleMap
} from '@react-google-maps/api';
//https://github.com/fcbergmann/my-map/blob/main/src/pages/MapPage.tsx
// import {
//   geocodeByPlaceId
// } from 'react-places-autocomplete';

import Row from '../layout/row';
import Grid from '../layout/grid';
import Button from '../button/button';
import { getDistance } from 'geolib';
import LabelAndInput from '../form/labelAndInput';
import axios from 'axios';
import { setError } from '../toast/toast';
import markerAzul from '../../assets/mapa/marker_azul.png';
import markerAzulTag from '../../assets/mapa/marker_azul_tag.png';
import markerVerde from '../../assets/mapa/marker_verde.png';
import markerVerdeTag from '../../assets/mapa/marker_verde_tag.png';
import markerAmarelo from '../../assets/mapa/marker_amarelo.png';
import markerAmareloTag from '../../assets/mapa/marker_amarelo_tag.png';
import markerLaranja from '../../assets/mapa/marker_laranja.png';
import markerLaranjaTag from '../../assets/mapa/marker_laranja_tag.png';
import markerVermelho from '../../assets/mapa/marker_vermelho.png';
import markerVermelhoTag from '../../assets/mapa/marker_vermelho_tag.png';
import markerHome from '../../assets/mapa/marker_home.png';
import markerTruckVermelho from '../../assets/mapa/marker_truck_vermelho.png';
import markerTruckAzul from '../../assets/mapa/marker_truck_azul.png';
import markerTruckVerde from '../../assets/mapa/marker_truck_verde.png';
import markerTruckAmarelo from '../../assets/mapa/marker_truck_amarelo.png';
import markerTruckLaranja from '../../assets/mapa/marker_truck_laranja.png';
import markerTruckVermelhoTerceiro from '../../assets/mapa/marker_truck_vermelho_terceiro.png';
import markerTruckAzulTerceiro from '../../assets/mapa/marker_truck_azul_terceiro.png';
import markerTruckVerdeTerceiro from '../../assets/mapa/marker_truck_verde_terceiro.png';
import markerTruckAmareloTerceiro from '../../assets/mapa/marker_truck_amarelo_terceiro.png';
import markerTruckLaranjaTerceiro from '../../assets/mapa/marker_truck_laranja_terceiro.png';
import markerPontoPosto from '../../assets/mapa/marker_ponto_posto.png';
import markerPontoMecanica from '../../assets/mapa/marker_ponto_mecanica.png';
import markerPontoProblemaPista from '../../assets/mapa/marker_ponto_problema_pista.png';
import markerPontoTransitoInterrompido from '../../assets/mapa/marker_ponto_transito_interrompido.png';
import markerPontoReferencia from '../../assets/mapa/marker_ponto_referencia.png';
import markerInterrogacao from '../../assets/mapa/marker_interrogacao.png';

const raio = 15000;

export default props => {

	const [directionsRef, setDirectionsRef] = React.useState(null);
	const [places, setPlaces] = React.useState([]);

	const containerStyle = {
		width: '100%',
		height: window.innerHeight * 0.70
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyAK578qzVAohLyd3UEMQzUbDYExVITWloE'
	});

	const icones = [
		{ nome: 'marker-azul', icone: markerAzul },
		{ nome: 'marker-azul-tag', icone: markerAzulTag },
		{ nome: 'marker-verde', icone: markerVerde },
		{ nome: 'marker-verde-tag', icone: markerVerdeTag },
		{ nome: 'marker-amarelo', icone: markerAmarelo },
		{ nome: 'marker-amarelo-tag', icone: markerAmareloTag },
		{ nome: 'marker-laranja', icone: markerLaranja },
		{ nome: 'marker-laranja-tag', icone: markerLaranjaTag },
		{ nome: 'marker-vermelho', icone: markerVermelho },
		{ nome: 'marker-vermelho-tag', icone: markerVermelhoTag },
		{ nome: 'marker-home', icone: markerHome },
		{ nome: 'marker-truck-vermelho', icone: markerTruckVermelho },
		{ nome: 'marker-truck-azul', icone: markerTruckAzul },
		{ nome: 'marker-truck-verde', icone: markerTruckVerde },
		{ nome: 'marker-truck-amarelo', icone: markerTruckAmarelo },
		{ nome: 'marker-truck-laranja', icone: markerTruckLaranja },
		{ nome: 'marker-truck-vermelho-terceiro', icone: markerTruckVermelhoTerceiro },
		{ nome: 'marker-truck-azul-terceiro', icone: markerTruckAzulTerceiro },
		{ nome: 'marker-truck-verde-terceiro', icone: markerTruckVerdeTerceiro },
		{ nome: 'marker-truck-amarelo-terceiro', icone: markerTruckAmareloTerceiro },
		{ nome: 'marker-truck-laranja-terceiro', icone: markerTruckLaranjaTerceiro },
		{ nome: 'marker-ponto-posto', icone: markerPontoPosto },
		{ nome: 'marker-ponto-mecanica', icone: markerPontoMecanica },
		{ nome: 'marker-ponto-problema-pista', icone: markerPontoProblemaPista },
		{ nome: 'marker-ponto-transito-interrompido', icone: markerPontoTransitoInterrompido },
		{ nome: 'marker-ponto-referencia', icone: markerPontoReferencia },
		{ nome: 'marker-interrogacao', icone: markerInterrogacao }
	];

	const [ponto, setPonto] = React.useState(null);

	let modalPonto = () => {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Ponto</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => setPonto(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							{ponto.nome.split('\n').map(linha => (
								<p>{linha}</p>
							))}
							
							<Row>
								<Grid cols='12 6 4 3'>
									<button
										type='button'
										class='btn btn-block btn-outline-primary btn-sm'
										onClick={() => {
											window.open(`https://www.google.com/maps/place/${ponto.latitude},${ponto.longitude}`, '_blank');
										}}>
										Abrir Google Maps
									</button>
								</Grid>
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							{ponto.evento && (
								<button type='button' class='btn btn-default' data-dismiss='modal'
									onClick={ponto.evento}>{ponto.nomeEvento}</button>
							)}
							<button type='button' class='btn btn-primary'
								onClick={() => setPonto(null)}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

    let calcularRota = async (itens) => {


	   // eslint-disable-next-line no-undef
	   const DirectionsService = new google.maps.DirectionsService();
	   let result = await DirectionsService.route({
		   origin: { lat: -15.927315, lng: -52.309051 },
		   destination:{ lat: -15.927315, lng: -52.309051 },
		   optimizeWaypoints: true,
		   travelMode: 'DRIVING',
	   })
	   if (result.status == 'OK') {

		   let waypoints = [];

	
		  props.setRota({
			  rotaCarregada: result
		  });

	   } else {
		   props.setRota(null);
	   }
   }

   if (!props.rotaCarregada) {
	   calcularRota();
   }

   const [pesquisa, setPesquisa] = React.useState('');

   if (isLoaded) {
		return (
			<>
				{ponto ? modalPonto() : null}
				{/*<input className='form-control'
					 placeholder='Pesquisar'
					 type='text'
					 style={{ marginBottom: 2 }}
					 value={pesquisa}
					 onChange={e => {
						 setPesquisa(e.target.value);
					 }} />*/}
				{props.rotaCarregada ? (
					<GoogleMap
						mapContainerStyle={containerStyle}
						options={{ zoom: 4 }}
						onLoad={map => {
						}}
						onClick={(e) => {
							
						}} >

						{props.rota && props.pontos ? props.pontos.filter(item => !pesquisa || item.nome.toUpperCase().includes(pesquisa.toUpperCase())).map((item, i) => {

							let icone = icones.filter(itemIcone => itemIcone.nome == item.icone)[0] ? icones.filter(itemIcone => itemIcone.nome == item.icone)[0].icone : null;
														
							return (
								<Marker key={item.id}
									position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
								 	options={{
										label: {
											text: item.label,
											className: 'map-marker'
										},
										title: `${item.nome}`,
										icon: {
											url: icone
										}
									}}
									onClick={() => setPonto(item)}/>
							);
						}) : null}

						{props.rotaCarregada ? (
							<DirectionsRenderer
								directions={props.rotaCarregada}
								ref={ref => setDirectionsRef(ref)}								
								options={{
									suppressMarkers: true,
									draggable: true
								}} />
						) : null}

					</GoogleMap>
				) : null}
			</>
		);
	} else {
		return null;
	}
}
