import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './postoForm';
import Map from '../common/map/mapVisualizacao';

import { setModoTela, initForm, salvar, excluir, getListaCidade, getLista, getListaRede, getListaFormaPagamento } from './postoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class Posto extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		modo: 'lista'
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaCidade();
		this.props.getListaRede();
		this.props.getListaFormaPagamento();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'negociacao' ? (
						<Form onSubmit={this.props.salvar} negociacao />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.codigo} ${item.nome_rede} ${item.nome} ${item.telefone} ${item.cnpj} ${item.nome_cidade}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_cadastro != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						placeholder='Pesquisar'
						type='text'
						value={this.state.pesquisar}
						onChange={e => {
							this.setState({ ...this.state, pesquisar: e.target.value });
						}} />

					<div class="btn-group">
						<button 
							type='button'
							class={`btn btn-${this.state.modo == 'lista' ? 'info' : 'default'}`} style={{ width: 150 }}
							onClick={() => this.setState({ ...this.state, modo: 'lista' })}>
							Lista
						</button>
						<button
							type='button'
							class={`btn btn-${this.state.modo == 'mapa' ? 'info' : 'default'}`}
							style={{ width: 150 }}
							onClick={() => {
								this.props.getLista();
								this.setState({ ...this.state, modo: 'mapa' });
							}}>
							Mapa
						</button>
					</div>
					
					{this.state.modo == 'lista' ? (
						<Table responsive>
							<THead>
								<Tr>
									<Th>Código Atua</Th>
									<Th>Rede</Th>
									<Th>Nome</Th>
									<Th>Telefone</Th>
									<Th>CNPJ</Th>
									<Th>Cidade</Th>
									<Th>Validade Diesel</Th>
									<Th>Validade Arla</Th>
									<Th alignCenter>Status</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{lista.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} 
										backgroundColor={item.desativado || item.cancelado ? '#ff9696' : null}>
										<Td>{item.codigo}</Td>
										<Td>{item.nome_rede}</Td>
										<Td>{item.nome}</Td>
										<Td>{FormatUtils.formatarTelefone(item.telefone)}</Td>
										<Td>{FormatUtils.formatarCNPJ(item.cnpj)}</Td>
										<Td>{item.nome_cidade}</Td>
										<Td alignCenter 
											style={{ 
												fontWeight: 'bold', 
												color: item.desativado || item.cancelado ? null : (item.dias_para_vencimento >= 1 && item.dias_para_vencimento < 5 ? '#000' : '#fff'), 
												backgroundColor: item.desativado || item.cancelado ? null : (item.dias_para_vencimento >= 10 ? '#00c853' : (item.dias_para_vencimento >= 5 ? '#29b6f6' : (item.dias_para_vencimento >= 1 ? '#ff0' : '#ff5252'))) }}>
											{DateFormat.formatarDataSqlParaTela(item.data_validade_diesel)}
										</Td>
										<Td>{DateFormat.formatarDataSqlParaTela(item.data_validade_arla)}</Td>
										<Td alignCenter>{item.desativado ? 'Desativado' : (item.cancelado ? 'Cancelado para Negociação' : (item.dias_para_vencimento > 0 ? 'Dentro do Prazo' : 'Fora do Prazo'))}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'success'}
												icon={'fas fa-dollar-sign'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={this.props.permissoes.acesso_cadastro != 2}
												event={() => {
													this.props.setModoTela('negociacao', {
														...item
													});
													this.props.initForm({
														...item
													});
												}} />

											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={this.props.permissoes.acesso_cadastro != 2}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item
													});
													this.props.initForm({
														...item
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={this.props.permissoes.acesso_cadastro != 2}
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														senha: null
													});
													this.props.initForm({
														...item,
														senha: null
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					) : (
						<Map 
							pontos={lista.map(item => ({
								id: `posto-${item.id}`,
								nome: `${item.nome}\n`
									+ `${item.codigo || ''}\n`
									+ `Telefone: ${FormatUtils.formatarTelefone(item.telefone)}\n`
									+ `CNPJ: ${FormatUtils.formatarCNPJ(item.cnpj)}\n`
									+ `Cidade: ${item.nome_cidade || ''}\n`
									+ `Preço Diesel: R$ ${FormatUtils.formatarValorTela(item.preco_diesel, 4)}\n`
									+ `Validade Diesel: ${item.data_validade_diesel ? DateFormat.formatarDataSqlParaTela(item.data_validade_diesel) || '' : 'Sem validade'}\n`
									+ `Preço Arla: R$ ${FormatUtils.formatarValorTela(item.preco_arla, 4)}\n`
									+ `Validade Arla: ${item.data_validade_arla ? DateFormat.formatarDataSqlParaTela(item.data_validade_arla || '') : 'Sem validade'}\n`
									+ `Forma Pagamento: ${item.nome_forma_pagamento || 'Não Informada'}\n`,
								latitude: item.latitude,
								longitude: item.longitude,
								icone: item.dias_para_vencimento >= 10 ? 'marker-verde' : (
									item.dias_para_vencimento >= 5 ? 'marker-azul' : (item.dias_para_vencimento >= 1 ? 'marker-amarelo' : 'marker-vermelho')
								),
								nomeEvento: 'Editar',
								evento: () => {
									this.props.setModoTela('cadastro', {
										...item
									});
									this.props.initForm({
										...item
									});
								}
							}))}
							rota={this.state.rota}
							rotaCarregada={this.state.rotaCarregada}
							setRota={rota => {
								this.setState({
									...this.state,
									rotaCarregada: rota.rotaCarregada,
									rota: {
										pontos: rota ? rota.pontos : [],
										itens: rota ? rota.itens : [],
										overview_polyline: rota.overview_polyline || null
									},
									postos: rota ? rota.pontos : []
								});
							}} />
					)}
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.posto,
	modoTela: state.posto.modoTela,
	lista: state.posto.lista,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({setModoTela, initForm, salvar, excluir, getListaCidade, getLista, getListaRede, getListaFormaPagamento }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Posto);
