import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Map from '../common/map/map';
import ButtonTable from '../common/table/buttonTable';
import NumberFormat from 'react-number-format';

import {
	setModoTela,
	initForm
} from './rotaActions';

class RotaForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let origem = this.props.listaCidade.filter(item => item.id == this.props.formularioValues.id_cidade_origem).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0];
		let destino = this.props.listaCidade.filter(item => item.id == this.props.formularioValues.id_cidade_destino).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0];

		return (
			<>
				<Form event={this.props.handleSubmit}>
					<ContentCard>					
						<ContentCardHeader>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 6 4'
									readOnly={readOnly} />

								<Field
									name='codigo'
									component={LabelAndInput}
									label='Código GR' placeholder='Informe o código'
									cols='12 6 3 2'
									readOnly={readOnly} />
									
								<Field
									name='id_tipo'
									component={Select}
									label='Tipo' placeholder='Informe o tipo'
									options={this.props.listaTipo}
									cols='12 6 3 2'
									readOnly={readOnly} />

								<Select
									name='veiculo_tipos'
									component={Select}
									options={this.props.listaVeiculoTipo}
									label='Veículos' placeholder='Informe os veículos'
									cols='12 12 12 4'
									readOnly={readOnly}
									multiple
									value={(this.props.formularioValues.veiculo_tipos || []).map(item => ({
										value: item,
										label: this.props.listaVeiculoTipo.filter(tipo => tipo.id == item).map(tipo => tipo.valor).join()
									}))}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											veiculo_tipos: (data || []).map(item => item.value)
										})
									}} />

								<Field
									name='id_estado_origem'
									component={Select}
									options={this.props.listaEstado}
									label='Estado de Origem'
									cols='12 12 6 6'
									placeholder='Selecione o estado'
									readOnly={readOnly} />

								<Field
									name='id_cidade_origem'
									component={Select}
									options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado_origem)}
									label='Cidade de Origem'
									cols='12 12 6 6'
									placeholder='Selecione a cidade'
									readOnly={readOnly} />
							</Row>

							<label>Pontos ({this.state.quantidadeItens || (this.props.formularioValues.itens || []).length})</label>
							{(this.props.formularioValues.itens || []).filter(item => item.ativo).map(item => (
								<ContentCard key={item.id || item.id_aux}>
									<ContentCardBody>
										<Row>
											<Select
												name='id_estado'
												options={this.props.listaEstado}
												label='Estado'
												cols='12 5 5 5'
												placeholder='Selecione o estado'
												readOnly={readOnly}
												value={item.id_estado}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_estado: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

											<Select
												name='id_cidade_carga'
												options={this.props.listaCidade.filter(values => values.id_estado == item.id_estado)}
												label='Cidade'
												cols='12 5 5 5'
												placeholder='Selecione a cidade'
												readOnly={readOnly}
												value={item.id_cidade}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_cidade: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

											<Select
												name='id_tipo'
												options={[{ id: 1, valor: 'Coleta' }, { id: 2, valor: 'Entrega' }]}
												label='Tipo'
												cols='12 2 2 2'
												placeholder='Selecione o tipo'
												readOnly={readOnly}
												value={item.id_tipo}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_tipo: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

										</Row>
									</ContentCardBody>
								</ContentCard>
							))}

							<Row>
								<Grid cols='6 6 3 2' style={{ paddingBottom: 28 }}>
									<Button
										text='Adicionar'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => {
											let lista = this.props.formularioValues.itens || [];
											lista.push({
												id_aux: new Date().getTime(),
												ativo: true
											});
											this.props.initForm({
												...this.props.formularioValues,
												itens: lista
											});
											this.setState({
												...this.state,
												quantidadeItens: lista.filter(itemAux => itemAux.ativo).length
											});
										}} />
								</Grid>
							</Row>

							<Row>
								<Field
									name='id_estado_destino'
									component={Select}
									options={this.props.listaEstado}
									label='Estado de Destino'
									cols='12 12 6 6'
									placeholder='Selecione o estado'
									readOnly={readOnly} />

								<Field
									name='id_cidade_destino'
									component={Select}
									options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado_destino)}
									label='Cidade de Destino'
									cols='12 12 6 6'
									placeholder='Selecione a cidade'
									readOnly={readOnly} />
							</Row>
						</ContentCardBody>
					</ContentCard>
					{origem && destino ? (
						<Map
							origem={origem}
							itens={(this.props.formularioValues.itens || []).map(item => this.props.listaCidade.filter(value => value.id == item.id_cidade).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0]).filter(item => item)}
							destino={destino}
							pontos={this.props.listaPosto}
							rota={this.props.formularioValues.rota}
							rotaCarregada={this.props.formularioValues.rotaCarregada}
							setRota={rota => {
								this.props.initForm({
									...this.props.formularioValues,
									rotaCarregada: rota.rotaCarregada,
									rota: {
										origin: rota.origin,
										destino: rota.destino,
										waypoints: rota.waypoints,
										distancia: rota.distancia,
										passos_localizacao: rota.passos_localizacao,
										link_google_maps: rota.link_google_maps,
										pontos: rota ? rota.pontos : [],
										itens: rota ? rota.itens : [],
										overview_polyline: rota.overview_polyline || null
									},
									postos: rota ? rota.pontos : []
								});
							}} />
					) : null}
					<ContentCard>
						<ContentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th width={300}>Postos</Th>
										<Th width={100} alignCenter>Rede</Th>
										<Th width={100} alignCenter>Cidade</Th>
										<Th width={100} alignCenter>Forma de Pagamento</Th>
										<Th width={100} alignCenter>Preço Diesel</Th>
										<Th width={100} alignCenter>Preço Arla</Th>
										<Th width={100} alignCenter>Qtd Diesel</Th>
										<Th width={100} alignCenter>Liberado</Th>
									</Tr>
								</THead>
								<TBody>
									{(this.props.formularioValues.postos || []).map(posto => {
														
										let postoCarregado = this.props.listaPosto.filter(item => item.id == posto.id)[0] || posto;

										let postoLiberado = (this.props.formularioValues.postos_liberados || []).filter(postoLiberado => postoLiberado.id == posto.id)[0];

										return (
											<Tr key={posto.id} >
												<Td>{postoCarregado.descricao}</Td>
												<Td alignCenter>{postoCarregado.nome_rede}</Td>
												<Td alignCenter>{postoCarregado.nome_cidade}</Td>
												<Td alignCenter>{postoCarregado.nome_forma_pagamento}</Td>
												<Td alignCenter>R$ {(postoCarregado.preco_diesel || '0.00').replace('.', ',')}</Td>
												<Td alignCenter>R$ {(postoCarregado.preco_arla || '0.00').replace('.', ',')}</Td>
												<Td alignCenter>
													{postoLiberado &&
													<NumberFormat
														className='form-control'
														style={{ textAlign: 'center' }}
														readOnly={readOnly}
														placeholder='Completa Tanque'
														decimalSeparator={","}
														decimalScale={0}
														fixedDecimalScale={0}
														value={postoLiberado.quantidade_diesel || null}
														onChange={data => {
															let lista = (this.props.formularioValues.postos_liberados || []).map(postoLiberado => postoLiberado);
															lista = lista.map(postoAux => {
																if (postoAux.id == posto.id) {
																	return {
																		...postoAux,
																		quantidade_diesel: data.target.value
																	};
																} else {
																	return postoAux;
																}
															});

															this.props.initForm({
																...this.props.formularioValues,
																postos_liberados: lista
															});
														}} />}
												</Td>
												<Td alignCenter minWidth={100}>
													<label htmlFor={'posto'}>
														<input
															style={{ marginTop: 12 }}
															type='checkbox'
															checked={postoLiberado ? true : false}
															onChange={data => {
																let lista = (this.props.formularioValues.postos_liberados || []).map(postoLiberado => postoLiberado);
																if (lista.filter(postoLiberado => postoLiberado.id == posto.id).length) {
																	lista = lista.filter(postoLiberado => postoLiberado.id != posto.id);
																} else {
																	lista.push({
																		id: posto.id,
																		quantidade_diesel: 0
																	});
																}
																this.props.initForm({
																	...this.props.formularioValues,
																	postos_liberados: lista
																});
															}} />
													</label>
												</Td>
											</Tr>
										);
									})}
								</TBody>
							</Table>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{!this.props.excluir &&
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</ContentCard>
				</Form>
			</>
		)
	}
}

RotaForm = reduxForm({ form: 'rotaForm', destroyOnUnmount: false })(RotaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('rotaForm')(state),
	registro: state.rota.registro,
	listaEstado: state.rota.listaEstado,
	listaCidade: state.rota.listaCidade,
	listaPosto: state.rota.listaPosto,
	listaTipo: state.rota.listaTipo,
	listaVeiculoTipo: state.rota.listaVeiculoTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela,
	initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RotaForm);
