import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Map from '../common/map/map';
import ButtonTable from '../common/table/buttonTable';

import {
	setModoTela,
	initForm,
	carregarRota,
	getListaEstado,
	getListaCidade,
	getListaViagemStatus,
	getListaViagemLancamento,
	getListaLocal, salvarLocal,
	getListaVeiculo, salvarVeiculo,
	getListaMotorista, salvarMotorista,
	getListaTransportadora, salvarTransportadora,
	getListaProduto, salvarProduto,
	getListaUnidadeMedida, salvarUnidadeMedida,
	getListaProximaCarga,
	gerarRotaPdf
} from './viagemActions';

import {
	setModoTela as setModoTelaLancamentoViagem
} from '../viagemLancamento/viagemLancamentoActions';

class ViagemForm extends Component {

	state = {
		modoCadastroLocalCarga: false,
		localCarga: null,
		modoCadastroLocalDescarga: false,
		localDescarga: null,
		modoCadastroVeiculo: false,
		veiculo: null,
		modoCadastroMotorista: false,
		motorista: null,
		modoCadastroTransportadora: false,
		transportadora: null,
		modoCadastroProduto: false,
		produto: null,
		modoCadastroUnidadeMedida: false,
		unidadeMedida: null,
		rotaCalculada: null,
		origem: null,
		destino: null
	}

	componentWillMount() {
		this.props.getListaViagemLancamento();
		this.props.getListaEstado();
		this.props.getListaCidade();
		this.props.getListaViagemStatus();
		this.props.getListaLocal();
		this.props.getListaVeiculo();
		this.props.getListaMotorista();
		this.props.getListaTransportadora();
		this.props.getListaProduto();
		this.props.getListaUnidadeMedida();
		this.props.getListaProximaCarga();

	}

	render() {

		let idMotorista = this.props.sessao && this.props.sessao.id_motorista ? this.props.sessao.id_motorista : null;

		let superAdmin = this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 7 || this.props.sessao.id == 82);
		let readOnly = this.props.excluir || this.props.visualizacaoViagem || idMotorista ? 'readOnly' : '';

		let quantidade = parseFloat(String(this.props.formularioValues.quantidade || 0).split(".").join("").replace(",", "."));
		let preco_unitario = parseFloat(String(this.props.formularioValues.preco_unitario || 0).split(".").join("").replace(",", "."));
		let valor_tarifa = parseFloat(String(this.props.formularioValues.valor_tarifa || 0).split(".").join("").replace(",", "."));
		let tomador_frete = parseFloat(String(this.props.formularioValues.tomador_frete || 0).split(".").join("").replace(",", "."));
		let percentual_adiantamento = parseFloat(String(this.props.formularioValues.percentual_adiantamento || 0).split(".").join("").replace(",", "."));
		let percentual_saldo = parseFloat(String(this.props.formularioValues.percentual_saldo || 0).split(".").join("").replace(",", "."));
		let valor_pedagio = parseFloat(String(this.props.formularioValues.valor_pedagio || 0).split(".").join("").replace(",", "."));
		let valor_descarga = parseFloat(String(this.props.formularioValues.valor_descarga || 0).split(".").join("").replace(",", "."));
		let porcentagem_comissao_moto = parseFloat(String(this.props.formularioValues.porcentagem_comissao_moto || 0).split(".").join("").replace(",", "."));
		let qtde_km = parseFloat(String(this.props.formularioValues.qtde_km || 0).split(".").join("").replace(",", "."));
		let media = parseFloat(String(this.props.formularioValues.media || 0).split(".").join("").replace(",", "."));
		let preco_combustivel = parseFloat(String(this.props.formularioValues.preco_combustivel || 0).split(".").join("").replace(",", "."));
		let seguro_carga = parseFloat(String(this.props.formularioValues.seguro_carga || 0).split(".").join("").replace(",", "."));
		let outros_custos = parseFloat(String(this.props.formularioValues.outros_custos || 0).split(".").join("").replace(",", "."));
		let deslocamento_origem_destino = parseFloat(String(this.props.formularioValues.deslocamento_origem_destino || 0).split(".").join("").replace(",", "."));
		let deslocamento_qtde_km = parseFloat(String(this.props.formularioValues.deslocamento_qtde_km || 0).split(".").join("").replace(",", "."));
		let deslocamento_media = parseFloat(String(this.props.formularioValues.deslocamento_media || 0).split(".").join("").replace(",", "."));
		let deslocamento_preco_combustivel = parseFloat(String(this.props.formularioValues.deslocamento_preco_combustivel || 0).split(".").join("").replace(",", "."));
		let deslocamento_pedagio = parseFloat(String(this.props.formularioValues.deslocamento_pedagio || 0).split(".").join("").replace(",", "."));
		let carregado_pedagio = parseFloat(String(this.props.formularioValues.carregado_pedagio || 0).split(".").join("").replace(",", "."));
		let valor_tarifa_motorista = parseFloat(String(this.props.formularioValues.valor_tarifa_motorista || 0).split(".").join("").replace(",", "."));

		let valorTotalFrete = 0;
		let valorTotalFreteMotorista = 0;
		if (this.props.formularioValues.id_tipo_tarifa == 1) {
			//apenas o valor total
			valorTotalFrete = valor_tarifa_motorista;
			valorTotalFreteMotorista = valor_tarifa_motorista;
		} else if (this.props.formularioValues.id_tipo_tarifa == 2) {
			//apenas o valor total
			valorTotalFrete = valor_tarifa_motorista * quantidade;
			valorTotalFreteMotorista = valor_tarifa_motorista * quantidade;
		} else if (this.props.formularioValues.id_tipo_tarifa == 3) {
			//apenas o valor total
			valorTotalFrete = valor_tarifa_motorista * quantidade;
			valorTotalFreteMotorista = valor_tarifa_motorista * quantidade;
		} else if (this.props.formularioValues.id_tipo_tarifa == 4) {
			//apenas o valor total
			valorTotalFrete = valor_tarifa_motorista * (qtde_km + deslocamento_qtde_km);
			valorTotalFreteMotorista = valor_tarifa_motorista * (qtde_km + deslocamento_qtde_km);
		} else if (this.props.formularioValues.id_tipo_tarifa == 5) {
			//apenas o valor total
			valorTotalFrete = valor_tarifa_motorista * quantidade;
			valorTotalFreteMotorista = valor_tarifa_motorista * quantidade;
		}
		//Inclui o pedágio apenas se for no cartão

		if (this.props.formularioValues.id_tipo_pedagio == 2 || this.props.formularioValues.id_tipo_pedagio == 3 || this.props.formularioValues.id_tipo_pedagio == 4) {
			valorTotalFrete = valorTotalFrete + valor_pedagio;
		}
		// valorTotalFrete = valorTotalFrete - carregado_pedagio - seguro_carga - outros_custos;
		let valorBaseCalculoFreteMotorista = valorTotalFrete - seguro_carga - outros_custos;

		let comissaoMotorista = (valorBaseCalculoFreteMotorista * porcentagem_comissao_moto) / 100;
		let diasViagem = (qtde_km + deslocamento_qtde_km) / 800;
		let custoCombustivelDesloca = !deslocamento_qtde_km ? 0 : (deslocamento_qtde_km / deslocamento_media) * deslocamento_preco_combustivel;
		let custoTotalDeslocamento = deslocamento_pedagio + custoCombustivelDesloca;
		let custoCombustivelCarregado = qtde_km && media && preco_combustivel ? (qtde_km / media) * preco_combustivel : 0;
		let custoTotalFrete = comissaoMotorista + custoCombustivelCarregado + seguro_carga + outros_custos + carregado_pedagio;
		let lucroLiquido = valorTotalFrete - custoTotalFrete - custoTotalDeslocamento;
		let lucroKmTotal = lucroLiquido && qtde_km ? lucroLiquido / qtde_km : 0;
		let valorKmFrete = valorTotalFrete && qtde_km ? valorTotalFrete / qtde_km : 0;

		let diaria_total = parseFloat(String(this.props.formularioValues.diaria_empresa || 0).split(".").join("").replace(",", "."))
			+ parseFloat(String(this.props.formularioValues.diaria_motorista || 0).split(".").join("").replace(",", "."));
		
		let origem = this.props.listaCidade.filter(item => item.id == this.props.formularioValues.id_cidade_carga).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0];
		let destino = this.props.listaCidade.filter(item => item.id == this.props.formularioValues.id_cidade_descarga).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0];

		let linkRota = null;

		let rota = this.props.listaRota.filter(item => item.id == this.props.formularioValues.id_rota)[0];
		if (rota && rota.rota) {
			linkRota = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyAK578qzVAohLyd3UEMQzUbDYExVITWloE&origin=${rota.rota.origin.latitude},${rota.rota.origin.longitude}&destination=${rota.rota.destino.latitude},${rota.rota.destino.longitude}${rota.rota.waypoints.length > 0 ? `&waypoints=${rota.rota.waypoints.map(waypoint=> `${waypoint.latitude},${waypoint.longitude}`).join('|')}` : ''}`;
		}

		if (this.props.formularioValues.valor_desconto_saldo == null || this.props.formularioValues.valor_desconto_saldo == undefined) {

			let valorAdiantamento = parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_adiantamento, 2));
			let valorSaldo = (valor_tarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_saldo, 2))) / 100;
			if (valorSaldo + valorAdiantamento != valor_tarifa) {
				valorSaldo += valor_tarifa - (valorSaldo + valorAdiantamento);
			}
			
			this.props.initForm({
				...this.props.formularioValues,
				valor_desconto_saldo: FormatUtils.formatarValorTela(
					valorSaldo
					- parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_saldo, 2)), 2)
					
			});
		}

		let deslocamentoVazio = this.props.formularioValues.deslocamento_vazio || false;

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardHeader>
							<Row alignCenter>
								<Grid cols='6 4 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
										<Button
											text='Salvar'
											submit
											disabled={false}
											type={'success'}
											icon={'fa fa-check'} />
									)}
								</Grid>
								{!this.props.excluir && !idMotorista ? (
									<Grid cols='6 4 4 3'>
										<Button
											text='Excluir'
											type={'danger'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('exclusao', this.props.registro)} />
									</Grid>
								) : null}
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.visualizacaoViagem ? this.props.setModoTelaLancamentoViagem('cadastro', this.props.registroLancamentoViagem) : this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							{this.props.formularioValues.id ? (
								<Row>
									<Field
										name='id_viagem_lancamento'
										component={Select}
										options={this.props.listaViagemLancamento}
										label='Viagem'
										cols='12 12 12 12'
										placeholder='Selecione a viagem'
										readOnly={readOnly} />
								</Row>
							) : null}
							<Row>
								<LabelAndCheckbox
									name='deslocamento_vazio'
									label='Deslocamento Vazio'
									cols='12 12 12 12'
									placeholder='Selecione se é deslocamento vazio'
									readOnly={readOnly}
									value={this.props.formularioValues.deslocamento_vazio}
									onChange={() => this.props.initForm({
										...this.props.formularioValues,
										deslocamento_vazio: !this.props.formularioValues.deslocamento_vazio,
										documentacao: !this.props.formularioValues.deslocamento_vazio ? true : false
									})} />
							</Row>
							<Row>
								<Field
									name='id_status'
									component={Select}
									options={this.props.listaViagemStatus}
									label='Status'
									cols='12 12 12 12'
									placeholder='Selecione o status'
									readOnly={
										this.props.formularioValues.id 
										&& this.props.formularioValues.id_status != 7 
										&& this.props.formularioValues.id_status != 8 
										&& !this.props.formularioValues.veiculo_terceiro
										? 'readOnly' 
										: readOnly /*Só permite modificar se é status manutenção ou vazio*/} />
							</Row>
							
							<Row>
								<Field
									name='id_usuario_proxima_carga'
									component={Select}
									label='Usuário Próxima Carga' placeholder='Informe a próxima carga'
									cols='12 12 4 4'
									readOnly={readOnly}
									options={this.props.listaProximaCarga.filter(item => item.id == 2 || item.id == 3 || item.id == 31)} /*HENRIQUE TRAESEL, MATIAS RIFFEL, WILLIAM ENGEL*/ />
							</Row>
							<Row>
								<Field
									name='datahora_agendamento'
									component={LabelAndInputDateTime}
									label='Date e Hora Agendada' placeholder='Informe a data'
									cols='12 12 4 4'
									readOnly={readOnly} />

								<Field
									name='datahora_carga'
									component={LabelAndInputDateTime}
									label='Date e Hora Carga' placeholder='Informe a data'
									cols='12 12 4 4'
									readOnly={readOnly} />

								<Field
									name='datahora_descarga'
									component={LabelAndInputDateTime}
									label='Date e Hora Descarga' placeholder='Informe a data'
									cols='12 12 4 4'
									readOnly={readOnly} />

							</Row>

							<Row>
								<Field
									name='km_inicial'
									component={LabelAndInputNumber}
									label='KM Inicial' placeholder='Informe o km'
									cols='12 12 6 4'
									readOnly={!idMotorista && !superAdmin ? 'readOnly' : null} />

								<Field
									name='km_final'
									component={LabelAndInputNumber}
									label='KM Final' placeholder='Informe o km'
									cols='12 12 6 4'
									readOnly={!idMotorista && !superAdmin ? 'readOnly' : null} />
							</Row>
							{/*
							<Row>
								<Field
									name='local_carga'
									component={LabelAndInput}
									label='Local de carga' placeholder='Informe o local'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='id_estado_carga'
									component={Select}
									options={this.props.listaEstado}
									label='Estado de Carga'
									cols='12 12 6 6'
									placeholder='Selecione o estado'
									readOnly={readOnly} />

								<Field
									name='id_cidade_carga'
									component={Select}
									options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado_carga)}
									label='Cidade de Carga'
									cols='12 12 6 6'
									placeholder='Selecione a cidade'
									readOnly={readOnly} />
							</Row>

							<Row>
								<Field
									name='local_descarga'
									component={LabelAndInput}
									label='Local de descarga' placeholder='Informe o local'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>

							<Row>
								<Field
									name='id_estado_descarga'
									component={Select}
									options={this.props.listaEstado}
									label='Estado de Descarga'
									cols='12 12 6 6'
									placeholder='Selecione o estado'
									readOnly={readOnly} />

								<Field
									name='id_cidade_descarga'
									component={Select}
									options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado_descarga)}
									label='Cidade de Descarga'
									cols='12 12 6 6'
									placeholder='Selecione a cidade'
									readOnly={readOnly} />
							</Row>
							*/}

							<label>Origem / Destino ({this.state.quantidadeItens || (this.props.formularioValues.itens || []).length})</label>
							{(this.props.formularioValues.itens || []).filter(item => item.ativo).map(item => (
								<ContentCard key={item.id || item.id_aux}>
									<ContentCardBody>
										<Row>
											<Select
												name='id_estado_carga'
												options={this.props.listaEstado}
												label='Estado de Carga'
												cols='12 4 2 2'
												placeholder='Selecione o estado'
												readOnly={readOnly}
												value={item.id_estado_carga}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_estado_carga: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

											<Select
												name='id_cidade_carga'
												options={this.props.listaCidade.filter(values => values.id_estado == item.id_estado_carga)}
												label='Cidade de Carga'
												cols='10 6 3 3'
												placeholder='Selecione a cidade'
												readOnly={readOnly}
												value={item.id_cidade_carga}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_cidade_carga: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

											<LabelAndCheckbox
												name='origem_frete'
												label='Origem'
												style={{ marginTop: 40 }}
												cols='2 2 1 1'
												readOnly={readOnly}
												value={item.origem_frete}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	origem_frete: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}} />

											<Select
												name='id_estado_descarga'
												options={this.props.listaEstado}
												label='Estado de Descarga'
												cols='12 4 2 2'
												placeholder='Selecione o estado'
												readOnly={readOnly}
												value={item.id_estado_descarga}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_estado_descarga: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

											<Select
												name='id_cidade_descarga'
												options={this.props.listaCidade.filter(values => values.id_estado == item.id_estado_descarga)}
												label='Cidade de Descarga'
												cols='10 6 3 3'
												placeholder='Selecione a cidade'
												readOnly={readOnly}
												value={item.id_cidade_descarga}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_cidade_descarga: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}} />

											<LabelAndCheckbox
												name='destino_frete'
												label='Destino'
												style={{ marginTop: 40 }}
												cols='2 2 1 1'
												readOnly={readOnly}
												value={item.destino_frete}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	destino_frete: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}} />
												
											{!deslocamentoVazio &&
											<Select
												name='id_produto'
												options={this.props.listaProduto}
												label='Produto'
												cols='12 6 3 2'
												placeholder='Selecione o produto'
												readOnly={item.id_produto ? readOnly : null}
												value={item.id_produto}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_produto: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />}

											{!deslocamentoVazio &&
											<Select
												name='id_unidade_medida'
												options={this.props.listaUnidadeMedida}
												label='Unidade de Medida'
												cols='12 6 3 2'
												placeholder='Selecione a unidade de medida'
												readOnly={item.id_unidade_medida ? readOnly : null}
												value={item.id_unidade_medida}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_unidade_medida: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />}
												
											{!deslocamentoVazio &&
											<LabelAndInputNumber
												name='quantidade'
												label='Quantidade' placeholder='Informe a quantidade'
												cols='6 6 2 1'
												readOnly={item.quantidade ? readOnly : null}
												value={String(item.quantidade || '').replace('.', ',')}
												onChange={data => {

													let listaItens = this.props.formularioValues.itens.map(itemAux => {
														if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
															return {
																...itemAux,
																quantidade: (data.target.value || '').replace(',', '.')
															};
														} else {
															return itemAux;
														}
													});

													let valorTarifa = 0;
													listaItens.filter(itemAux => itemAux.ativo).forEach(itemAux => {
														if (itemAux.id_tipo_tarifa == 1) {
															valorTarifa += parseFloat(itemAux.valor);
														} else if (itemAux.id_tipo_tarifa == 2) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														} else if (itemAux.id_tipo_tarifa == 3) {
															valorTarifa += parseFloat(itemAux.valor);
														} else if (itemAux.id_tipo_tarifa == 4) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														} else if (itemAux.id_tipo_tarifa == 5) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														}
													});

													let valorAdiantamento = (valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_adiantamento || 0, 2))) / 100;
													let valorSaldo = ((valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_saldo || 0, 2))) / 100) - parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 2));
													if (valorSaldo + valorAdiantamento != valor_tarifa) {
														valorSaldo += valor_tarifa - (valorSaldo + valorAdiantamento);
													}
													this.props.initForm({
														...this.props.formularioValues,
														itens: listaItens,
														valor_tarifa: valorTarifa.toFixed(2).replace('.', ','),
														valor_tarifa_motorista: valorTarifa.toFixed(2).replace('.', ','),
														valor_adiantamento: FormatUtils.formatarValorTela(valorAdiantamento, 2),
														valor_saldo: FormatUtils.formatarValorTela(valorSaldo, 2)
													});

												}} />}

											{(!idMotorista && !deslocamentoVazio) &&
											<LabelAndInputNumber
												name='valor'
												label='Valor Tarifa (R$)' placeholder='Informe o valor'
												cols='6 6 3 1'
												readOnly={item.valor ? readOnly : null}
												value={String(item.valor || '').replace('.', ',')}
												onChange={data => {

													let listaItens = this.props.formularioValues.itens.map(itemAux => {
														if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
															return {
																...itemAux,
																valor: (data.target.value || '').replace(',', '.')
															};
														} else {
															return itemAux;
														}
													});

													let valorTarifa = 0;
													listaItens.filter(itemAux => itemAux.ativo).forEach(itemAux => {
														if (itemAux.id_tipo_tarifa == 1) {
															valorTarifa += parseFloat(itemAux.valor);
														} else if (itemAux.id_tipo_tarifa == 2) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														} else if (itemAux.id_tipo_tarifa == 3) {
															valorTarifa += parseFloat(itemAux.valor);
														} else if (itemAux.id_tipo_tarifa == 4) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														} else if (itemAux.id_tipo_tarifa == 5) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														}
													});

													this.props.initForm({
														...this.props.formularioValues,
														itens: listaItens,
														valor_tarifa: valorTarifa.toFixed(2).replace('.', ','),
														valor_tarifa_motorista: valorTarifa.toFixed(2).replace('.', ','),
														valor_adiantamento: FormatUtils.formatarValorTela((valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_adiantamento || 0, 2))) / 100, 2),
														valor_saldo: FormatUtils.formatarValorTela(((valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_saldo || 0, 2))) / 100) - parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 2)), 2)
													});
												}} />}
											
											{(!idMotorista && !deslocamentoVazio) &&
											<Select
												name='id_tipo_tarifa'
												options={this.props.listaTarifaTipo}
												label='Tipo'
												cols='12 6 3 2'
												placeholder='Selecione o tipo da  tarifa'
												readOnly={item.id_tipo_tarifa ? readOnly : null}
												value={item.id_tipo_tarifa}
												onChange={data => {

													let listaItens = this.props.formularioValues.itens.map(itemAux => {
														if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
															return {
																...itemAux,
																id_tipo_tarifa: data
															};
														} else {
															return itemAux;
														}
													});

													let valorTarifa = 0;
													listaItens.forEach(itemAux => {
														if (itemAux.id_tipo_tarifa == 1) {
															valorTarifa += parseFloat(itemAux.valor);
														} else if (itemAux.id_tipo_tarifa == 2) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														} else if (itemAux.id_tipo_tarifa == 3) {
															valorTarifa += parseFloat(itemAux.valor);
														} else if (itemAux.id_tipo_tarifa == 4) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														} else if (itemAux.id_tipo_tarifa == 5) {
															valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
														}
													});

													this.props.initForm({
														...this.props.formularioValues,
														itens: listaItens,
														valor_tarifa: valorTarifa.toFixed(2).replace('.', ','),
														valor_tarifa_motorista: valorTarifa.toFixed(2).replace('.', ','),
														valor_adiantamento: FormatUtils.formatarValorTela((valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_adiantamento || 0, 2))) / 100, 2),
														valor_saldo: FormatUtils.formatarValorTela(((valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_saldo || 0, 2))) / 100) - parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 2)), 2)
													});
												}}  />}

											{!deslocamentoVazio &&
											<Select
												name='id_tomador'
												options={this.props.listaTomadorTipo}
												label='Tomador de Frete'
												cols='12 6 3 2'
												placeholder='Selecione o tomador'
												readOnly={item.id_tomador ? readOnly : null}
												value={item.id_tomador}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_tomador: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />}
												
											<Grid cols='6 6 2 2' style={{ paddingTop: 28 }}>
												<Button
													text='Novo Produto'
													type={'primary'}
													icon={'fa fa-chevron-left'}
													disabled={
														readOnly == 'readOnly' 
														|| !item.id_estado_carga || !item.id_cidade_carga
														|| !item.id_estado_descarga || !item.id_cidade_descarga
													}
													event={() => {
														let lista = this.props.formularioValues.itens || [];

														lista.push({
															id_aux: new Date().getTime(),
															id_estado_carga: item.id_estado_carga,
															id_cidade_carga: item.id_cidade_carga,
															id_estado_descarga: item.id_estado_descarga,
															id_cidade_descarga: item.id_cidade_descarga,
															ativo: true
														});
														this.props.initForm({
															...this.props.formularioValues,
															itens: lista
														});
														this.setState({
															...this.state,
															quantidadeItens: lista.filter(itemAux => itemAux.ativo).length
														});
													}} />
											</Grid>

											<LabelAndInput
												name='observacao'
												label='Observação' placeholder='Informe a observação'
												cols='12 12 7 7'
												readOnly={readOnly}
												value={item.observacao}
												onChange={data => {

													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	observacao: data.target.value
																};
															} else {
																return itemAux;
															}
														})
													});

												}} />

											<LabelAndInput
												name='troca_nota'
												label='Troca de Nota' placeholder='Informe o local da troca de nota'
												cols='12 12 4 4'
												readOnly={readOnly}
												value={item.troca_nota}
												onChange={data => {

													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	troca_nota: data.target.value
																};
															} else {
																return itemAux;
															}
														})
													});

												}} />

											<Grid cols='6 6 3 1'>
												<ButtonTable
													type={'danger'}
													icon={'fas fa-trash-alt'}
													visible={item}
													style={{ marginTop: 30 }}
													disabled={readOnly == 'readOnly'}
													event={() => {

														let listaItens = this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																 	ativo: false
																};
															} else {
																return itemAux;
															}
														});
														
														let valorTarifa = 0;
														listaItens.filter(itemAux => itemAux.ativo).forEach(itemAux => {
															if (itemAux.id_tipo_tarifa == 1) {
																valorTarifa += parseFloat(itemAux.valor);
															} else if (itemAux.id_tipo_tarifa == 2) {
																valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
															} else if (itemAux.id_tipo_tarifa == 3) {
																valorTarifa += parseFloat(itemAux.valor);
															} else if (itemAux.id_tipo_tarifa == 4) {
																valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
															} else if (itemAux.id_tipo_tarifa == 5) {
																valorTarifa += parseFloat(itemAux.valor) * parseFloat(itemAux.quantidade);
															}
														});

														this.props.initForm({
															...this.props.formularioValues,
															itens: listaItens,
															valor_tarifa: valorTarifa.toFixed(2).replace('.', ','),
															valor_tarifa_motorista: valorTarifa.toFixed(2).replace('.', ','),
															valor_adiantamento: FormatUtils.formatarValorTela((valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_adiantamento || 0, 2))) / 100, 2),
															valor_saldo: FormatUtils.formatarValorTela(((valorTarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_saldo || 0, 2))) / 100) - parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 2)), 2)
														});
													}} />
											</Grid>
										</Row>
									</ContentCardBody>
								</ContentCard>
							))}

							<Row>
								<Grid cols='6 6 3 2' style={{ paddingBottom: 28 }}>
									<Button
										text='Adicionar'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										disabled={readOnly == 'readOnly'}
										event={() => {
											let lista = this.props.formularioValues.itens || [];
											lista.push({
												id_aux: new Date().getTime(),
												ativo: true
											});
											this.props.initForm({
												...this.props.formularioValues,
												itens: lista
											});
											this.setState({
												...this.state,
												quantidadeItens: lista.filter(itemAux => itemAux.ativo).length
											});
										}} />
								</Grid>
							</Row>

							<Row>
								<Field
									name='id_veiculo'
									component={SelectAlteracao}
									options={this.props.listaVeiculo.filter(item => !item.carreta)}
									label='Veículo'
									cols='12 12 12 12'
									placeholder='Selecione o veículo'
									modoCadastro={this.state.modoCadastroVeiculo}
									acaoBotaoLabel={this.props.formularioValues.id_veiculo && this.props.formularioValues.id_veiculo != 0 ? 'Alterar' : 'Novo'}
									acaoBotao={() => {
										let itemLista = this.props.listaVeiculo.filter(item => item.id == this.props.formularioValues.id_veiculo)[0];

										this.setState({
											...this.state,
											modoCadastroVeiculo: true,
											veiculo: {
												id: this.props.formularioValues.id_veiculo && this.props.formularioValues.id_veiculo != 0 ? this.props.formularioValues.id_veiculo : null,
												placa: itemLista ? itemLista.valor : null,
												cm: itemLista ? itemLista.cm : null
											}
										});
									}}
									componenteCadastro={
										<div class='input-group'>
											<input type='text' className='form-control'
												placeholder='Informe a placa'
												value={this.state.veiculo ? this.state.veiculo.placa : ''}
												onChange={data => {
													this.setState({
														...this.state,
														veiculo: {
															id: this.state.veiculo ? this.state.veiculo.id : null,
															cm: this.state.veiculo ? this.state.veiculo.cm : '',
															placa: data.target.value
														}
													})
												}} />
											<input type='text' className='form-control'
												placeholder='Informe o CM'
												value={this.state.veiculo ? this.state.veiculo.cm : ''}
												onChange={data => {
													this.setState({
														...this.state,
														veiculo: {
															id: this.state.veiculo ? this.state.veiculo.id : null,
															placa: this.state.veiculo ? this.state.veiculo.placa : '',
															cm: data.target.value
														}
													})
												}} />
											<span class='input-group-btn'>
												<button type='button' class='btn btn-success btn-flat'
													onClick={() => {
														this.props.salvarVeiculo(this.state.veiculo, this.props.formularioValues);
														this.setState({
															...this.state,
															modoCadastroVeiculo: false,
															veiculo: null
														});
													}}>
													Salvar
												</button>
											</span>
										</div>
									}
									readOnly={readOnly} />
							</Row>


							<Row>
								<Select
									name='id_rota'
									options={this.props.listaRota.filter(item => {
										//Verifica as cidades da rota
										if (!(this.props.formularioValues.itens || []).filter(ponto => 
											ponto.id_cidade_carga == item.id_cidade_origem 
											|| ponto.id_cidade_descarga == item.id_cidade_destino).length) {
											return false
										}
										
										//Verifica os tipos de veículo
										let veiculo = this.props.listaVeiculo.filter(item => item.id == this.props.formularioValues.id_veiculo)[0];
										if (!veiculo) {
											return false;
										}
									
										if ((item.veiculo_tipos || []).length
											&& !(item.veiculo_tipos || []).includes(veiculo.id_veiculo_tipo)) {
											return false;
										}

										return true;
									})}
									label='Rota'
									cols='12 12 6 4'
									placeholder='Selecione a rota'
									readOnly={readOnly}
									value={this.props.formularioValues.id_rota}
									onChange={value => {

										let rota = this.props.listaRota.filter(item => item.id == value)[0];
										
										this.props.initForm({
											...this.props.formularioValues,
											id_rota: value,
											postos_liberados_novo: (rota ? rota.postos_liberados || [] : [])
										});
										if (value) {
											this.props.carregarRota(value);
										}
									}} />
								{(rota && rota.rota) ? (
									<Grid cols='6 6 3 2' style={{ paddingTop: 28 }}>
										<Button
											text='Gerar PDF'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.gerarRotaPdf(this.props.formularioValues)} />
									</Grid>
								) : null}
								{(rota && rota.rota) ? (
									<Grid cols='6 6 3 2' style={{ paddingTop: 28 }}>
										<Button
											text='Abrir Maps'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => window.open(rota.rota.link_google_maps, '_blank')} />
									</Grid>
								) : null}
							</Row>
							
							<Row>
								<Field
									name='id_motorista'
									component={SelectAlteracao}
									options={this.props.listaMotorista}
									label='Motorista'
									cols='12 12 12 12'
									placeholder='Selecione o motorista'
									modoCadastro={this.state.modoCadastroMotorista}
									acaoBotaoLabel={this.props.formularioValues.id_motorista && this.props.formularioValues.id_motorista != 0 ? 'Alterar' : 'Novo'}
									acaoBotao={() => {
										let itemLista = this.props.listaMotorista.filter(item => item.id == this.props.formularioValues.id_motorista)[0];

										this.setState({
											...this.state,
											modoCadastroMotorista: true,
											motorista: {
												id: this.props.formularioValues.id_motorista && this.props.formularioValues.id_motorista != 0 ? this.props.formularioValues.id_motorista : null,
												nome: itemLista ? itemLista.valor : null
											}
										});
									}}
									componenteCadastro={
										<div class='input-group'>
											<input type='text' className='form-control'
												placeholder='Informe o nome'
												value={this.state.motorista ? this.state.motorista.nome : ''}
												onChange={data => {
													this.setState({
														...this.state,
														motorista: {
															id: this.state.motorista ? this.state.motorista.id : null,
															nome: data.target.value
														}
													})
												}} />
											<span class='input-group-btn'>
												<button type='button' class='btn btn-success btn-flat'
													onClick={() => {
														this.props.salvarMotorista(this.state.motorista, this.props.formularioValues);
														this.setState({
															...this.state,
															modoCadastroMotorista: false,
															motorista: null
														});
													}}>
													Salvar
												</button>
											</span>
										</div>
									}
									readOnly={readOnly} />
							</Row>

							{!idMotorista &&
							<Row>
								<Field
									name='id_transportadora'
									component={SelectAlteracao}
									options={this.props.listaTransportadora}
									label='Transportadora'
									cols='12 12 12 12'
									placeholder='Selecione a transportadora'
									modoCadastro={this.state.modoCadastroTransportadora}
									acaoBotaoLabel={this.props.formularioValues.id_transportadora && this.props.formularioValues.id_transportadora != 0 ? 'Alterar' : 'Novo'}
									acaoBotao={() => {
										let itemLista = this.props.listaTransportadora.filter(item => item.id == this.props.formularioValues.id_transportadora)[0];

										this.setState({
											...this.state,
											modoCadastroTransportadora: true,
											transportadora: {
												id: this.props.formularioValues.id_transportadora && this.props.formularioValues.id_transportadora != 0 ? this.props.formularioValues.id_transportadora : null,
												nome: itemLista ? itemLista.valor : null
											}
										});
									}}
									componenteCadastro={
										<div class='input-group'>
											<input type='text' className='form-control'
												placeholder='Informe o nome'
												value={this.state.transportadora ? this.state.transportadora.nome : ''}
												onChange={data => {
													this.setState({
														...this.state,
														transportadora: {
															id: this.state.transportadora ? this.state.transportadora.id : null,
															nome: data.target.value
														}
													})
												}} />
											<span class='input-group-btn'>
												<button type='button' class='btn btn-success btn-flat'
													onClick={() => {
														this.props.salvarTransportadora(this.state.transportadora, this.props.formularioValues);
														this.setState({
															...this.state,
															modoCadastroTransportadora: false,
															transportadora: null
														});
													}}>
													Salvar
												</button>
											</span>
										</div>
									}
									readOnly={readOnly} />
							</Row>}
{/*
							<Row>
								<Field
									name='id_produto'
									component={SelectAlteracao}
									options={this.props.listaProduto}
									label='Produto'
									cols='12 12 12 12'
									placeholder='Selecione o produto'
									modoCadastro={this.state.modoCadastroProduto}
									acaoBotaoLabel={this.props.formularioValues.id_produto && this.props.formularioValues.id_produto != 0 ? 'Alterar' : 'Novo'}
									acaoBotao={() => {
										let itemLista = this.props.listaProduto.filter(item => item.id == this.props.formularioValues.id_produto)[0];

										this.setState({
											...this.state,
											modoCadastroProduto: true,
											produto: {
												id: this.props.formularioValues.id_produto && this.props.formularioValues.id_produto != 0 ? this.props.formularioValues.id_produto : null,
												nome: itemLista ? itemLista.valor : null
											}
										});
									}}
									componenteCadastro={
										<div class='input-group'>
											<input type='text' className='form-control'
												placeholder='Informe o nome'
												value={this.state.produto ? this.state.produto.nome : ''}
												onChange={data => {
													this.setState({
														...this.state,
														produto: {
															id: this.state.produto ? this.state.produto.id : null,
															nome: data.target.value
														}
													})
												}} />
											<span class='input-group-btn'>
												<button type='button' class='btn btn-success btn-flat'
													onClick={() => {
														this.props.salvarProduto(this.state.produto, this.props.formularioValues);
														this.setState({
															...this.state,
															modoCadastroProduto: false,
															produto: null
														});
													}}>
													Salvar
												</button>
											</span>
										</div>
									}
									readOnly={readOnly} />
							</Row>

							<Row>
								<Field
									name='id_unidade_medida'
									component={SelectAlteracao}
									options={this.props.listaUnidadeMedida}
									label='Unidade de Medida'
									cols='12 12 12 12'
									placeholder='Selecione a unidade de medida'
									modoCadastro={this.state.modoCadastroUnidadeMedida}
									acaoBotaoLabel={this.props.formularioValues.id_unidade_medida && this.props.formularioValues.id_unidade_medida != 0 ? 'Alterar' : 'Novo'}
									acaoBotao={() => {
										let itemLista = this.props.listaUnidadeMedida.filter(item => item.id == this.props.formularioValues.id_unidade_medida)[0];

										this.setState({
											...this.state,
											modoCadastroUnidadeMedida: true,
											unidadeMedida: {
												id: this.props.formularioValues.id_unidade_medida && this.props.formularioValues.id_unidade_medida != 0 ? this.props.formularioValues.id_unidade_medida : null,
												nome: itemLista ? itemLista.valor : null
											}
										});
									}}
									componenteCadastro={
										<div class='input-group'>
											<input type='text' className='form-control'
												placeholder='Informe o nome'
												value={this.state.unidadeMedida ? this.state.unidadeMedida.nome : ''}
												onChange={data => {
													this.setState({
														...this.state,
														unidadeMedida: {
															id: this.state.unidadeMedida ? this.state.unidadeMedida.id : null,
															nome: data.target.value
														}
													})
												}} />
											<span class='input-group-btn'>
												<button type='button' class='btn btn-success btn-flat'
													onClick={() => {
														this.props.salvarUnidadeMedida(this.state.unidadeMedida, this.props.formularioValues);
														this.setState({
															...this.state,
															modoCadastroUnidadeMedida: false,
															unidadeMedida: null
														});
													}}>
													Salvar
												</button>
											</span>
										</div>
									}
									readOnly={readOnly} />
							</Row>

							<Row>
								<Field
									name='quantidade'
									component={LabelAndInputNumber}
									label='Quantidade' placeholder='Informe o quantidade'
									cols='12 12 6 4'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='valor_tarifa'
									component={LabelAndInputNumber}
									label='Valor Tarifa (R$)' placeholder='Informe o valor'
									cols='6 6 4 4'
									readOnly={readOnly} />
								<Field
									name='id_tipo_tarifa'
									component={Select}
									options={this.props.listaTarifaTipo}
									label='Tipo'
									cols='6 6 4 4'
									placeholder='Selecione o tipo'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='id_tomador'
									component={Select}
									options={this.props.listaTomadorTipo}
									label='Tomador de Frete '
									cols='6 6 4 4'
									placeholder='Selecione o tomador'
									readOnly={readOnly} />
							</Row>

							*/}

							{(!idMotorista && !deslocamentoVazio) &&
							<Row>
								<Field
									name='valor_tarifa'
									component={LabelAndInputNumber}
									label='Valor Tarifa (R$)' placeholder='Informe o valor'
									cols='6 6 4 4'
									readOnly={'readOnly'} />
									
								<LabelAndInputNumber
									component={LabelAndInputNumber}
									label='Valor Tarifa Com Desconto mais Pedágio (R$)' placeholder='Informe o valor'
									cols='6 6 4 4'
									readOnly={'readOnly'}
									value={FormatUtils.formatarValorTelaDecimal(
										parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_tarifa, 4))
										- parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 4))
										+ (
											this.props.formularioValues.id_tipo_pedagio == 5 ? 0 //Incluso
											: parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_pedagio, 4))
										), 2)}
									onChange={() => {}} />
							</Row>}
								
							{(!idMotorista && !deslocamentoVazio) &&
							<Row>
								<LabelAndInputNumber
									label='Forma de Pgto do Adiantamento (%)' placeholder='Informe o percentual'
									cols='4 4 3 2'
									readOnly={'readOnly'}
									casas={2}
									value={this.props.formularioValues.percentual_adiantamento}
									onChange={data => {										
										let percentual = parseFloat(FormatUtils.formatarValorBanco(data.target.value, 2));
										this.props.initForm({
											...this.props.formularioValues,
											percentual_adiantamento: data.target.value,
											valor_adiantamento: FormatUtils.formatarValorTela((valor_tarifa * percentual) / 100, 2),
											percentual_saldo: FormatUtils.formatarValorTela(100 - percentual, 2),
											valor_saldo: FormatUtils.formatarValorTela(((valor_tarifa * (100 - percentual)) / 100) - parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 2)), 2)
										});
									}} />

								<Field
									name='valor_adiantamento'
									component={LabelAndInputNumber}
									label='Valor Adiantamento' placeholder='Informe o valor'
									cols='4 4 3 2'
									readOnly={null/*readOnly*/}
									casas={2}
									value={this.props.formularioValues.percentual_adiantamento}
									onChange={data => {										
										let valorAdiantamento = parseFloat(FormatUtils.formatarValorBanco(data.target.value, 2));
										let percentual = ((valorAdiantamento * 100) / valor_tarifa).toFixed(2);
										let valorSaldo = ((valor_tarifa * (100 - percentual)) / 100) - parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 2));
										if (valorSaldo + valorAdiantamento != valor_tarifa) {
											valorSaldo += valor_tarifa - (valorSaldo + valorAdiantamento);
										}
										this.props.initForm({
											...this.props.formularioValues,
											percentual_adiantamento: FormatUtils.formatarValorTela(percentual, 2),
											valor_adiantamento: FormatUtils.formatarValorTela(valorAdiantamento, 2),
											percentual_saldo: FormatUtils.formatarValorTela(100 - percentual, 2),
											valor_saldo: FormatUtils.formatarValorTela(valorSaldo, 2)
										});
									}} />

								<Field
									name='id_tipo_adiantamento'
									component={Select}
									options={this.props.listaAdiantamentoTipo}
									label='Pagamento de adiantamento feito para'
									cols='4 4 6 5'
									placeholder='Selecione o tipo'
									readOnly={null/*readOnly*/} />
							</Row>}
							{(!idMotorista && !deslocamentoVazio) &&
							<Row>
								<LabelAndInputNumber
									label='Forma de Pgto do Saldo (%)' placeholder='Informe o percentual'
									cols='4 4 3 2'
									readOnly={null/*readOnly*/}
									casas={2}
									value={this.props.formularioValues.percentual_saldo}
									onChange={data => {										
										let percentual = parseFloat(FormatUtils.formatarValorBanco(data.target.value, 2));
										let valorAdiantamento = (valor_tarifa * (100 - percentual)) / 100;
										let valorSaldo = ((valor_tarifa * percentual) / 100) - parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_desconto_saldo, 2));
										if (valorSaldo + valorAdiantamento != valor_tarifa) {
											valorSaldo += valor_tarifa - (valorSaldo + valorAdiantamento);
										}

										this.props.initForm({
											...this.props.formularioValues,
											percentual_adiantamento: FormatUtils.formatarValorTela(100 - percentual, 2),
											valor_adiantamento: FormatUtils.formatarValorTela(valorAdiantamento, 2),
											percentual_saldo: data.target.value,
											valor_saldo: FormatUtils.formatarValorTela(valorSaldo, 2)
										});
									}} />
								
								<LabelAndInputNumber
									label='Desconto' placeholder='Informe o desconto'
									cols='4 4 3 2'
									readOnly={null/*readOnly*/}
									casas={2}
									value={this.props.formularioValues.valor_desconto_saldo}
									onChange={data => {										

										let valorAdiantamento = parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor_adiantamento, 2));
										let valorSaldo = (valor_tarifa * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.percentual_saldo, 2))) / 100;
										if (valorSaldo + valorAdiantamento != valor_tarifa) {
											valorSaldo += valor_tarifa - (valorSaldo + valorAdiantamento);
										}

										this.props.initForm({
											...this.props.formularioValues,
											valor_desconto_saldo: data.target.value,
											valor_saldo: FormatUtils.formatarValorTela(valorSaldo - parseFloat(FormatUtils.formatarValorBanco(data.target.value, 2)), 2)
										});
									}} />

								<Field
									name='valor_saldo'
									component={LabelAndInputNumber}
									label='Valor Saldo' placeholder='Informe o valor'
									cols='4 4 3 2'
									readOnly={'readOnly'}
									casas={2} />

								<Field
									name='id_tipo_saldo'
									component={Select}
									options={this.props.listaSaldoTipo}
									label='Pagamento de saldo feito para'
									cols='4 4 3 3'
									placeholder='Selecione o tipo'
									readOnly={null/*readOnly*/} />
							</Row>}
							{(!idMotorista && !deslocamentoVazio) &&
							<Row>
								<Field
									name='valor_pedagio'
									component={LabelAndInputNumber}
									label='Valor Pedágio (R$)' placeholder='Informe o valor'
									cols='6 6 4 4'
									readOnly={readOnly} />
								<Field
									name='id_tipo_pedagio'
									component={Select}
									options={this.props.listaPedagioTipo}
									label='Tipo'
									cols='6 6 4 4'
									placeholder='Selecione o tipo'
									readOnly={readOnly} />
							</Row>}
							{(!idMotorista && !deslocamentoVazio) &&
							<Row>
								<Field
									name='valor_descarga'
									component={LabelAndInputNumber}
									label='Valor Descarga (R$)' placeholder='Informe o valor'
									cols='6 6 4 4'
									readOnly={readOnly} />
								<Field
									name='id_tipo_descarga'
									component={Select}
									options={this.props.listaDescargaTipo}
									label='Tipo'
									cols='6 6 4 4'
									placeholder='Selecione o tipo'
									readOnly={readOnly} />
							</Row>}

							{(!idMotorista && !deslocamentoVazio) &&
							<Row>
								<Field
									name='contato'
									component={LabelAndInput}
									label='Contato' placeholder='Informe o contato'
									cols='8 9 4 4'
									readOnly={readOnly} />

								<Grid cols='4 3 2 2'>
									<span class='input-group-append' style={{ marginTop: 31 }}>
										{this.props.formularioValues.contato ? (
											<button class='btn btn-primary' type={'button'}
												onClick={() => {
													let telefone = String(this.props.formularioValues.contato).replace('55', '').replace(/[^0-9]/g, '');
													if (telefone.length <= 8) {
														telefone = `55${telefone}`;
													}
													window.open(`https://api.whatsapp.com/send?phone=55${telefone}`);
												}}>WhatsApp</button>
										) : (
											<button class='btn btn-primary' disabled>WhatsApp</button>
										)}
									</span>
								</Grid>
							</Row>}
							{!idMotorista &&
							<Row>
								<Field
									name='documentacao'
									component={LabelAndCheckbox}
									label='Documentação'
									cols='12 12 4 4'
									placeholder='Selecione se possui documentação'
									readOnly={readOnly} />
								
								<Field
									name='rota_abastecimento'
									component={LabelAndCheckbox}
									label='Rota Abastecimento'
									cols='12 12 4 4'
									placeholder='Selecione se enviou rota e abastecimento para o motorista'
									readOnly={readOnly} />
							</Row>}
							{!idMotorista &&							
							<Row>
								<Field
									name='responsavel_agendamento'
									component={LabelAndInput}
									label='Agendado por' placeholder='Informe o responsável'
									cols='12 12 6 4'
									readOnly={readOnly} />

								{!deslocamentoVazio &&
								<Field
									name='responsavel_descarga'
									component={LabelAndInput}
									label='Descarga por' placeholder='Informe o responsável'
									cols='12 12 6 4'
									readOnly={readOnly} />}

								{!deslocamentoVazio &&
								<Field
									name='responsavel_pedagio'
									component={LabelAndInput}
									label='Pedágio por' placeholder='Informe o responsável'
									cols='12 12 6 4'
									readOnly={readOnly} />}
							</Row>}
							{!idMotorista &&
							<Row>
								<Field
									name='observacao'
									component={LabelAndInput}
									label='Observação' placeholder='Informe a observação'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>}

							{!deslocamentoVazio &&
							<Row>
								<Field
									name='monitorado_gr'
									component={LabelAndCheckbox}
									label='Monitorado por GR' placeholder='Informe se é monitorado por GR'
									cols='12 12 3 2'
									readOnly={readOnly} />

								<Field
									name='comercial'
									component={LabelAndInput}
									label='Comercial' placeholder='Informe o comercial'
									cols='12 12 9 8'
									readOnly={readOnly} />
							</Row>}

							{(!idMotorista && !deslocamentoVazio) &&
							<Row>
								<Grid cols="4 4 4 4">
									<label> Carregado</label>
									<Row>
										<Field
											name='valor_tarifa_motorista'
											component={LabelAndInputNumber}
											label='Tarifa do Motorista' placeholder='Informe a tarifa'
											cols='6 6 6 6'
											casas={2}
											readOnly={null/*readOnly*/} />

										<Field
											name='id_tipo_tarifa'
											component={Select}
											options={this.props.listaTarifaTipo}
											label='Tipo'
											cols='6 6 6 6'
											placeholder='Selecione o tipo'
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='porcentagem_comissao_moto'
											component={LabelAndInputNumber}
											label='Comissão para motorista (%)' placeholder='Informe a comissão'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='qtde_km'
											component={LabelAndInputNumber}
											label='Quantidade KM' placeholder='Informe a quantidade de KM'
											cols='12 12 12 12'
											casas={0}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='media'
											component={LabelAndInputNumber}
											label='Média de consumo' placeholder='Informe a média'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='preco_combustivel'
											component={LabelAndInputNumber}
											label='Preço combustível' placeholder='Informe o preço do combustível'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='carregado_pedagio'
											component={LabelAndInputNumber}
											label='Pedágio Carregado' placeholder='Informe o pedágio carregado'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='seguro_carga'
											component={LabelAndInputNumber}
											label='Agenciamento/Comercial' placeholder='Informe o agenciamento/comercial'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='outros_custos'
											component={LabelAndInputNumber}
											label='Outros custos' placeholder='Informe outros custos'
											cols='6 6 6 6'
											casas={2}
											readOnly={null/*readOnly*/} />

										<Field
											name='observacao_outros_custos'
											component={LabelAndInput}
											label='Observação' placeholder='Informe a observação'
											cols='6 6 6 6'
											readOnly={null/*readOnly*/} />
									</Row>
								</Grid>

								<Grid cols='4 4 4 4'>
									<label>Deslocamento Vazio</label>
									<Row>
										<Field
											name='deslocamento_origem_destino'
											component={LabelAndInput}
											label='Origem/Destino do deslocamento' placeholder='Informe a origem/destino do deslocamento'
											cols='12 12 12 12'

											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='deslocamento_qtde_km'
											component={LabelAndInputNumber}
											label='Quantidade de KM do deslocamento' placeholder='Informe a quantidade de KM do deslocamento'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='deslocamento_media'
											component={LabelAndInputNumber}
											label='Média do deslocamento' placeholder='Informe  a média do deslocamento'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='deslocamento_preco_combustivel'
											component={LabelAndInputNumber}
											label='Preço do combustível do deslocamento ' placeholder='Informe o preço do combustível do deslocamento'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='deslocamento_pedagio'
											component={LabelAndInputNumber}
											label='Pedágio do deslocamento' placeholder='Informe  o pedágio do deslocamento'
											cols='12 12 12 12'
											casas={2}
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='diaria_empresa'
											component={LabelAndInputNumber}
											label='Diária Empresa' placeholder='Informe o valor'
											cols='6 6 6 6'
											readOnly={null/*readOnly*/}
											casas={2} />

										<Field
											name='id_diaria_tipo'
											component={Select}
											options={this.props.listaDiariaTipo}
											label='Forma Pagamento'
											cols='6 6 6 6'
											placeholder='Selecione a forma de pagamento'
											readOnly={null/*readOnly*/} />
									</Row>
									<Row>
										<Field
											name='diaria_motorista'
											component={LabelAndInputNumber}
											label='Diária Motorista' placeholder='Informe o valor'
											cols='6 6 6 6'
											readOnly={null/*readOnly*/}
											casas={2} />
									</Row>
									<Row>
										<LabelAndInputNumber
											label='Diária Total' placeholder='Informe o valor'
											cols='6 6 6 6'
											readOnly={null/*readOnly*/}
											casas={2}
											value={diaria_total}
											onChange={() => {}} />

										<Field
											name='id_diaria_paga'
											component={Select}
											options={this.props.listaSimNao}
											label='Diária está Paga?'
											cols='6 6 6 6'
											placeholder='Selecione'
											readOnly={null/*readOnly*/} />
									</Row>
								</Grid>

								<Grid cols='4 4 4 4'>

									<Table responsive>
										<p style={{ fontSize: 18, color: '#1C8403' }}> Extrato do Frete </p>
										<TBody>
											<Tr>
												<Td style={{ fontSize: 16 }}> Valor total do frete</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(valorTotalFrete, 2)} </Td>
											</Tr>
											<Tr>
												<Td style={{ fontSize: 16 }}> Base cálculo frete motorista</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(valorBaseCalculoFreteMotorista, 2)} </Td>
											</Tr>
											<Tr>
												<Td style={{ fontSize: 16 }}> Comissão p/ motorista</Td>
												<Td alignRight style={{ fontSize: 16 }}> R$ {FormatUtils.formatarValorTelaDecimal(comissaoMotorista, 2)} </Td>
											</Tr>
											<Tr>
												<Td style={{ fontSize: 16 }}> Dias de viagem</Td>
												<Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(diasViagem, 2)}</Td>
											</Tr>
											<Tr>
												<Td style={{ fontSize: 16 }}> Custo combustível deslocamento</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(custoCombustivelDesloca, 2)}</Td>
											</Tr>
											<Tr>
												<Td style={{ fontSize: 16 }}> Custo total deslocamento</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(custoTotalDeslocamento, 2)}</Td>
											</Tr>
											<Tr>
												<Td style={{ fontSize: 16 }}> Custo combustível carregado</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(custoCombustivelCarregado, 2)}</Td>
											</Tr>
											<Tr>
												<Td style={{ fontSize: 16 }}> Custo total frete</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(custoTotalFrete, 2)}</Td>
											</Tr>
											<Tr backgroundColor='#1C8403' color='#fff'>
												<Td style={{ fontSize: 16 }}> Lucro Líquido</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(lucroLiquido, 2)}</Td>
											</Tr>
											<Tr backgroundColor='#1C8403' color='#fff'>
												<Td style={{ fontSize: 16 }}> Lucro KM total</Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(lucroKmTotal, 2)}</Td>
											</Tr>
											<Tr backgroundColor='#1C8403' color='#fff'>
												<Td style={{ fontSize: 16 }}> Valor KM frete </Td>
												<Td alignRight style={{ fontSize: 16 }}>R$ {FormatUtils.formatarValorTelaDecimal(valorKmFrete, 2)}</Td>
											</Tr>

										</TBody>
									</Table>
								</Grid>
							</Row>}
											
							{linkRota ? (
								<iframe
								width="100%"
								height="1000"
								frameborder="0" style={{ border: 0 }}
								referrerpolicy="no-referrer-when-downgrade"
								src={linkRota}
								allowfullscreen>
								</iframe>
							) : null}
							<Row>
								<Grid cols='12 12 12 12'>
									{rota && rota.rota ? (
										<Table responsive>
											<THead>
												<Tr>
													<Th width={300}>Postos</Th>
													<Th width={100} alignCenter>Rede</Th>
													<Th width={100} alignCenter>Cidade</Th>
													<Th width={100} alignCenter>Forma de Pagamento</Th>
													<Th width={100} alignCenter>Preço Diesel</Th>
													<Th width={100} alignCenter>Preço Arla</Th>
													<Th width={100} alignCenter>Qtd Diesel</Th>
													<Th width={100} alignCenter>Liberado</Th>
												</Tr>
											</THead>
											<TBody>
												{(rota.rota.pontos || []).map(posto => {
													
													let postoCarregado = this.props.listaPosto.filter(item => item.id == posto.id)[0] || posto;

													let postoLiberado = null;
													try {
														postoLiberado = (this.props.formularioValues.postos_liberados_novo || []).filter(pstLiberado => pstLiberado.id == posto.id)[0];
													} catch(ex) {
														
													}

													if (postoLiberado) {
														return (
															<Tr key={posto.id} >
																<Td>{postoCarregado.descricao}</Td>
																<Td alignCenter>{postoCarregado.nome_rede}</Td>
																<Td alignCenter>{postoCarregado.nome_cidade}</Td>
																<Td alignCenter>{postoCarregado.nome_forma_pagamento}</Td>
																<Td alignCenter>R$ {(postoCarregado.preco_diesel || '0.00').replace('.', ',')}</Td>
																<Td alignCenter>R$ {(postoCarregado.preco_arla || '0.00').replace('.', ',')}</Td>
																<Td alignCenter>{postoLiberado.quantidade_diesel || 0} litros</Td>
																<Td alignCenter minWidth={100}>
																	<label htmlFor={'posto'}>
																		<input
																			type='checkbox'
																			checked={true}
																			readOnly={'readOnly'}
																			onChange={data => {
																				
																			}} />
																	</label>
																</Td>
															</Tr>
														);
													} else {
														return null;
													}
												})}
											</TBody>
										</Table>
									) : null}
								</Grid>
							</Row>

							</ContentCardBody>
						{!idMotorista &&
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
										<Button
											text='Salvar'
											submit
											type={'success'}
											disabled={false}
											icon={'fa fa-check'} />
									)}
								</Grid>
								{!this.props.excluir ? (
									<Grid cols='6 4 4 3'>
										<Button
											text='Excluir'
											type={'danger'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('exclusao', this.props.registro)} />
									</Grid>
								) : null}
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.visualizacaoViagem ? this.props.setModoTelaLancamentoViagem('cadastro', this.props.registroLancamentoViagem) : this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>}
					</Form>
				</ContentCard>
			</>
		)
	}
}

ViagemForm = reduxForm({ form: 'viagemForm', destroyOnUnmount: false })(ViagemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('viagemForm')(state),
	registro: state.viagem.registro,
	registroLancamentoViagem: state.viagemLancamento.registro,
	listaViagemLancamento: state.viagem.listaViagemLancamento,
	listaLocal: state.viagem.listaLocal,
	listaEstado: state.viagem.listaEstado,
	listaCidade: state.viagem.listaCidade,
	listaViagemStatus: state.viagem.listaViagemStatus,
	listaVeiculo: state.viagem.listaVeiculo,
	listaMotorista: state.viagem.listaMotorista,
	listaTransportadora: state.viagem.listaTransportadora,
	listaProduto: state.viagem.listaProduto,
	listaUnidadeMedida: state.viagem.listaUnidadeMedida,
	listaTarifaTipo: state.viagem.listaTarifaTipo,
	listaTomadorTipo: state.viagem.listaTomadorTipo,
	listaAdiantamentoTipo: state.viagem.listaAdiantamentoTipo,
	listaSaldoTipo: state.viagem.listaSaldoTipo,
	listaPedagioTipo: state.viagem.listaPedagioTipo,
	listaDescargaTipo: state.viagem.listaDescargaTipo,
	listaProximaCarga: state.viagem.listaProximaCarga,
	listaPosto: state.viagem.listaPosto,
	listaRota: state.viagem.listaRota,
	listaDiariaTipo: state.viagem.listaDiariaTipo,
	listaSimNao: state.viagem.listaSimNao
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela,
	initForm,
	carregarRota,
	getListaViagemLancamento,
	getListaEstado,
	getListaCidade,
	getListaViagemStatus,
	getListaLocal, salvarLocal,
	getListaVeiculo, salvarVeiculo,
	getListaMotorista, salvarMotorista,
	getListaTransportadora, salvarTransportadora,
	getListaProduto, salvarProduto,
	getListaProximaCarga,
	getListaUnidadeMedida, salvarUnidadeMedida,
	gerarRotaPdf,
	setModoTelaLancamentoViagem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViagemForm);
