import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import confirmDialog from '../common/dialog/confirmDialog';
import { getDistance } from 'geolib';

export function setModoTela(modo, registro = {}) {

	return async (dispatch, getState) => {
		
		if (registro.id_rota) {
			dispatch(carregarRota(registro.id_rota));
		}
		
		dispatch({
			type: 'VIAGEM_MODO_TELA',
			payload: {
				modo: modo,
				registro: registro
			}
		});
    }
}

export function setAguardando(aguardando) {
    return {
        type: 'VIAGEM_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {

    return [
        initialize('viagemForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/viagem/listarDashboard?viagem=true`);
    return {
        type: 'VIAGEM_LISTADO',
        payload: request
    };
}

export function getListaLancamento(viagemLancamento) {

	return async (dispatch, getState) => {
		await axios.get(`${consts.API_URL}/viagemLancamento`).then(resp => {

			let registro = null;

			if (viagemLancamento) {
				registro = resp.data.filter(item => item.id == viagemLancamento.id)[0];
			}

			dispatch({
				type: 'VIAGEM_LANCAMENTO_ATUALIZACAO_LISTADO',
				payload: {
					lista: resp.data,
					registro: registro
				}
			});
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {

		dispatch(setAguardando(true));

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/viagem`, {
				...registro,
				datahora_agendamento: registro.datahora_agendamento ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_agendamento) : null,
				datahora_carga: registro.datahora_carga ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_carga) : null,
				datahora_descarga: registro.datahora_descarga ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_descarga) : null,
				quantidade: String(registro.quantidade || 0).split(".").join("").replace(",", "."),
				preco_unitario: String(registro.preco_unitario || 0).split(".").join("").replace(",", "."),
				valor_tarifa: String(registro.valor_tarifa || 0).split(".").join("").replace(",", "."),
				tomador_frete: String(registro.tomador_frete || 0).split(".").join("").replace(",", "."),
				percentual_adiantamento: String(registro.percentual_adiantamento || 0).split(".").join("").replace(",", "."),
				percentual_saldo: String(registro.percentual_saldo || 0).split(".").join("").replace(",", "."),
				valor_adiantamento: String(registro.valor_adiantamento || 0).split(".").join("").replace(",", "."),
				valor_saldo: String(registro.valor_saldo || 0).split(".").join("").replace(",", "."),
				valor_pedagio: String(registro.valor_pedagio || 0).split(".").join("").replace(",", "."),
				valor_descarga: String(registro.valor_descarga || 0).split(".").join("").replace(",", "."),
				porcentagem_comissao_moto: String(registro.porcentagem_comissao_moto || 0).split(".").join("").replace(",", "."),
				qtde_km: String(registro.qtde_km || 0).split(".").join("").replace(",", "."),
				media: String(registro.media || 0).split(".").join("").replace(",", "."),
				preco_combustivel: String(registro.preco_combustivel || 0).split(".").join("").replace(",", "."),
				seguro_carga: String(registro.seguro_carga || 0).split(".").join("").replace(",", "."),
				outros_custos: String(registro.outros_custos || 0).split(".").join("").replace(",", "."),
				deslocamento_origem_destino: String(registro.deslocamento_origem_destino || 0).split(".").join("").replace(",", "."),
				deslocamento_qtde_km: String(registro.deslocamento_qtde_km || 0).split(".").join("").replace(",", "."),
				deslocamento_media: String(registro.deslocamento_media || 0).split(".").join("").replace(",", "."),
				deslocamento_preco_combustivel: String(registro.deslocamento_preco_combustivel || 0).split(".").join("").replace(",", "."),
				deslocamento_pedagio: String(registro.deslocamento_pedagio || 0).split(".").join("").replace(",", "."),
				carregado_pedagio: String(registro.carregado_pedagio || 0).split(".").join("").replace(",", "."),
				valor_tarifa_motorista: String(registro.valor_tarifa_motorista || 0).split(".").join("").replace(",", "."),
				diaria_empresa: String(registro.diaria_empresa || 0).split(".").join("").replace(",", "."),
				diaria_motorista: String(registro.diaria_motorista || 0).split(".").join("").replace(",", ".")
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/viagem`, {
				...registro,
				datahora_agendamento: registro.datahora_agendamento ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_agendamento) : null,
				datahora_carga: registro.datahora_carga ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_carga) : null,
				datahora_descarga: registro.datahora_descarga ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_descarga) : null,
				quantidade: String(registro.quantidade || 0).split(".").join("").replace(",", "."),
				preco_unitario: String(registro.preco_unitario || 0).split(".").join("").replace(",", "."),
				valor_tarifa: String(registro.valor_tarifa || 0).split(".").join("").replace(",", "."),
				tomador_frete: String(registro.tomador_frete || 0).split(".").join("").replace(",", "."),
				percentual_adiantamento: String(registro.percentual_adiantamento || 0).split(".").join("").replace(",", "."),
				percentual_saldo: String(registro.percentual_saldo || 0).split(".").join("").replace(",", "."),
				valor_adiantamento: String(registro.valor_adiantamento || 0).split(".").join("").replace(",", "."),
				valor_saldo: String(registro.valor_saldo || 0).split(".").join("").replace(",", "."),
				valor_pedagio: String(registro.valor_pedagio || 0).split(".").join("").replace(",", "."),
				valor_descarga: String(registro.valor_descarga || 0).split(".").join("").replace(",", "."),
				porcentagem_comissao_moto: String(registro.porcentagem_comissao_moto || 0).split(".").join("").replace(",", "."),
				qtde_km: String(registro.qtde_km || 0).split(".").join("").replace(",", "."),
				media: String(registro.media || 0).split(".").join("").replace(",", "."),
				preco_combustivel: String(registro.preco_combustivel || 0).split(".").join("").replace(",", "."),
				seguro_carga: String(registro.seguro_carga || 0).split(".").join("").replace(",", "."),
				outros_custos: String(registro.outros_custos || 0).split(".").join("").replace(",", "."),
				deslocamento_origem_destino: registro.deslocamento_origem_destino,
				deslocamento_qtde_km: String(registro.deslocamento_qtde_km || 0).split(".").join("").replace(",", "."),
				deslocamento_media: String(registro.deslocamento_media || 0).split(".").join("").replace(",", "."),
				deslocamento_preco_combustivel: String(registro.deslocamento_preco_combustivel || 0).split(".").join("").replace(",", "."),
				deslocamento_pedagio: String(registro.deslocamento_pedagio || 0).split(".").join("").replace(",", "."),
				carregado_pedagio: String(registro.carregado_pedagio || 0).split(".").join("").replace(",", "."),
				valor_tarifa_motorista: String(registro.valor_tarifa_motorista || 0).split(".").join("").replace(",", "."),
				diaria_empresa: String(registro.diaria_empresa || 0).split(".").join("").replace(",", "."),
				diaria_motorista: String(registro.diaria_motorista || 0).split(".").join("").replace(",", ".")
			}).then(resp => {
				//Se finalizou a viagem e possui viagem agendada ALTERA a agendada pra EM TRÂNSITO
				// if (registro.viagem_agendada && registro.id_status == 6) { //6-Finalizada
				// 	axios.put(`${consts.API_URL}/viagem`, {
				// 		...registro.viagem_agendada,
				// 		id_status: 3 //3-Transito
				// 	}).then(resp => {
				// 	}).catch(e => {
				// 		setErroAPI(e);
				// 	});
				// }

				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaLancamento(getState().viagemLancamento.registro));
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {

		dispatch(setAguardando(true));

        axios.delete(`${consts.API_URL}/viagem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function getListaViagemLancamento() {
    const request = axios.get(`${consts.API_URL}/viagemLancamento/listarSelect`);
    return {
        type: 'VIAGEM_LANCAMENTO_SELECT_LISTADO',
        payload: request
    };
}

//Local
export function getListaLocal() {
    const request = axios.get(`${consts.API_URL}/local/listarSelect`);
    return {
        type: 'VIAGEM_LOCAL_SELECT_LISTADO',
        payload: request
    };
}

export function salvarLocal(registro, formulario) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/local`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaLocal());
				if (!registro.id) {
					dispatch(initForm({
						...formulario,
						id_local_carga: resp.data.id
					}));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/local`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaLocal());
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Estado
export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarSelect`);
    return {
        type: 'VIAGEM_ESTADO_SELECT_LISTADO',
        payload: request
    };
}

//Cidade
export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'VIAGEM_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

//Viagem Status
export function getListaViagemStatus() {
    const request = axios.get(`${consts.API_URL}/viagemStatus/listarSelect`);
    return {
        type: 'VIAGEM_VIAGEM_STATUS_SELECT_LISTADO',
        payload: request
    };
}

//Veículo
export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'VIAGEM_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function salvarVeiculo(registro, formulario) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/veiculo`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaVeiculo());
				if (!registro.id) {
					dispatch(initForm({
						...formulario,
						id_veiculo: resp.data.id
					}));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/veiculo`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaVeiculo());
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirVeiculo(registro) {

	return (dispatch, getState) => {
		confirmDialog('Deseja realmente excluir o veículo?', () => {
	        axios.delete(`${consts.API_URL}/veiculo?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
			}).catch(e => {
				setErroAPI(e);
			});

	    });
	};
}

//Motorista
export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'VIAGEM_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarMotorista(registro, formulario) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/motorista`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaMotorista());
				if (!registro.id) {
					dispatch(initForm({
						...formulario,
						id_motorista: resp.data.id
					}));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/motorista`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaMotorista());
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Transportadora
export function getListaTransportadora() {
    const request = axios.get(`${consts.API_URL}/transportadora/listarSelect`);
    return {
        type: 'VIAGEM_TRANSPORTADORA_SELECT_LISTADO',
        payload: request
    };
}

//Próxima Carga
export function getListaProximaCarga() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'VIAGEM_PROXIMA_CARGA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarTransportadora(registro, formulario) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/transportadora`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaTransportadora());
				if (!registro.id) {
					dispatch(initForm({
						...formulario,
						id_transportadora: resp.data.id
					}));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/transportadora`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaTransportadora());
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Produto
export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'VIAGEM_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function salvarProduto(registro, formulario) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/produto`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaProduto());
				if (!registro.id) {
					dispatch(initForm({
						...formulario,
						id_produto: resp.data.id
					}));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/produto`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaProduto());
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Unidade de Medida
export function getListaUnidadeMedida() {
    const request = axios.get(`${consts.API_URL}/unidadeMedida/listarSelect`);
    return {
        type: 'VIAGEM_UNIDADE_MEDIDA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarUnidadeMedida(registro, formulario) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/unidadeMedida`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaUnidadeMedida());
				if (!registro.id) {
					dispatch(initForm({
						...formulario,
						id_unidade_medida: resp.data.id
					}));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/unidadeMedida`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaUnidadeMedida());
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function getListaPosto() {
    const request = axios.get(`${consts.API_URL}/posto/listarSelect`);
    return {
        type: 'VIAGEM_POSTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaRota() {
    const request = axios.get(`${consts.API_URL}/rota/listarSelect?resumido=true`);
    return {
        type: 'VIAGEM_ROTA_SELECT_LISTADO',
        payload: request
    };
}

export function carregarRota(id) {

	return async (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/rota/listarSelect?id=${id}`).then(result => {

			const rota = result.data[0];
			const lista = getState().viagem.listaRota.map(item => {
				if (rota && item.id == rota.id) {	
					console.log(rota);
					return { ...rota };
				} else {
					return { ...item };
				}
			});

			dispatch({
				type: 'VIAGEM_ROTA_SELECT_LISTADO',
				payload: { data: lista }
			});
		}).catch(e => {
			console.log(e);
			
			setErroAPI(e);
		});
	}
}

export function gerarRotaPdf(registro) {

	return async (dispatch, getState) => {

		let cidadeOrigem = getState().viagem.listaCidade.filter(item => item.id == registro.id_cidade_carga)[0];
		let cidadeDestino = getState().viagem.listaCidade.filter(item => item.id == registro.id_cidade_descarga)[0];
		let motorista = getState().viagem.listaMotorista.filter(item => item.id == registro.id_motorista)[0];
		let veiculo = getState().viagem.listaVeiculo.filter(item => item.id == registro.id_veiculo)[0];
		let transportadora = getState().viagem.listaTransportadora.filter(item => item.id == registro.id_transportadora)[0];
		let rota = getState().viagem.listaRota.filter(item => item.id == registro.id_rota)[0];

		let postos = [];
		if (rota) {
			for (var i = 0; i < rota.rota.passos_localizacao.filter(local => local).length; i++) {
				let item = rota.rota.passos_localizacao.filter(local => local)[i];
			
				for (var j = 0; j < getState().viagem.listaPosto.length; j++) {
					let posto = getState().viagem.listaPosto[j];

					let postoLiberado = (registro.postos_liberados_novo || []).filter(postoLiberado => postoLiberado.id == posto.id)[0];

					if (postoLiberado) {
						if (!postos.filter(item => item.descricao == posto.descricao)[0]) {
							postos.push({
								...posto,
								preco_diesel: FormatUtils.formatarValorTela(posto.preco_diesel, 4),
								preco_arla: FormatUtils.formatarValorTela(posto.preco_arla, 4),
								quantidade_diesel: postoLiberado.quantidade_diesel,
								posicao: (registro.postos_liberados_novo || []).indexOf(postoLiberado)
							});
						}
					}
					
					// if (posto.latitude && posto.longitude) {
					// 	let distancia = await getDistance({ latitude: parseFloat(item.latitude), longitude: parseFloat(item.longitude) }, { latitude: parseFloat(posto.latitude), longitude: parseFloat(posto.longitude) })

					// 	if (distancia < 27000) {
					// 		if (!postos.filter(item => item.descricao == posto.descricao)[0]) {
					// 			postos.push({
					// 				...posto,
					// 				preco_diesel: FormatUtils.formatarValorTela(posto.preco_diesel, 4),
					// 				preco_arla: FormatUtils.formatarValorTela(posto.preco_arla, 4)
					// 			});
					// 		}
					// 	}
					// }
				}
			}
		}
		

		postos.sort((a, b) => a.posicao > b.posicao ? 1 : -1);

		axios.post(`${consts.API_URL}/rota/gerarPdf`, {
			id_viagem: registro.id,
			datahoraAgendamento: registro.datahora_agendamento,
			datahoraCarga: registro.datahora_carga,
			estadoOrigem: cidadeOrigem ? cidadeOrigem.uf : '',
			estadoDestino: cidadeDestino ? cidadeDestino.uf : '',
			cidadeOrigem: cidadeOrigem ? cidadeOrigem.valor : '',
			cidadeDestino: cidadeDestino ? cidadeDestino.valor : '',
			motorista: motorista ? motorista.valor : '',
			veiculo: veiculo ? veiculo.valor : '',
			transportadora: transportadora ? transportadora.valor : '',
			postos: postos,
			rotaLink: rota ? `origin=${rota.rota.origin.latitude},${rota.rota.origin.longitude}&destination=${rota.rota.destino.latitude},${rota.rota.destino.longitude}${rota.rota.waypoints.length > 0 ? `&waypoints=${rota.rota.waypoints.map(waypoint=> `${waypoint.latitude},${waypoint.longitude}`).join('|')}` : ''}` : '',
			overview_polyline: rota && rota.rota.overview_polyline ? rota.rota.overview_polyline : null,
			novo: true,
			postos_liberados_novo: registro.postos_liberados_novo || []
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});
	}

}
