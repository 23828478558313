import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './despesaProdutoForm';


import { setModoTela, initForm, salvar, excluir, getLista, getListaUsuario } from './despesaProdutoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class DespesaProduto extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaUsuario();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_cadastro != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Aprovadores</Th>
								<Th alignCenter>Autorização</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{
										(item.aprovadores || []).map(item => 
											this.props.listaUsuario.filter(usuario => usuario.id == item).map(usuario => usuario.valor).join()
										).join(', ')}										
									</Td>
									<Td alignCenter>
									<button type='button'
										title='Necessita aprovação'
										class={`btn btn-${item.aprovacao ? 'success' : 'default'} btn-sm`}
										onClick={() => this.props.salvar({ ...item, aprovacao: !item.aprovacao })}><i class={`far fa${item.aprovacao ? '-check' : ''}-square`}></i>
									</button>
									</Td>
									<Td alignRight minWidth={100}>

									<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_cadastro != 2}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_cadastro != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.despesaProduto,
	modoTela: state.despesaProduto.modoTela,
	lista: state.despesaProduto.lista,
	permissoes: state.usuario.permissoes,
	listaUsuario: state.despesaProduto.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({setModoTela, initForm, salvar, excluir, getLista, getListaUsuario}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DespesaProduto);
