const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaMotorista: [],
	listaVeiculo: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'OCORRENCIA_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'OCORRENCIA_LISTADO':
			return { ...state, lista: action.payload.data };


		case 'OCORRENCIA_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'OCORRENCIA_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

		case 'OCORRENCIA_VEICULO_SELECT_LISTADO':
			return { ...state, listaVeiculo: action.payload.data };

		default:
			return state;
	}
}
