import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './despesaViagemActions';

class DespesaViagemForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorProduto = document.createElement('input');
		fileSelectorProduto.setAttribute('type', 'file');
		fileSelectorProduto.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('png')) {
					
					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_produto: reader.result
						});

					  };
				}				

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorProduto = fileSelectorProduto;

		const fileSelectorNF = document.createElement('input');
		fileSelectorNF.setAttribute('type', 'file');
		fileSelectorNF.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('png')) {
					
					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_nota_fiscal: reader.result
						});

					  };
				}				

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorNF = fileSelectorNF;

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='datahora_despesa'
								label='Data Hora' placeholder='Informe a data '
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='6 6 6 6'
								readOnly={readOnly}
							/>

							<Field
								name='id_motorista'
								component={Select}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 12 12 12'
								readOnly={this.props.sessao && this.props.sessao.id_motorista ? 'readOnly' : readOnly}
								options={this.props.listaMotorista} />

							<Field
								name='fornecedor'
								component={LabelAndInput}
								label='Fornecedor' placeholder='Informe o fornecedor'
								cols='4 4 4 4'
								readOnly={readOnly} />

							<Field
								name='telefone'
								label='Telefone Contato' placeholder='Informe o telefone'
								component={LabelAndInputMask}
								mask='(99) 9 9999-9999'
								cols='6 6 6 6'
								readOnly={readOnly}
							/>

							<Field
								name='id_cidade'
								component={Select}
								label='Cidade' placeholder='Informe a cidade'
								cols='4 4 4 4'
								readOnly={readOnly}
								options={this.props.listaCidade} />

							<Field
								name='id_usuario_autorizacao'
								component={Select}
								label='Autorizado por' placeholder='Informe o usuário'
								cols='4 4 4 4'
								readOnly={readOnly}
								options={this.props.listaUsuario} />

							<Field
								name='id_despesa_produto'
					 			component={Select}
								label='Produto'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaProduto} />

							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor' placeholder='Informe o valor'
								cols='4 4 4 4'
								readOnly={readOnly}
								casas={2} />

							<Field
								name='odometro'
								component={LabelAndInputNumber}
								label='Odômetro' placeholder='Informe o odômetro'
								cols='4 4 4 4'
								readOnly={readOnly}
								casas={0} />

							<Field
								name='nota_fiscal'
								component={LabelAndInputNumber}
								label='Nota Fiscal' placeholder='Informe a nota fiscal'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='pagamento_motorista'
								component={Select}
								label='Pagamento' placeholder='Informe o tipo de pagamento'
								cols='6 6 6 6'
								readOnly={readOnly}
								options={this.props.listaPagamento} />
								
							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>

						<Row>
							<Grid cols='6 6 6 6'>
								<label>Foto do Produto</label>
							</Grid>

							<Grid cols='6 6 6 6'>
								<label>Foto da Nota Fiscal</label>
							</Grid>
						</Row>
						<Row>
							<Grid cols='6 6 6 6'>
								{this.props.formularioValues.imagem_produto ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={this.props.formularioValues.imagem_produto} style={{ height: 400 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '100%' }}
												onClick={() => {
													const linkSource = this.props.formularioValues.imagem_produto;
													const downloadLink = document.createElement("a");
													const fileName = `produto.jpg`;
													downloadLink.href = linkSource;
													downloadLink.download = fileName;
													downloadLink.click();
												}}>
												<i class='fas fa-download'></i>
											</button>
										</div>
									</div>
								) : (
									<Grid cols='6 6 3 2'>
										<Button
											text='Carregar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => {
												this.fileSelectorProduto.click()
											}} />
									</Grid>
								)}
							</Grid>

							<Grid cols='6 6 6 6'>
								{this.props.formularioValues.imagem_nota_fiscal ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={this.props.formularioValues.imagem_nota_fiscal} style={{ height: 400 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '100%' }}
												onClick={() => {
													const linkSource = this.props.formularioValues.imagem_nota_fiscal;
													const downloadLink = document.createElement("a");
													const fileName = `nf.jpg`;
													downloadLink.href = linkSource;
													downloadLink.download = fileName;
													downloadLink.click();
												}}>
												<i class='fas fa-download'></i>
											</button>
										</div>
									</div>
								) : (
									<Grid cols='6 6 3 2'>
										<Button
											text='Carregar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => {
												this.fileSelectorNF.click()
											}} />
									</Grid>
								)}
							</Grid>
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

DespesaViagemForm = reduxForm({ form: 'despesaViagemForm', destroyOnUnmount: false })(DespesaViagemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('despesaViagemForm')(state),
	registro: state.despesaViagem.registro,
	listaCidade: state.despesaViagem.listaCidade,
	listaTipoDespesa: state.despesaViagem.listaTipoDespesa,
	listaPagamento: state.despesaViagem.listaPagamento,
	listaProduto: state.despesaViagem.listaProduto,
	listaMotorista: state.despesaViagem.listaMotorista,
	listaUsuario: state.despesaViagem.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DespesaViagemForm);
