import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pontoForm';
import Map from '../common/map/mapVisualizacao';

import { setModoTela, initForm, salvar, excluir, getListaCidade, getLista, getListaTipo } from './pontoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class Ponto extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		modo: 'lista'
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaCidade();
		this.props.getListaTipo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome} ${item.nome_tipo} ${item.telefone} ${item.nome_cidade}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_cadastro != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						placeholder='Pesquisar'
						type='text'
						value={this.state.pesquisar}
						onChange={e => {
							this.setState({ ...this.state, pesquisar: e.target.value });
						}} />

					<div class="btn-group">
						<button 
							type='button'
							class={`btn btn-${this.state.modo == 'lista' ? 'info' : 'default'}`} style={{ width: 150 }}
							onClick={() => this.setState({ ...this.state, modo: 'lista' })}>
							Lista
						</button>
						<button
							type='button'
							class={`btn btn-${this.state.modo == 'mapa' ? 'info' : 'default'}`}
							style={{ width: 150 }}
							onClick={() => {
								this.props.getLista();
								this.setState({ ...this.state, modo: 'mapa' });
							}}>
							Mapa
						</button>
					</div>
					
					{this.state.modo == 'lista' ? (
						<Table responsive>
							<THead>
								<Tr>
									<Th>Tipo</Th>
									<Th>Nome</Th>
									<Th>Telefone</Th>
									<Th>Cidade</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{lista.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_tipo}</Td>
										<Td>{item.nome}</Td>
										<Td>{FormatUtils.formatarTelefone(item.telefone)}</Td>
										<Td>{item.nome_cidade}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={this.props.permissoes.acesso_cadastro != 2}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item
													});
													this.props.initForm({
														...item
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={this.props.permissoes.acesso_cadastro != 2}
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														senha: null
													});
													this.props.initForm({
														...item,
														senha: null
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					) : (
						<Map 
							pontos={lista.map(item => ({
								id: `ponto-${item.id}`,
								nome: `${item.nome}\n`
									+ `Telefone: ${FormatUtils.formatarTelefone(item.telefone)}\n`
									+ `Cidade: ${item.nome_cidade || ''}\n\n`
									+ `Localização: ${item.latitude || ''}, ${item.longitude || ''}`,
								latitude: item.latitude,
								longitude: item.longitude,
								icone: item.icone_tipo,
								nomeEvento: 'Editar',
								evento: () => {
									this.props.setModoTela('cadastro', {
										...item
									});
									this.props.initForm({
										...item
									});
								}
							}))}
							rota={this.state.rota}
							rotaCarregada={this.state.rotaCarregada}
							setRota={rota => {
								this.setState({
									...this.state,
									rotaCarregada: rota.rotaCarregada,
									rota: {
										pontos: rota ? rota.pontos : [],
										itens: rota ? rota.itens : [],
										overview_polyline: rota.overview_polyline || null
									},
									pontos: rota ? rota.pontos : []
								});
							}} />
					)}
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.ponto,
	modoTela: state.ponto.modoTela,
	lista: state.ponto.lista,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({setModoTela, initForm, salvar, excluir, getListaCidade, getLista, getListaTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Ponto);
