import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './valeForm';
import Select from '../common/form/select';
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, getListaMotorista, salvarConferido } from './valeActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class Vale extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaMotorista();
    }

    render() {
        return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_despesa != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ 
										datahora_vale: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), pessoal: true,
										id_motorista: this.props.sessao && this.props.sessao.id_motorista ? this.props.sessao.id_motorista : null 
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Select
						label='Conferido'
						cols='12 6 4 3'
						options={[{ id: '1', valor: 'Sim' }, { id: '2', valor: 'Aguardando' }, { id: '3', valor: 'Ambos' }]}
						value={this.state.conferido == null ? 3 : (this.state.conferido ? 1 : 2)}
						onChange={item => {
							this.setState({ ...this.state, conferido: item == 3 ? null : item == 1 })
						}} />

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						
							<Tr>
								<Th alignCenter>Data Inclusão</Th>
								<Th alignCenter>Data Documento</Th>
								<Th >Motorista</Th>
								<Th alignCenter>Tipo</Th>
								<Th >Valor</Th>
								<Th >Forma Pagamento</Th>
								<Th >Observação</Th>
								<Th>Conferido</Th>
								<Th></Th>
							</Tr>
						
						<TBody>
							{this.props.lista.filter(item => 
								this.props.sessao && (!this.props.sessao.id_motorista || this.props.sessao.id_motorista == item.id_motorista)
								).filter(item => this.state.conferido == null ? true : (this.state.conferido ? item.conferido : !item.conferido))
								.filter(item => {
									if (this.state.pesquisar
										&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)} ${item.nome_motorista} 
											${item.pessoal ? 'PESSOAL' : 'CAMINHÃO'} ${item.nome_forma} ${item.observacao}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
										return false;
									}
									return true;
								}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)}</Td>
									<Td>{item.nome_motorista}</Td>
									<Td alignCenter>{item.pessoal ? 'PESSOAL' : 'CAMINHÃO'}</Td>
									<Td>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									<Td>{item.nome_forma}</Td>
									<Td>{item.observacao}</Td>
									<Td>
										{(this.props.sessao && !this.props.sessao.id_motorista) &&
										<select
											className='form-control'
											readOnly={item.conferido ? 'readOnly' : null}
											value={item.conferido ? 1 : 2}
											onChange={data => {
												this.props.salvarConferido({
													...item,
													datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													conferido: data.target.value == 1
												});
											}}>
											<option value='1'>Sim</option>
											<option value='2'>Aguardando</option>
										</select>}
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_vale: DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_vale: DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_vale: DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_vale: DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.vale.modoTela,
	aguardando: state.vale.aguardando,
	lista: state.vale.lista,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({setModoTela, initForm, salvar, excluir, getLista, getListaMotorista, salvarConferido }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Vale);
