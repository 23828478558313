import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './checklistPerguntasForm';

import { setModoTela, initForm, salvar, salvarOrdenacao, excluir, getLista, getListaChecklist, getListaSetor } from './checklistPerguntasActions';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

class ChecklistPerguntas extends Component {

	state = {
		linhaSelecionada: null,
		listaOrdenar: null
	}

    componentWillMount() {
		this.props.getLista(this.props.registro.id);
		this.props.getListaChecklist();
		this.props.getListaSetor();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		if (this.state.listaOrdenar) {
			return (
				<ContentCard>
					<ContentCardHeader>
					<label> Perguntas </label>
						<Row>
							<Grid cols='6 6 4 3'>
								<Button
									text='Salvar Ordenação'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvarOrdenacao(this.state.listaOrdenar);
										this.setState({ ...this.state, listaOrdenar: null });
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>

						<Table responsive>
							<THead>
								<Tr>
									<Th alignLeft minWidth={50}></Th>
									<Th>Descrição</Th>
									<Th alignCenter>Escolha</Th>
									<Th alignCenter>Texto</Th>
									<Th alignCenter>Foto</Th>
									<Th alignCenter>Gera Ocorrência</Th>
									<Th alignCenter>Setor</Th>
								</Tr>
							</THead>
							<TBody>
								{this.state.listaOrdenar.map((item, i) => (
									<Tr key={item.id}>
										<Td alignLeft minWidth={50}>
											<ButtonTable
												type={'success'}
												icon={'fas fa-chevron-up'}
												visible={true}
												disabled={i == 0}
												event={() => {
													this.setState({
														...this.state,
														listaOrdenar: this.state.listaOrdenar.map((pergunta, j) => {
															if (j == i-1) {
																return this.state.listaOrdenar[j+1];
															} else if (j == i) {
																return this.state.listaOrdenar[j-1];
															} else {
																return pergunta;
															}
														})
													});
												}} />

											<ButtonTable
												type={'success'}
												icon={'fas fa-chevron-down'}
												visible={true}
												disabled={i == this.state.listaOrdenar.length -1}
												event={() => {

													this.setState({
														...this.state,
														listaOrdenar: this.state.listaOrdenar.map((pergunta, j) => {
															if (j == i) {
																return this.state.listaOrdenar[j+1];
															} else if (j == i+1) {
																return this.state.listaOrdenar[j-1];
															} else {
																return pergunta;
															}
														})
													});
												}} />
										</Td>
										<Td>{item.descricao}</Td>
										<Td alignCenter>{item.escolha ? 'Sim' : 'Não'}</Td>
										<Td alignCenter>{item.texto ? 'Sim' : 'Não'}</Td>
										<Td alignCenter>{item.foto ? 'Sim' : 'Não'}</Td>
										<Td alignCenter>{item.gera_ocorrencia ? 'Sim' : 'Não'}</Td>
										<Td alignCenters>{item.nome_setor}</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		} else {
			return (
				<ContentCard>
					<ContentCardHeader>
					<label> Perguntas </label>
						<Row>
							<Grid cols='6 6 4 2'>
								<Button
									text='Adicionar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastro', { id_checklist: this.props.registro.id });
										this.props.initForm({ id_checklist: this.props.registro.id });
									}} />
							</Grid>
							<Grid cols='6 6 4 2'>
								<Button
									text='Ordenar'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.setState({ ...this.state, listaOrdenar: this.props.lista });
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>

						<Table responsive>
							<THead>
								<Tr>
									<Th>Descrição</Th>
									<Th alignCenter>Escolha</Th>
									<Th alignCenter>Texto</Th>
									<Th alignCenter>Foto</Th>
									<Th alignCenter>Gera Ocorrência</Th>
									<Th alignCenter>Setor</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{(this.props.lista).map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.descricao}</Td>
										<Td alignCenter>{item.escolha ? 'Sim' : 'Não'}</Td>
										<Td alignCenter>{item.texto ? 'Sim' : 'Não'}</Td>
										<Td alignCenter>{item.foto ? 'Sim' : 'Não'}</Td>
										<Td alignCenter>{item.gera_ocorrencia ? 'Sim' : 'Não'}</Td>
										<Td alignCenters>{item.nome_setor}</Td>
	                                    <Td></Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item,
														senha: null
													});
													this.props.initForm({
														...item,
														senha: null
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														senha: null
													});
													this.props.initForm({
														...item,
														senha: null
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		}
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.checklistPerguntas,
	modoTela: state.checklistPerguntas.modoTela,
	lista: state.checklistPerguntas.lista,
	registro: state.checklist.registro

});
const mapDispatchToProps = dispatch => bindActionCreators({setModoTela, initForm, salvar, salvarOrdenacao, excluir, getLista, getListaChecklist, getListaSetor }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistPerguntas);
