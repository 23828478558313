const INITIAL_STATE = { 
	registro: {}, 
	modoTela: 'lista', 
	lista: [], 
	listaMotorista: [], 
	permissoes: { naoCarregado: true, acesso_usuario: 0, acesso_cadastro: 0, acesso_logistica: 0, acesso_despesa: 0 },
	listaNotificacaoPerfil: [
		{ id: 1, valor: 'Negociação' },
		{ id: 2, valor: 'Abastecida' },
		{ id: 3, valor: 'Frete' },
		{ id: 4, valor: 'Placa' },
		{ id: 5, valor: 'Despesa' },
		{ id: 6, valor: 'Vale' },
		{ id: 7, valor: 'Devolução' }
	],
	notificacao_perfil: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'USUARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'USUARIO_PERMISSAO_CARREGADO':
            return {
				...state,
				permissoes: action.payload.data,
				notificacao_perfil: (action.payload.data || {}).notificacao_perfil || []
			};

        case 'USUARIO_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };

		case 'USUARIO_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };
		
        default:
            return state;
    }
}
