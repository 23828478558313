const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaCidade: [],
	listaTipo: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'PONTO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'PONTO_LISTADO':
			return { ...state, lista: action.payload.data };

		case 'PONTO_CIDADE_LISTADO':
			return { ...state, listaCidade: action.payload.data };

		case 'PONTO_TIPO_LISTADO':
			return { ...state, listaTipo: action.payload.data };

		case 'PONTO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		default:
			return state;
	}
}
