import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'POSTO_NEGOCIACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initForm(registro = {}) {
    return (dispatch, getState) => {
		let valorAnterior = registro.preco_anterior || 0;
		if (!registro.id) {
			let anterior = getState().postoNegociacao.lista.filter(item => item.id_posto == registro.id_posto && item.id_produto == registro.id_produto)[0];
			if (anterior) {
				valorAnterior = FormatUtils.formatarValorTela(anterior.preco_atual || 0, 2);
			}
		}
        dispatch(initialize('postoNegociacaoForm', {
			...registro,
			preco_anterior: valorAnterior
		}));
    }
}

export function setAguardando(aguardando) {
    return {
        type: 'POSTO_NEGOCIACAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(id_posto) {
    const request = axios.get(`${consts.API_URL}/postoNegociacao?id_posto=${id_posto}`);
    return {
        type: 'POSTO_NEGOCIACAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {

    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/postoNegociacao`, {
				...registro,
				data_negociacao: DateFormat.formatarDataTelaParaSql(registro.data_negociacao),
				preco_atual: FormatUtils.formatarValorBanco(registro.preco_atual, 2),
				preco_anterior: FormatUtils.formatarValorBanco(registro.preco_anterior, 2)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_posto));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/postoNegociacao`, {
				...registro,
				data_negociacao: DateFormat.formatarDataTelaParaSql(registro.data_negociacao),
				preco_atual: FormatUtils.formatarValorBanco(registro.preco_atual, 2),
				preco_anterior: FormatUtils.formatarValorBanco(registro.preco_anterior, 2)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_posto));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/postoNegociacao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_posto));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
