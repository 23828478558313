const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaProduto: [{
		id: 1,
		valor: 'Diesel'
	}, {
		id: 2,
		valor: 'Arla'
	}]
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'POSTO_NEGOCIACAO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'POSTO_NEGOCIACAO_LISTADO':
			return { ...state, lista: action.payload.data };

		case 'POSTO_NEGOCIACAO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		default:
			return state;
	}
}
