import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './abastecidaActions';

class AbastecidaForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorOdometro = document.createElement('input');
		fileSelectorOdometro.setAttribute('type', 'file');
		fileSelectorOdometro.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('png')) {
					
					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_odometro: reader.result
						});

					  };
				}				

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorOdometro = fileSelectorOdometro;

		const fileSelectorNF = document.createElement('input');
		fileSelectorNF.setAttribute('type', 'file');
		fileSelectorNF.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('png')) {
					
					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_nota_fiscal: reader.result
						});

					  };
				}				

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorNF = fileSelectorNF;

	}

	render() {

		let readOnly = this.props.excluir || this.props.formularioValues.conferido ? 'readOnly' : '';



		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='datahora_abastecida'
								label='Data Hora' placeholder='Informe a data '
								component={LabelAndInputMask}
								mask='99/99/9999 99:99'
								cols='6 6 6 6'
								readOnly={readOnly}
							/>

							<Field
								name='id_motorista'
								component={Select}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 12 12 12'
								readOnly={this.props.sessao && this.props.sessao.id_motorista ? 'readOnly' : readOnly}
								options={this.props.listaMotorista} />

							<Field
								name='id_veiculo'
								component={Select}
								label='Veículo' placeholder='Informe o veículo'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaVeiculo.filter(item => !item.carreta)} />

							<Select
								name='id_fornecedor'
								label='Posto' placeholder='Informe o posto'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaPosto}
								value={this.props.formularioValues.id_fornecedor}
								onChange={item => {
									// if (this.props.formularioValues.id) {
									// 	this.props.initForm({
									// 		...this.props.formularioValues,
									// 		id_fornecedor: item
									// 	});
									// } else {
										let produto = this.props.listaProduto.filter(prod => prod.id == this.props.formularioValues.id_produto)[0];
										let posto = this.props.listaPosto.filter(pst => pst.id == item)[0];
										if (produto && posto) {
											//1: Diesel, 2: Arla
											this.props.initForm({
												...this.props.formularioValues,
												id_fornecedor: item,
												preco_negociado: produto.id == 1 ? posto.preco_diesel : posto.preco_arla,
												dentro_prazo: produto.id == 1 ? posto.dentro_prazo_diesel : posto.dentro_prazo_arla,
												id_negociacao: produto.id == 1 ? posto.id_negociacao_diesel : posto.id_negociacao_arla
											});
										} else {
											this.props.initForm({
												...this.props.formularioValues,
												id_fornecedor: item,
												preco_negociado: 0,
												dentro_prazo: 0,
												id_negociacao: 0
											});
										}
									// }
								}} />

							<Select
								name='id_produto'
								label='Produto'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaProduto}
								value={this.props.formularioValues.id_produto}
								onChange={item => {

									// if (this.props.formularioValues.id) {
									// 	this.props.initForm({
									// 		...this.props.formularioValues,
									// 		id_produto: item
									// 	});
									// } else {
										let produto = this.props.listaProduto.filter(prod => prod.id == item)[0];
										let posto = this.props.listaPosto.filter(pst => pst.id == this.props.formularioValues.id_fornecedor)[0];

										if (produto && posto) {
											//1: Diesel, 2: Arla
											this.props.initForm({
												...this.props.formularioValues,
												id_produto: item,
												preco_negociado: produto.id == 1 ? posto.preco_diesel : posto.preco_arla,
												dentro_prazo: produto.id == 1 ? posto.dentro_prazo_diesel : posto.dentro_prazo_arla,
												id_negociacao: produto.id == 1 ? posto.id_negociacao_diesel : posto.id_negociacao_arla
											});
										} else {
											this.props.initForm({
												...this.props.formularioValues,
												id_produto: item,
												preco_negociado: 0,
												dentro_prazo: false,
												id_negociacao: null
											});
										}
									// }
								}} />

							<LabelAndInput
								name='preco_negociado'
								label='Preço Negociado' placeholder='Informe o preço'
								cols='12 12 12 12'
								backgroundColor={this.props.formularioValues.id_produto && this.props.formularioValues.id_fornecedor && !this.props.formularioValues.id_negociacao ? '#ff0' : null}
								readOnly={'readOnly'}
								value={this.props.formularioValues.id_negociacao ? `${FormatUtils.formatarValorTela(this.props.formularioValues.preco_negociado, 2)} - ${this.props.formularioValues.dentro_prazo ? 'Dentro do Prazo' : 'Fora do Prazo'}` : (this.props.formularioValues.id_produto && this.props.formularioValues.id_fornecedor ? 'Preço ainda não negociado' : '')}
								onChange={() => {}} />

							<LabelAndInput
								name='preco'
								label='Preço Pago' placeholder='Informe o preço'
								cols='12 12 12 12'
								readOnly={'readOnly'}
								value={this.props.formularioValues.quantidade && this.props.formularioValues.valor ? FormatUtils.formatarValorTela(parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.valor, 2)) / parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.quantidade, 2)), 3) : '0,000'}
								onChange={() => {}} />

							<Field
								name='quantidade'
								component={LabelAndInputNumber}
								label='Quantidade' placeholder='Informe a quantidade'
								cols='4 4 4 4'
								readOnly={readOnly}
								casas={2} />

							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor' placeholder='Informe o valor'
								cols='4 4 4 4'
								readOnly={readOnly}
								casas={2} />

							<Field
								name='odometro'
								component={LabelAndInputNumber}
								label='Odômetro' placeholder='Informe o odômetro'
								cols='4 4 4 4'
								readOnly={readOnly}
								casas={0} />

							<Field
								name='nota_fiscal'
								component={LabelAndInputNumber}
								label='Nota Fiscal' placeholder='Informe a nota fiscal'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='id_forma_pagamento'
								component={Select}
								label='Forma de Pagamento' placeholder='Informe a forma de pagamento'
								cols='6 6 6 6'
								readOnly={readOnly}
								options={this.props.listaFormaPagamento} />

							<Select
								name='completou'
								label='Completou o Tanque' placeholder='Informe se completou o tanque'
								cols='6 6 6 6'
								readOnly={readOnly}
								options={this.props.listaCompletou}
								value={this.props.formularioValues.completou ? 1 : 2}
								onChange={item => {
									this.props.initForm({
										...this.props.formularioValues,
										completou: item == 1
									})
								}} />
						</Row>

						<Row>
							<Grid cols='6 6 6 6'>
								<label>Foto do Odômetro</label>
							</Grid>

							<Grid cols='6 6 6 6'>
								<label>Foto da Nota Fiscal</label>
							</Grid>
						</Row>
						<Row>
							<Grid cols='6 6 6 6'>
								{this.props.formularioValues.imagem_odometro ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={this.props.formularioValues.imagem_odometro} style={{ height: 400 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '100%' }}
												onClick={() => {
													const linkSource = this.props.formularioValues.imagem_odometro;
													const downloadLink = document.createElement("a");
													const fileName = `nf.jpg`;
													downloadLink.href = linkSource;
													downloadLink.download = fileName;
													downloadLink.click();
												}}>
												<i class='fas fa-download'></i>
											</button>
										</div>
									</div>
								) : (
									<Grid cols='6 6 3 2'>
										<Button
											text='Carregar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => {
												this.fileSelectorOdometro.click()
											}} />
									</Grid>
								)}
							</Grid>

							<Grid cols='6 6 6 6'>
								{this.props.formularioValues.imagem_nota_fiscal ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={this.props.formularioValues.imagem_nota_fiscal} style={{ height: 400 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '100%' }}
												onClick={() => {
													const linkSource = this.props.formularioValues.imagem_nota_fiscal;
													const downloadLink = document.createElement("a");
													const fileName = `nf.jpg`;
													downloadLink.href = linkSource;
													downloadLink.download = fileName;
													downloadLink.click();
												}}>
												<i class='fas fa-download'></i>
											</button>
										</div>
									</div>
								) : (
									<Grid cols='6 6 3 2'>
										<Button
											text='Carregar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											event={() => {
												this.fileSelectorNF.click()
											}} />
									</Grid>
								)}
							</Grid>

							<br />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação do Gerente' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										disabled={this.props.formularioValues.conferido}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										disabled={this.props.formularioValues.conferido}
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

AbastecidaForm = reduxForm({ form: 'abastecidaForm', destroyOnUnmount: false })(AbastecidaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('abastecidaForm')(state),
	registro: state.abastecida.registro,
	listaPosto: state.abastecida.listaPosto,
	listaTipoDespesa: state.abastecida.listaTipoDespesa,
	listaFormaPagamento: state.abastecida.listaFormaPagamento,
	listaProduto: state.abastecida.listaProduto,
	listaMotorista: state.abastecida.listaMotorista,
	listaVeiculo: state.abastecida.listaVeiculo,
	listaCompletou: state.abastecida.listaCompletou
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AbastecidaForm);
