import React from 'react';
import Menu from './menu';
import imagemLogo from '../../assets/images/sologo.png';

export default props => (
	<aside className="main-sidebar elevation-4 sidebar-light-indigo">
		<a href="../../index3.html" className="brand-link navbar-light">
			<img src={imagemLogo} alt="Engel Logo" className="brand-image"/>
			<span className="brand-text font-weight-light">Engel Transportes</span>
		</a>
		<div className="sidebar">
			<Menu />
		</div>
	</aside>
)
