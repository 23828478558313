import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaCidade: [],
	listaCompletou:[{
		id: 'true',
		valor: 'Sim'
	}, {
		id: 'false',
		valor: 'Não'
	}],
	listaPagamento: [{
		id: 'true',
		valor: 'Motorista'
	}, {
		id: 'false',
		valor: 'Empresa'
	}],
	listaProduto:[],
	listaMotorista: [],
	listaUsuario: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'DESPESA_VIAGEM_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'DESPESA_VIAGEM_FILTRO':
			return { ...state, filtro: action.payload };

		case 'DESPESA_VIAGEM_LISTADO':
			return { ...state, lista: action.payload.data };


		case 'DESPESA_VIAGEM_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'DESPESA_VIAGEM_CIDADE_SELECT_LISTADO':
			return { ...state, listaCidade: action.payload.data };

		case 'DESPESA_VIAGEM_COMPLETOU_SELECT_LISTADO':
			return { ...state, listaCompletou: action.payload.data };

		case 'DESPESA_VIAGEM_PAGAMENTO_SELECT_LISTADO':
			return { ...state, listaPagamento: action.payload.data };

		case 'DESPESA_VIAGEM_PRODUTO_SELECT_LISTADO':
			return { ...state, listaProduto: action.payload.data };

		case 'DESPESA_VIAGEM_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

		case 'DESPESA_VIAGEM_USUARIO_SELECT_LISTADO':
			return { ...state, listaUsuario: action.payload.data };

		default:
			return state;
	}
}
