import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'MULTA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('multaForm', {
			...registro
		})
    ];
}

export function setFiltro(filtro) {
	return {
		type: 'MULTA_FILTRO',
		payload: filtro,
	};
}

export function setAguardando(aguardando) {
    return {
        type: 'MULTA_SET_AGUARDANDO',
        payload: aguardando
    };
}


export function getLista() {

	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/multa?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().multa.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().multa.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().multa.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().multa.filtro.data_final)}
			${getState().auth.usuario && getState().auth.usuario.id_motorista ?
				`&id_motorista=${getState().auth.usuario.id_motorista}`
			: ''}
		`).then(resp => {
			dispatch({
				type: 'MULTA_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};
}

export function salvar(registro) {

    return (dispatch, getState) => {
		if (!registro.id) {

			let dataVencimento = new Date(DateFormat.formatarDataTelaParaSql(registro.data_vencimento));
			let dataParcelas = [];
			for (let i = 0; i < (registro.parcelas || 1); i++) {
				dataParcelas.push(`${dataVencimento.getFullYear()}/${String(dataVencimento.getMonth() + 1).padStart(2, '0')}/${String(dataVencimento.getDate()).padStart(2, '0')}`);
				dataVencimento.setMonth(dataVencimento.getMonth() + 1);
			}

	        axios.post(`${consts.API_URL}/multa`, {
				...registro,
				datahora_infracao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_infracao),
				data_vencimento: DateFormat.formatarDataTelaParaSql(registro.data_vencimento),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				data_parcelas: dataParcelas,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/multa`, {
				...registro,
				datahora_infracao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_infracao),
				data_vencimento: DateFormat.formatarDataTelaParaSql(registro.data_vencimento),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				id_usuario_alteracao: getState().auth.usuario.id,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/multa?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'MULTA_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'MULTA_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'MULTA_VEICULO_SELECT_LISTADO',
        payload: request
    };
}
