import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
	return {
		type: 'VIAGEM_LANCAMENTO_MODO_TELA',
		payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
	};
}
export function initForm(registro = {}) {
	return [
		initialize('viagemLancamentoForm', {
			...registro,
			datahora_inicio: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_inicio),
			datahora_fim: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_fim),


		})
	];
}

export function setFiltro(filtro) {
	return {
		type: 'VIAGEM_LANCAMENTO_FILTRO',
		payload: filtro,
	};
}

export function setAguardando(aguardando) {
	return {
		type: 'VIAGEM_LANCAMENTO_SET_AGUARDANDO',
		payload: aguardando
	};
}

export function setChecklist(registro) {
	return {
		type: 'VIAGEM_LANCAMENTO_SET_CHECKLIST',
		payload: registro
	};
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/viagemLancamento?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().viagemLancamento.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().viagemLancamento.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().viagemLancamento.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().viagemLancamento.filtro.data_final)}
		`).then(resp => {
			dispatch({
				type: 'VIAGEM_LANCAMENTO_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};
}

export function getListaMotorista() {
	const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
	return {
		type: 'VIAGEM_LANCAMENTO_MOTORISTA_LISTADO',
		payload: request
	};
}

export function getListaVeiculoTipo() {
	const request = axios.get(`${consts.API_URL}/veiculoTipo/listarSelect`);
	return {
		type: 'VIAGEM_LANCAMENTO_VEICULO_TIPO_LISTADO',
		payload: request
	};
}

export function getListaVeiculo() {
	const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
	return {
		type: 'VIAGEM_LANCAMENTO_VEICULO_LISTADO',
		payload: request
	};
}

export function getListaChecklistResposta(id_viagem_lancamento) {
	const request = axios.get(`${consts.API_URL}/checklistResposta?id_viagem_lancamento=${id_viagem_lancamento}`);
	return {
		type: 'VIAGEM_LANCAMENTO_CHECKLIST_RESPOSTA_LISTADO',
		payload: request
	};
}

export function getListaDespesaViagem(id_motorista, data_inicial, data_final) {
	return (dispatch, getState) => {

		dispatch({
			type: 'VIAGEM_LANCAMENTO_DESPESA_VIAGEM_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/despesaViagem?
			id_motorista=${id_motorista}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(data_final)}
		`).then(resp => {
			dispatch({
				type: 'VIAGEM_LANCAMENTO_DESPESA_VIAGEM_LISTADO',
				payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
	};
}

export function getListaAbastecida(id_motorista, data_inicial, data_final) {

	return (dispatch, getState) => {

		dispatch({
			type: 'VIAGEM_LANCAMENTO_ABASTECIDA_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/abastecida?
			id_motorista=${id_motorista}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(data_final)}
		`).then(resp => {
			dispatch({
				type: 'VIAGEM_LANCAMENTO_ABASTECIDA_LISTADO',
				payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
	};
}

export function salvar(registro) {

	return (dispatch, getState) => {
		let tipoVeiculo = getState().viagemLancamento.listaVeiculoTipo.filter(item => item.id == registro.id_veiculo_tipo)[0];
		let possuiCarreta1 = tipoVeiculo ? tipoVeiculo.carreta1 : false;
		let possuiCarreta2 = tipoVeiculo ? tipoVeiculo.carreta2 : false;
		
		if (!registro.id) {
			axios.post(`${consts.API_URL}/viagemLancamento`, {
				...registro,
				datahora_inicio: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio),
				datahora_fim: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim),
				possuiCarreta1: possuiCarreta1,
				possuiCarreta2: possuiCarreta2,
				id_carreta1: possuiCarreta1 ? registro.id_carreta1 : null,
				id_carreta2: possuiCarreta2 ? registro.id_carreta2 : null
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/viagemLancamento`, {
				...registro,
				datahora_inicio: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio),
				datahora_fim: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim),
				possuiCarreta1: possuiCarreta1,
				possuiCarreta2: possuiCarreta2,
				id_carreta1: possuiCarreta1 ? registro.id_carreta1 : null,
				id_carreta2: possuiCarreta2 ? registro.id_carreta2 : null
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
	};
}

export function excluir(registro) {
	return dispatch => {
		axios.delete(`${consts.API_URL}/viagemLancamento?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
	};
}

export function baixarImagem(imagem, nome) {
	return dispatch => {
		if (imagem) {
			const linkSource = imagem;
			const downloadLink = document.createElement("a");
			const fileName = `${nome}.jpg`;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		}
	};
}

export function getListaMulta(registro) {
	return dispatch => {
		if (registro) {
			axios.get(`${consts.API_URL}/multaPagamento?id_viagem_lancamento=${registro.id_viagem_lancamento}&id_motorista=${registro.id_motorista}`).then(resp => {
				dispatch({
					type: 'VIAGEM_LANCAMENTO_MULTA_LISTADO',
					payload: resp
				});
			}).catch(ex => {
				console.log(ex);
			});
		} else {
			dispatch({
				type: 'VIAGEM_LANCAMENTO_MULTA_LISTADO',
				payload: { data: [] }
			});
		}
	};
}

export function salvarMulta(registro) {

    return (dispatch, getState) => {
		
		axios.put(`${consts.API_URL}/multaPagamento`, {
			...registro,
			datahora_pagamento: registro.id_viagem_lancamento ? DateFormat.getDataHoraAtual() : null,
			id_usuario_alteracao: getState().auth.usuario.id,
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaMulta({ id_viagem_lancamento: registro.id_viagem_lancamento_atualizar, id_motorista: registro.id_motorista }));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function gerarPdf(registro) {

	return async (dispatch, getState) => {

		registro = {
			...registro,
			listaChecklistResposta: [],//getState().viagemLancamento.listaChecklistResposta,
			listaAbastecida: getState().viagemLancamento.listaAbastecida.filter(item =>
				item.id_motorista == registro.id_motorista
				&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)) >= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio)
				&& (!registro.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)) <= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim))
			).map(item => ({
				...item,
				datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
				quantidade: FormatUtils.formatarValorTelaDecimal(item.quantidade, 2),
				valor: FormatUtils.formatarValorTelaDecimal(item.valor, 2),
				imagem_odometro: null,
				imagem_nota_fiscal: null
			})),
			listaDespesaViagem: getState().viagemLancamento.listaDespesaViagem.filter(item =>
				item.id_motorista == registro.id_motorista
				&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)) >= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio)
				&& (!registro.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)) <= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim))
			).map(item => ({
				...item,
				datahora_despesa: DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa),
				valor: FormatUtils.formatarValorTelaDecimal(item.valor, 2),
				imagem_nota_fiscal: null,
				imagem_produto: null
			})),
			listaVale: getState().vale.lista.filter(item =>
				item.id_motorista == registro.id_motorista
				&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)) >= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio)
				&& (!registro.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)) <= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim))
			).map(item => ({
				...item,
				datahora_vale: DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale),
				valor: FormatUtils.formatarValorTelaDecimal(item.valor, 2)
			})),
			listaDevolucao: getState().devolucao.lista.filter(item =>
				item.id_motorista == registro.id_motorista
				&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao)) >= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio)
				&& (!registro.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao)) <= DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim))
			).map(item => ({
				...item,
				datahora_devolucao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao),
				valor: FormatUtils.formatarValorTelaDecimal(item.valor, 2),
				imagem: null,
                imagem_valor: null
			}))
		}

		let totalAbastecidaEmpresa = 0;
		let totalAbastecidaMotorista = 0;
		for (var i = 0; i < registro.listaAbastecida.length; i++) {
			let item = registro.listaAbastecida[i];
			totalAbastecidaEmpresa += !(item.nome_forma_pagamento || '').includes('MOTORISTA') ? parseFloat(FormatUtils.formatarValorBanco(item.valor, 2)) : 0;
			totalAbastecidaMotorista += (item.nome_forma_pagamento || '').includes('MOTORISTA') ? parseFloat(FormatUtils.formatarValorBanco(item.valor, 2)) : 0;
		}

		let totalDespesaEmpresa = 0;
		let totalDespesaMotorista = 0;
		for (var i = 0; i < registro.listaDespesaViagem.length; i++) {
			let item = registro.listaDespesaViagem[i];
			totalDespesaEmpresa += !item.pagamento_motorista ? parseFloat(FormatUtils.formatarValorBanco(item.valor, 2)) : 0;
			totalDespesaMotorista += item.pagamento_motorista ? parseFloat(FormatUtils.formatarValorBanco(item.valor, 2)) : 0;
		}

		let totalVale = 0;
		for (var i = 0; i < registro.listaVale.length; i++) {
			let item = registro.listaVale[i];
			totalVale += parseFloat(FormatUtils.formatarValorBanco(item.valor, 2));
		}

		let totalDevolucao = 0;
		for (var i = 0; i < registro.listaDevolucao.length; i++) {
			let item = registro.listaDevolucao[i];
			totalDevolucao += parseFloat(FormatUtils.formatarValorBanco(item.valor, 2));
		}

		await axios.post(`${consts.API_URL}/viagemLancamento/gerarPdfDespesa`, {
			...registro,
			totalAbastecidaEmpresa: FormatUtils.formatarValorTelaDecimal(totalAbastecidaEmpresa, 2),
			totalAbastecidaMotorista: FormatUtils.formatarValorTelaDecimal(totalAbastecidaMotorista, 2),
			totalAbastecidaGeral: FormatUtils.formatarValorTelaDecimal(totalAbastecidaEmpresa + totalAbastecidaMotorista, 2),
			totalDespesaEmpresa: FormatUtils.formatarValorTelaDecimal(totalDespesaEmpresa, 2),
			totalDespesaMotorista: FormatUtils.formatarValorTelaDecimal(totalDespesaMotorista, 2),
			totalDespesaGeral: FormatUtils.formatarValorTelaDecimal(totalDespesaEmpresa + totalDespesaMotorista, 2),
			totalVale: FormatUtils.formatarValorTelaDecimal(totalVale, 2),
			totalDevolucao: FormatUtils.formatarValorTelaDecimal(totalDevolucao, 2)
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

		let kmTotal = 0;
		let comissaoTotal = 0;
		let valorFreteTotal = 0;
		let valorAdiantamento = 0;
		let valorSaldo = 0;
		let valorRecebidoMotorista = 0;
		let valorRecebidoEmpresa = 0;

		registro = {
			...registro,
			fretes: (registro.fretes || []).filter(item => item).map(item => {

				let quantidade = parseFloat(String(item.quantidade || 0));
				let preco_unitario = parseFloat(String(item.preco_unitario || 0));
				let valor_tarifa = parseFloat(String(item.valor_tarifa || 0));
				let tomador_frete = parseFloat(String(item.tomador_frete || 0));
				let percentual_adiantamento = parseFloat(String(item.percentual_adiantamento || 0));
				let percentual_saldo = parseFloat(String(item.percentual_saldo || 0));
				let valor_pedagio = parseFloat(String(item.valor_pedagio || 0));
				let valor_descarga = parseFloat(String(item.valor_descarga || 0));
				let porcentagem_comissao_moto = parseFloat(String(item.porcentagem_comissao_moto || 0));
				let qtde_km = parseFloat(String(item.qtde_km || 0));
				let media = parseFloat(String(item.media || 0));
				let preco_combustivel = parseFloat(String(item.preco_combustivel || 0));
				let seguro_carga = parseFloat(String(item.seguro_carga || 0));
				let outros_custos = parseFloat(String(item.outros_custos || 0));
				let deslocamento_origem_destino = parseFloat(String(item.deslocamento_origem_destino || 0));
				let deslocamento_qtde_km = parseFloat(String(item.deslocamento_qtde_km || 0));
				let deslocamento_media = parseFloat(String(item.deslocamento_media || 0));
				let deslocamento_preco_combustivel = parseFloat(String(item.deslocamento_preco_combustivel || 0));
				let deslocamento_pedagio = parseFloat(String(item.deslocamento_pedagio || 0));
				let carregado_pedagio = parseFloat(String(item.carregado_pedagio || 0));
				let valor_tarifa_motorista = parseFloat(String(item.valor_tarifa_motorista || 0));

				let valorTotalFrete = 0;
				let valorTotalFreteMotorista = 0;
				if (item.id_tipo_tarifa == 1) {
					//apenas o valor total
					valorTotalFrete = valor_tarifa_motorista;
					valorTotalFreteMotorista = valor_tarifa_motorista;
				} else if (item.id_tipo_tarifa == 2) {
					//apenas o valor total
					valorTotalFrete = valor_tarifa_motorista * quantidade;
					valorTotalFreteMotorista = valor_tarifa_motorista * quantidade;
				} else if (item.id_tipo_tarifa == 3) {
					//apenas o valor total
					valorTotalFrete = valor_tarifa_motorista * quantidade;
					valorTotalFreteMotorista = valor_tarifa_motorista * quantidade;
				} else if (item.id_tipo_tarifa == 4) {
					//apenas o valor total
					valorTotalFrete = valor_tarifa_motorista * (qtde_km + deslocamento_qtde_km);
					valorTotalFreteMotorista = valor_tarifa_motorista * (qtde_km + deslocamento_qtde_km);
				} else if (item.id_tipo_tarifa == 5) {
					//apenas o valor total
					valorTotalFrete = valor_tarifa_motorista * quantidade;
					valorTotalFreteMotorista = valor_tarifa_motorista * quantidade;
				}
				//Inclui o pedágio apenas se for no cartão

				if (item.id_tipo_pedagio == 2 || item.id_tipo_pedagio == 3 || item.id_tipo_pedagio == 4) {
					valorTotalFrete = valorTotalFrete + valor_pedagio;
				}
				// valorTotalFrete = valorTotalFrete - carregado_pedagio - seguro_carga - outros_custos;
				valorTotalFrete = valorTotalFrete - seguro_carga - outros_custos;

				let comissaoMotorista = (valorTotalFrete * porcentagem_comissao_moto) / 100;
		
				if ((parseInt(item.km_final) - parseInt(item.km_inicial)) > 0) {
					kmTotal += parseInt(item.km_final) - parseInt(item.km_inicial);
				}
				comissaoTotal += comissaoMotorista;
				valorFreteTotal += valorTotalFrete;

				valorAdiantamento = parseFloat(item.valor_adiantamento || 0);
				valorSaldo += parseFloat(item.valor_saldo || 0);

				let tipoAdiantamento = '';
				if (item.id_tipo_adiantamento == 1) {
					tipoAdiantamento = 'Valor recebido pelo Motorista';
					valorRecebidoMotorista += parseFloat(item.valor_adiantamento || 0);
				} else if (item.id_tipo_adiantamento == 2) {
					tipoAdiantamento = 'Valor recebido pela Empresa';
					valorRecebidoEmpresa += parseFloat(item.valor_adiantamento || 0);
				}

				let tipoSaldo = '';
				if (item.id_tipo_saldo == 1) {
					tipoSaldo = 'Valor recebido pelo Motorista';
					valorRecebidoMotorista += parseFloat(item.valor_saldo || 0);
				} else if (item.id_tipo_saldo == 2) {
					tipoSaldo = 'Valor recebido pela Empresa';
					valorRecebidoEmpresa += parseFloat(item.valor_saldo || 0);
				}

				if (item.id_tipo_pedagio == 5) { //Incluso
					valorRecebidoEmpresa += parseFloat(item.valor_pedagio || 0);
				}

				return {
					...item,
					datahora_carga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga),
					datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
					valor_frete: FormatUtils.formatarValorTelaDecimal(valorTotalFrete, 2),
					valor_comissao_motorista: FormatUtils.formatarValorTelaDecimal(comissaoMotorista, 2),
					valor_adiantamento: FormatUtils.formatarValorTelaDecimal(item.valor_adiantamento, 2),
					valor_saldo: FormatUtils.formatarValorTelaDecimal(item.valor_saldo, 2),
					nome_tipo_adiantamento: tipoAdiantamento,
					nome_tipo_saldo: tipoSaldo
				}
			})
		}

		await axios.post(`${consts.API_URL}/viagemLancamento/gerarPdfFrete`, {
			...registro,
			km_total: parseInt(kmTotal),
			comissao_total: FormatUtils.formatarValorTelaDecimal(comissaoTotal, 2),
			valor_frete_total: FormatUtils.formatarValorTelaDecimal(valorFreteTotal, 2),
			valor_adiantamento: FormatUtils.formatarValorTelaDecimal(valorAdiantamento, 2),
			valor_saldo: FormatUtils.formatarValorTelaDecimal(valorSaldo, 2),
			valor_recebido_motorista: FormatUtils.formatarValorTelaDecimal(valorRecebidoMotorista, 2),
			valor_recebido_empresa: FormatUtils.formatarValorTelaDecimal(valorRecebidoEmpresa, 2)
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});
	}
}

export function gerarPdfChecklist(registro, frete, checklists) {

	return async (dispatch, getState) => {

		registro = {
			...registro,
			frete: frete,
			checklists: checklists
		}

		await axios.post(`${consts.API_URL}/viagemLancamento/gerarPdfChecklist`, {
			...registro,
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}

