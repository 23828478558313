import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaPosto: [],
	listaCompletou:[{
		id: '1',
		valor: 'Sim'
	}, {
		id: '2',
		valor: 'Não'
	}],
	listaFormaPagamento: [],
	listaProduto:[],
	listaMotorista: [],
	listaVeiculo: [],
	listaProduto: [{
		id: 1,
		valor: 'Diesel'
	}, {
		id: 2,
		valor: 'Arla'
	}],
	listaStatus: [{ id: 0, valor: 'Não Negociado' }, { id: 1, valor: 'Abaixo do Negociado' }, { id: 2, valor: 'Preço OK' }, { id: 3, valor: 'Acima do Negociado' }],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'ABASTECIDA_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'ABASTECIDA_FILTRO':
			return { ...state, filtro: action.payload };

		case 'ABASTECIDA_LISTADO':
			return { ...state, lista: action.payload.data };


		case 'ABASTECIDA_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'ABASTECIDA_POSTO_SELECT_LISTADO':
			return { ...state, listaPosto: action.payload.data };

		case 'ABASTECIDA_COMPLETOU_SELECT_LISTADO':
			return { ...state, listaCompletou: action.payload.data };

		case 'ABASTECIDA_FORMA_PAGAMENTO_SELECT_LISTADO':
			return { ...state, listaFormaPagamento: action.payload.data };

		case 'ABASTECIDA_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

		case 'ABASTECIDA_VEICULO_SELECT_LISTADO':
			return { ...state, listaVeiculo: action.payload.data };

		default:
			return state;
	}
}
