const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaCidade: [],
	listaRede: [],
	listaFormaPagamento: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'POSTO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'POSTO_LISTADO':
			return { ...state, lista: action.payload.data };

		case 'POSTO_CIDADE_LISTADO':
			return { ...state, listaCidade: action.payload.data };

		case 'POSTO_REDE_LISTADO':
			return { ...state, listaRede: action.payload.data };

		case 'POSTO_FORMA_PAGAMENTO_SELECT_LISTADO':
			return { ...state, listaFormaPagamento: action.payload.data };

		case 'POSTO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		default:
			return state;
	}
}
