import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'DESPESA_VIAGEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('despesaViagemForm', {
			...registro
		})
    ];
}

export function setFiltro(filtro) {
	return {
		type: 'DESPESA_VIAGEM_FILTRO',
		payload: filtro,
	};
}

export function setAguardando(aguardando) {
    return {
        type: 'DESPESA_VIAGEM_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/despesaViagem?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().despesaViagem.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().despesaViagem.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().despesaViagem.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().despesaViagem.filtro.data_final)}
			${getState().auth.usuario && getState().auth.usuario.id_motorista ?
				`&id_motorista=${getState().auth.usuario.id_motorista}`
			: ''}
		`).then(resp => {
			dispatch({
				type: 'DESPESA_VIAGEM_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(ex => {
			console.log(ex);
			dispatch(setAguardando(false));
		});
	};
}

export function buscarImagens(id) {
	return (dispatch, getState) => {
	    axios.get(`${consts.API_URL}/despesaViagem/imagens?id=${id}`).then(retorno => {
			dispatch(initForm({
				...getState().despesaViagem.registro,
				imagem_produto: retorno.data ? retorno.data.imagem_produto : null,
				imagem_nota_fiscal: retorno.data ? retorno.data.imagem_nota_fiscal : null
			}));
		}).catch(ex => {
			console.log(ex);
		});
	};
}

export function salvar(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/despesaViagem`, {
				...registro,
                datahora_despesa: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_despesa),
				quantidade: FormatUtils.formatarValorBanco(registro.quantidade, 2),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/despesaViagem`, {
				...registro,
				datahora_despesa: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_despesa),
				quantidade: FormatUtils.formatarValorBanco(registro.quantidade, 2),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				modoMotorista: getState().auth.usuario.id_motorista || false
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
		dispatch(setAguardando(true));
        axios.delete(`${consts.API_URL}/despesaViagem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function salvarAprovado(registro) {

    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/despesaViagem/aprovado`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'DESPESA_VIAGEM_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/despesaProduto/listarSelect`);
    return {
        type: 'DESPESA_VIAGEM_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'DESPESA_VIAGEM_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'DESPESA_VIAGEM_USUARIO_SELECT_LISTADO',
        payload: request
    };
}
