import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initForm } from './usuarioActions';

class UsuarioForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Select
							name='id_motorista'
							label='Motorista' placeholder='Informe o motorista'
							cols='12 12 12 12'
							readOnly={readOnly}
							options={this.props.listaMotorista}
							value={this.props.formularioValues.id_motorista}
							onChange={data => {
								this.props.initForm({
									...this.props.formularioValues,
									id_motorista: data,
									nome: this.props.listaMotorista.filter(item => item.id == data)[0] ? this.props.listaMotorista.filter(item => item.id == data)[0].valor : ''
								})
							}} />

						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *' placeholder='Informe o Nome'
							cols='12 12 12 12'
							readOnly={readOnly} />

						<Field
							name='login'
							component={LabelAndInput}
							label='Login *'
							placeholder='Informe o Usuário'
							cols='12 12 12 12'
							readOnly={readOnly} />

						<Field
							name='senha'
							component={LabelAndInput}
							type="password"
							label='Senha *'
							placeholder='Informe a Senha'
							cols='12 12 12 12'
							readOnly={readOnly} />

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('usuarioForm')(state),
	registro: state.usuario.registro,
	listaMotorista: state.usuario.listaMotorista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
