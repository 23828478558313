import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Grid from '../layout/grid'

class LabelInputSearchLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address).then(async results => {
		if (results[0]) {
			let latLong = await getLatLng(results[0]);

			let retorno = {
				endereco: results[0].address_components.filter(item => item.types.includes('route'))[0] ? results[0].address_components.filter(item => item.types.includes('route'))[0].long_name || '' : '',
				numero: results[0].address_components.filter(item => item.types.includes('street_number'))[0] ? results[0].address_components.filter(item => item.types.includes('street_number'))[0].long_name || '' : '',
				bairro: results[0].address_components.filter(item => item.types.includes('sublocality'))[0] ? results[0].address_components.filter(item => item.types.includes('sublocality'))[0].long_name || '' : '',
				cep: results[0].address_components.filter(item => item.types.includes('postal_code'))[0] ? results[0].address_components.filter(item => item.types.includes('postal_code'))[0].long_name || '' : '',
				estado: results[0].address_components.filter(item => item.types.includes('administrative_area_level_1'))[0] ? results[0].address_components.filter(item => item.types.includes('administrative_area_level_1'))[0].short_name || '' : '',
				cidade: results[0].address_components.filter(item => item.types.includes('administrative_area_level_2'))[0] ? results[0].address_components.filter(item => item.types.includes('administrative_area_level_2'))[0].long_name || '' : '',
				latitude: latLong ? latLong.lat : null,
				longitude: latLong ? latLong.lng : null
			};

			this.props.onChange(retorno);
		} else {
			this.props.onChange(null);
		}
	})
}



  render() {
    return (
		<Grid cols={this.props.cols}>
			<div className='form-group'>
				<label htmlFor={this.props.name}>{this.props.label}</label>
				<PlacesAutocomplete
					value={this.state.address}
					onChange={this.handleChange}
					onSelect={this.handleSelect} >
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<div>
							<input {...getInputProps({
								placeholder: 'Pesquisar',
								className: 'form-control'
							})} />
							<div className='autocomplete-dropdown-container'>
								{loading && <div>Loading...</div>}
								{suggestions.map(suggestion => {
									const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
									// inline style for demonstration purpose
									const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
									return (
										<div{...getSuggestionItemProps(suggestion, { className, style })}>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
			</div>
		</Grid>
    );
  }
}

export default LabelInputSearchLocation;
