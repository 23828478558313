import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Form from '../common/form/form';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, imprimir } from './planejamentoViagemActions';
import labelAndInputMask from '../common/form/labelAndInputMask';

class PlanejamentoViagem extends Component {

    state = {
        linhaSelecionada: null
    }

    componentDidMount() {
        this.props.initForm({});

    }

    render() {
        if (!this.props.formularioValues) {

            return null;
        }

        let tonelada = parseFloat(String(this.props.formularioValues.tonelada || 0).split(".").join("").replace(",", "."));
        let valor_tonelada = parseFloat(String(this.props.formularioValues.valor_tonelada || 0).split(".").join("").replace(",", "."));
        let percentual_comissao = parseFloat(String(this.props.formularioValues.percentual_comissao || 0).split(".").join("").replace(",", "."));
        let qtde_km = parseFloat(String(this.props.formularioValues.qtde_km || 0).split(".").join("").replace(",", "."));
        let media = parseFloat(String(this.props.formularioValues.media || 0).split(".").join("").replace(",", "."));
        let preco_combustivel = parseFloat(String(this.props.formularioValues.preco_combustivel || 0).split(".").join("").replace(",", "."));
        let seguro_carga = parseFloat(String(this.props.formularioValues.seguro_carga || 0).split(".").join("").replace(",", "."));
        let outros_custos = parseFloat(String(this.props.formularioValues.outros_custos || 0).split(".").join("").replace(",", "."));
        let pedagio = parseFloat(String(this.props.formularioValues.pedagio || 0).split(".").join("").replace(",", "."));
        let qtde_km_deslocamento = parseFloat(String(this.props.formularioValues.qtde_km_deslocamento || 0).split(".").join("").replace(",", "."));
        let media_deslocamento = parseFloat(String(this.props.formularioValues.media_deslocamento || 0).split(".").join("").replace(",", "."));
        let preco_combustivel_deslocamento = parseFloat(String(this.props.formularioValues.preco_combustivel_deslocamento || 0).split(".").join("").replace(",", "."));
        let pedagio_deslocamento = parseFloat(String(this.props.formularioValues.pedagio_deslocamento || 0).split(".").join("").replace(",", "."));
        let mais_pedagio = parseFloat(String(this.props.formularioValues.mais_pedagio || 0).split(".").join("").replace(",", "."));

		let valorTotalFrete = (tonelada > 1 ? tonelada * valor_tonelada : valor_tonelada) + mais_pedagio;
		let valorBaseCalculoFreteMotorista = valorTotalFrete - seguro_carga - outros_custos;
        let comissaoMotorista = valorBaseCalculoFreteMotorista * (percentual_comissao / 100);
        let diasDeViagem = (qtde_km + qtde_km_deslocamento) / 800;
        let custoCombustivelDesloca = qtde_km_deslocamento == 0 ? 0 : (qtde_km_deslocamento / media_deslocamento) * preco_combustivel_deslocamento;
        let custoTotalDeslocamento = custoCombustivelDesloca + pedagio_deslocamento;
        let custoCombustivelCarregado = qtde_km && media && preco_combustivel ? (qtde_km / media) * preco_combustivel : 0;
        let custoTotalFrete = comissaoMotorista + custoCombustivelCarregado + seguro_carga + outros_custos + pedagio;
		let lucroLiquido = valorTotalFrete - custoTotalFrete - custoTotalDeslocamento;
        let lucroKmTotal = lucroLiquido && qtde_km ? lucroLiquido / qtde_km : 0;
        let lucroKmFrete = valorTotalFrete && qtde_km ? valorTotalFrete / qtde_km : 0;

        return (
            <ContentCard style={{ margin: 10 }}>
                <ContentCardHeader>
                </ContentCardHeader>
                <ContentCardBody >




                    <Form event={this.props.handleSubmit}>
                        <Row>

                            <Grid cols='4 4 4 4'>
                            <label> Carregamento </label>
                                <br></br>
                            <br></br>


                                <Row>
                                    <Field
                                        name='origem_destino'
                                        component={LabelAndInput}
                                        label='Origem/Destino' placeholder='Informe a origem/destino'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>

                                <Row>

                                    <Field
                                        name='motorista'
                                        component={LabelAndInput}
                                        label='Motorista' placeholder='Informe o motorista'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='placa'
                                        component={LabelAndInput}
                                        label='Placa' placeholder='Informe a placa'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>

                                    <Field
                                        name='transportadora'
                                        component={LabelAndInput}
                                        label='Transportadora' placeholder='Informe a transportadora'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>

                                    <Field
                                        name='responsavel'
                                        component={LabelAndInput}
                                        label='Responsavel' placeholder='Informe o responsavel'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='telefone'
                                        component={labelAndInputMask}
                                        mask='(99) 99999-9999'
                                        label='Telefone' placeholder='Informe o telefone'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>

                                    <Field
                                        name='pagamento'
                                        component={LabelAndInput}
                                        label='Pagamento' placeholder='Informe o pagamento'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='data_carregamento'
                                        component={labelAndInputMask}
                                        label='Data Carregamento' placeholder='Informe a data '
                                        mask='99/99/9999'
                                        cols='12 12 12 12'


                                    />
                                </Row>

                                <Row>
                                    <Field
                                        name='produto'
                                        component={LabelAndInput}
                                        label='Produto' placeholder='Informe o Produto '
                                        cols='12 12 12 12'
                                    />
                                </Row>
                            </Grid>
                                <Grid cols='4 4 4 4'>
                                <label> Carregamento </label>
                                <br></br>
                            <br></br>

                                <Row>

                                    <Field

                                        name='tonelada'
                                        component={LabelAndInputNumber}
                                        label='Tonelada' placeholder='Informe  a tonelada'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='valor_tonelada'
                                        component={LabelAndInputNumber}
                                        label='Valor p/ Tonelada' placeholder='Informe  o valor por tonelada'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='mais_pedagio'
                                        component={LabelAndInputNumber}
                                        label='Mais Pedágio' placeholder='Informe mais pedágio'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>

                                    <Field
                                        name='percentual_comissao'
                                        component={LabelAndInputNumber}
                                        label='Comissão Motorista' placeholder='Informe a comissão'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='qtde_km'
                                        component={LabelAndInputNumber}
                                        label='Quantidade Km' placeholder='Informe a quantidade Km'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='media'
                                        component={LabelAndInputNumber}
                                        label='Media ' placeholder='Informe a media'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='preco_combustivel'
                                        component={LabelAndInputNumber}
                                        label='Preço Combustivel ' placeholder='Informe o preço do combustivel'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>

                                    <Field
                                        name='seguro_carga'
                                        component={LabelAndInputNumber}
                                        label='Seguro da Carga' placeholder='Informe o seguro carga '
                                        cols='12 12 12 12'
                                    />
                                </Row>
                                <Row>

                                    <Field
                                        name='outros_custos'
                                        component={LabelAndInputNumber}
                                        label='Outros Custos' placeholder='Informe  outros custos'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                                <Row>
                                    <Field
                                        name='pedagio'
                                        component={LabelAndInputNumber}
                                        label='Pedágio' placeholder='Informe  o pedágio'
                                        cols='12 12 12 12'
                                        casas={2} />
                                </Row>
                            </Grid>

                    <Grid cols='4 4 4 4'>

                            <label>Deslocamento </label>
                            <br></br>
                            <br></br>


                            <Row>

                                <Field
                                    name='origem_destino_deslocamento'
                                    component={LabelAndInput}
                                    label='Origem/Destino' placeholder='Informe a origem/destino'
                                    cols='12 12 12 12'
                                    casas={2} />
                            </Row>
                            <Row>

                                <Field
                                    name='qtde_km_deslocamento'
                                    component={LabelAndInputNumber}
                                    label='Quantidade Km' placeholder='Informe a quantidade Km'
                                    cols='12 12 12 12'
                                    casas={2} />
                            </Row>
                            <Row>
                                <Field
                                    name='media_deslocamento'
                                    component={LabelAndInputNumber}
                                    label='Media ' placeholder='Informe a media'
                                    cols='12 12 12 12'
                                    casas={2} />
                            </Row>
                            <Row>
                                <Field
                                    name='preco_combustivel_deslocamento'
                                    component={LabelAndInputNumber}
                                    label='Preço Combustivel ' placeholder='Informe o preço do combustivel'
                                    cols='12 12 12 12'
                                    casas={2} />
                            </Row>
                            <Row>

                                <Field
                                    name='pedagio_deslocamento'
                                    component={LabelAndInputNumber}
                                    label='Pedágio' placeholder='Informe  o pedágio'
                                    cols='12 12 12 12'
                                    casas={2} />
                            </Row>

                         <Table responsive >
                        <THead>
                            <p style={{ fontSize: 18, color: '#1C8403' }}> Extrato do Frete </p>
                        </THead>
                        <TBody>
                            <Tr>
                                <Td style={{ fontSize: 16 }}> Valor Total do Frete</Td>
                                <Td alignRight style={{ fontSize: 16 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(valorTotalFrete, 2)} </Td>
                            </Tr>
							<Tr>
								<Td style={{ fontSize: 16 }}> Base Cálculo Frete Motorista</Td>
                                <Td alignRight style={{ fontSize: 16 }}>R$</Td>
								<Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(valorBaseCalculoFreteMotorista, 2)} </Td>
							</Tr>
                            <Tr>
                                <Td style={{ fontSize: 16 }}> Comissao p/ Motorista</Td>
                                <Td alignRight style={{ fontSize: 16 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(comissaoMotorista, 2)} </Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontSize: 16 }}> Dias de Viagem</Td>
                                <Td alignRight style={{ fontSize: 16 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(diasDeViagem, 2)} </Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontSize: 16 }}> Custo Combutível Deslocamento</Td>
                                <Td alignRight style={{ fontSize: 16 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(custoCombustivelDesloca, 2)} </Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontSize: 16 }}> Custo Total Deslocamento</Td>
                                <Td alignRight style={{ fontSize: 16 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(custoTotalDeslocamento, 2)} </Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontSize: 16 }}> Custo Combustível Carregado</Td>
                                <Td alignRight style={{ fontSize: 16 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(custoCombustivelCarregado, 2)} </Td>
                            </Tr>
							<Tr>
								<Td style={{ fontSize: 16 }}> Custo Total Frete</Td>
								<Td alignRight style={{ fontSize: 16 }}>R$</Td>
								<Td alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTelaDecimal(custoTotalFrete, 2)}</Td>
							</Tr>
                            <Tr backgroundColor='#1C8403' color='#fff'>
                                <Td style={{ fontSize: 18 }}> Lucro Líquido</Td>
                                <Td alignRight style={{ fontSize: 18 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 18 }}>{FormatUtils.formatarValorTelaDecimal(lucroLiquido, 2)} </Td>
                            </Tr>
                            <Tr backgroundColor='#1C8403' color='#fff'>
                                <Td style={{ fontSize: 18 }}> Lucro KM Total</Td>
                                <Td alignRight style={{ fontSize: 18 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 18 }}>{FormatUtils.formatarValorTelaDecimal(lucroKmTotal, 2)} </Td>
                            </Tr>
                            <Tr backgroundColor='#1C8403' color='#fff'>
                                <Td style={{ fontSize: 18 }}> Valor Km Frete </Td>
                                <Td alignRight style={{ fontSize: 18 }}>R$</Td>
                                <Td alignRight style={{ fontSize: 18 }}>{FormatUtils.formatarValorTelaDecimal(lucroKmFrete, 2)} </Td>
                            </Tr>
                        </TBody>
                    </Table>


                    </Grid>


                                    </Row>
                        </Form>

            </ContentCardBody>

			<Button
				text='Imprimir'
				type={'success'}
				icon={'fa fa-check'}
				event={() => {
					this.props.imprimir({
						...this.props.formularioValues,
						tonelada: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.tonelada || 0).split(".").join("").replace(",", ".")), 2),
				        valor_tonelada: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.valor_tonelada || 0).split(".").join("").replace(",", ".")), 2),
				        percentual_comissao: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.percentual_comissao || 0).split(".").join("").replace(",", ".")), 2),
				        qtde_km: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.qtde_km || 0).split(".").join("").replace(",", ".")), 2),
				        media: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.media || 0).split(".").join("").replace(",", ".")), 2),
				        preco_combustivel: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.preco_combustivel || 0).split(".").join("").replace(",", ".")), 2),
				        seguro_carga: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.seguro_carga || 0).split(".").join("").replace(",", ".")), 2),
				        outros_custos: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.outros_custos || 0).split(".").join("").replace(",", ".")), 2),
				        pedagio: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.pedagio || 0).split(".").join("").replace(",", ".")), 2),
				        qtde_km_deslocamento: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.qtde_km_deslocamento || 0).split(".").join("").replace(",", ".")), 2),
				        media_deslocamento: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.media_deslocamento || 0).split(".").join("").replace(",", ".")), 2),
				        preco_combustivel_deslocamento: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.preco_combustivel_deslocamento || 0).split(".").join("").replace(",", ".")), 2),
				        pedagio_deslocamento: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.pedagio_deslocamento || 0).split(".").join("").replace(",", ".")), 2),
				        mais_pedagio: FormatUtils.formatarValorTelaDecimal(parseFloat(String(this.props.formularioValues.mais_pedagio || 0).split(".").join("").replace(",", ".")), 2),
						valorTotalFrete: FormatUtils.formatarValorTelaDecimal(valorTotalFrete, 2),
						comissaoMotorista: FormatUtils.formatarValorTelaDecimal(comissaoMotorista, 2),
						diasDeViagem: FormatUtils.formatarValorTelaDecimal(diasDeViagem, 2),
						custoCombustivelDesloca: FormatUtils.formatarValorTelaDecimal(custoCombustivelDesloca, 2),
						custoTotalDeslocamento: FormatUtils.formatarValorTelaDecimal(custoTotalDeslocamento, 2),
						custoCombustivelCarregado: FormatUtils.formatarValorTelaDecimal(custoCombustivelCarregado, 2),
						lucroLiquido: FormatUtils.formatarValorTelaDecimal(lucroLiquido, 2),
						lucroKmTotal: FormatUtils.formatarValorTelaDecimal(lucroKmTotal, 2),
						lucroKmFrete: FormatUtils.formatarValorTelaDecimal(lucroKmFrete, 2),
						valorBaseCalculoFreteMotorista: FormatUtils.formatarValorTelaDecimal(valorBaseCalculoFreteMotorista, 2),
						custoTotalFrete: FormatUtils.formatarValorTelaDecimal(custoTotalFrete, 2)
					});
				}} />
            </ContentCard >



        );
    }


}


PlanejamentoViagem = reduxForm({ form: 'planejamentoViagem', destroyOnUnmount: false })(PlanejamentoViagem);
const mapStateToProps = state => ({
    sessao: state.auth.usuario,
    formularioValues: getFormValues('planejamentoViagem')(state),
    registro: state.planejamentoViagem.registro,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, imprimir }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PlanejamentoViagem);
