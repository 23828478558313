import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela,
	getListaEstado,
	getListaCidade,
	getListaLocal, salvarLocal,
	getListaVeiculo, salvarVeiculo
} from '../viagem/viagemActions';

class ManutencaoForm extends Component {

	state = {
		modoCadastroLocal: false,
		local: null,
		modoCadastroVeiculo: false,
		veiculo: null
	}

    componentWillMount() {
		this.props.getListaEstado();
		this.props.getListaCidade();
		this.props.getListaLocal();
		this.props.getListaVeiculo();
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardHeader>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Field
								name='datahora_agendamento'
								component={LabelAndInputDateTime}
								label='Date e Hora Agendada' placeholder='Informe a data'
								cols='12 12 4 4'
								readOnly={readOnly} />

							<Field
								name='datahora_previsao_termino'
								component={LabelAndInputDateTime}
								label='Date e Hora Previsão Término' placeholder='Informe a data'
								cols='12 12 4 4'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='datahora_inicio'
								component={LabelAndInputDateTime}
								label='Date e Hora Início' placeholder='Informe a data'
								cols='12 12 4 4'
								readOnly={readOnly} />

							<Field
								name='datahora_fim'
								component={LabelAndInputDateTime}
								label='Date e Hora Fim' placeholder='Informe a data'
								cols='12 12 4 4'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='id_local'
								component={SelectAlteracao}
								options={this.props.listaLocal}
								label='Local da Manutenção'
								cols='12 12 12 12'
								placeholder='Selecione o local'
								modoCadastro={this.state.modoCadastroLocal}
								acaoBotaoLabel={this.props.formularioValues.id_local && this.props.formularioValues.id_local != 0 ? 'Alterar' : 'Novo'}
								acaoBotao={() => {
									let itemLista = this.props.listaLocal.filter(item => item.id == this.props.formularioValues.id_local)[0];

									this.setState({
										...this.state,
										modoCadastroLocal: true,
										local: {
											id: this.props.formularioValues.id_local && this.props.formularioValues.id_local != 0 ? this.props.formularioValues.id_local : null,
											nome: itemLista ? itemLista.valor : null
										}
									});
								}}
								componenteCadastro={
									<div class='input-group'>
										<input type='text' className='form-control'
											placeholder='Informe o nome'
											value={this.state.local ? this.state.local.nome : ''}
											onChange={data => {
												this.setState({
													...this.state,
													local: {
														id: this.state.local ? this.state.local.id : null,
														nome: data.target.value
													}
												})
											}} />
										<span class='input-group-btn'>
											<button type='button' class='btn btn-success btn-flat'
												onClick={() => {
													this.props.salvarLocal(this.state.local, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroLocal: false,
														local: null
													});
												}}>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='id_estado'
								component={Select}
								options={this.props.listaEstado}
								label='Estado'
								cols='12 12 6 6'
								placeholder='Selecione o estado'
								readOnly={readOnly} />

							<Field
								name='id_cidade'
								component={Select}
								options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado)}
								label='Cidade'
								cols='12 12 6 6'
								placeholder='Selecione a cidade'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='id_veiculo'
								component={SelectAlteracao}
								options={this.props.listaVeiculo.filter(item => !item.carreta)}
								label='Veículo'
								cols='12 12 12 12'
								placeholder='Selecione o veículo'
								modoCadastro={this.state.modoCadastroVeiculo}
								acaoBotaoLabel={this.props.formularioValues.id_veiculo && this.props.formularioValues.id_veiculo != 0 ? 'Alterar' : 'Novo'}
								acaoBotao={() => {
									let itemLista = this.props.listaVeiculo.filter(item => item.id == this.props.formularioValues.id_veiculo)[0];

									this.setState({
										...this.state,
										modoCadastroVeiculo: true,
										veiculo: {
											id: this.props.formularioValues.id_veiculo && this.props.formularioValues.id_veiculo != 0 ? this.props.formularioValues.id_veiculo : null,
											placa: itemLista ? itemLista.valor : null,
											cm: itemLista ? itemLista.cm : null
										}
									});
								}}
								componenteCadastro={
									<div class='input-group'>
										<input type='text' className='form-control'
											placeholder='Informe a placa'
											value={this.state.veiculo ? this.state.veiculo.placa : ''}
											onChange={data => {
												this.setState({
													...this.state,
													veiculo: {
														id: this.state.veiculo ? this.state.veiculo.id : null,
														cm: this.state.veiculo ? this.state.veiculo.cm : '',
														placa: data.target.value
													}
												})
											}} />
										<input type='text' className='form-control'
											placeholder='Informe o CM'
											value={this.state.veiculo ? this.state.veiculo.cm : ''}
											onChange={data => {
												this.setState({
													...this.state,
													veiculo: {
														id: this.state.veiculo ? this.state.veiculo.id : null,
														placa: this.state.veiculo ? this.state.veiculo.placa : '',
														cm: data.target.value
													}
												})
											}} />
										<span class='input-group-btn'>
											<button type='button' class='btn btn-success btn-flat'
												onClick={() => {
													this.props.salvarVeiculo(this.state.veiculo, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroVeiculo: false,
														veiculo: null
													});
												}}>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='responsavel_agendamento'
								component={LabelAndInput}
								label='Agendado por' placeholder='Informe o responsável'
								cols='12 12 6 4'
								readOnly={readOnly} />

							<Field
								name='responsavel_manutencao'
								component={LabelAndInput}
								label='Responsável pela manutenção' placeholder='Informe o responsável'
								cols='12 12 6 4'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='servico'
								component={LabelAndInput}
								label='Serviço' placeholder='Informe a descrição do serviço'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='confirmado'
								component={Select}
								options={[{ id: false, valor: 'Não' }, { id: true, valor: 'Sim' }]}
								label='Confirmado'
								cols='12 12 4 4'
								placeholder='Selecione se está confirmado'
								readOnly={readOnly} />

							<Field
								name='Vazio'
								component={Select}
								options={[{ id: false, valor: 'Não' }, { id: true, valor: 'Sim' }]}
								label='Informe se está vazio'
								cols='12 12 4 4'
								placeholder='Selecione se está vazio'
								readOnly={readOnly} />
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

ManutencaoForm = reduxForm({form: 'manutencaoForm', destroyOnUnmount: false})(ManutencaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('manutencaoForm')(state),
	registro: state.manutencao.registro,
	listaLocal: state.viagem.listaLocal,
	listaEstado: state.viagem.listaEstado,
	listaCidade: state.viagem.listaCidade,
	listaVeiculo: state.viagem.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela,
	getListaEstado,
	getListaCidade,
	getListaLocal, salvarLocal,
	getListaVeiculo, salvarVeiculo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManutencaoForm);
