const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaProduto: [],
	listaChecklistTipo: [{
		id: 1,
		valor: 'Carregamento'
	}, {
		id: 2,
		valor: 'Descarga'
	}, {
		id: 3,
		valor: 'Início de Viagem'
	}, {
		id: 4,
		valor: 'Fim de Viagem'
	}, {
		id: 5,
		valor: 'Periódico'
	}]
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'CHECKLIST_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CHECKLIST_LISTADO':
			return { ...state, lista: action.payload.data };


		case 'CHECKLIST_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'CHECKLIST_PRODUTO_SELECT_LISTADO':
			return { ...state, listaProduto: action.payload.data };

		default:
			return state;
	}
}
