const INITIAL_STATE = { lista: [] };

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        
		case 'NOTIFICACAO_LISTADO':
            return { 
                ...state, 
                lista: action.payload.data 
            };

        default:
            return state;
    }
}
